import {
  Stack,
  Typography,
  Box,
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableHead
} from '@mui/material';

// --------------------------------------------------------------------------------------------------------------
export default function ALTermsOfService() {
  return (
    <div>
      <Stack direction="column">
        <Typography variant="body1">
          <br />
          주식회사 여니(이하 "회사"라 함)는 개인정보보호법 등 관련 법령에 따라 "회원"의 개인정보를
          보호하고, 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이
          개인정보처리방침을 수립⦁공개 합니다.
          <br />
          <br />
          <strong>1. 개인정보의 수집</strong>
          <br />
          회원 가입 시 또는 서비스 이용 과정에서 웹사이트 또는 모바일 어플리케이션을 통해 서비스
          제공을 위해 최소한의 개인정보를 수집하고 있습니다.
          <br />
          <Box sx={{ width: 500, height: 100, p: 2, border: '1px dashed grey' }}>
            <strong>필수</strong>
            <br />
            <strong>- 이름, 생년월일, 닉네임, 연락처, 이메일, 개인 작업위치정보</strong>
            <br />
            <strong>- 사업자등록번호, 보유 장비, 보유 자격증</strong>
            <br />
          </Box>
          <br />
          필수정보란? : 해당 서비스의 본질적 기능을 수행하기 위한 정보
          <br />
          선택정보란? : 보다 특화된 서비스를 제공하기 위해 추가 수집하는 정보 (선택 정보를 입력하지
          않은 경우에도 서비스 이용 제한은 없습니다.)
          <br />
          <br />
          "회원"은 개인정보의 수집⦁이용 동의를 거부하는 경우 회원가입서비스는 이용하실 수 있으나,
          서비스의 이용/제공이 제한될 수 있습니다. 개인정보 수집⦁이용약관에 대한 변동사항은 공지 및
          별도 통지를 할 예정입니다.
          <br />
          <br />
          <strong>2. 개인정보의 이용</strong>
          <br />
          회원관리, 서비스 제공·개선, 신규 서비스 개발 등을 위해 이용합니다. 회원 가입 시 또는
          서비스 이용 과정에서 웹사이트 또는 모바일 어플리케이션을 통해 서비스 제공을 위해 최소한의
          개인정보를 이용 할 수 있습니다.
        </Typography>
        <TableContainer>
          <Table sx={{ width: 1000, height: 400 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>구분</strong>
                </TableCell>
                <TableCell>
                  <strong>목적</strong>
                </TableCell>
                <TableCell>
                  <strong>항목</strong>
                </TableCell>
                <TableCell>
                  <strong>보유기간</strong>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>회원가입</TableCell>
                <TableCell>
                  본인여부 확인, 각종 맞춤형 서비스 제공, 서비스 개선 및 신규 서비스 개발을 위한
                  통계 활용, 계약이행 및 약관변경 등의 고지를 위한 연락, 본인의사확인 및 민원 등의
                  고객불만처리
                </TableCell>
                <TableCell>
                  [필수] 이름, 생년월일, 닉네임, 연락처, 이메일 [선택] 사업자등록번호, 보유장비,
                  보유자격증
                </TableCell>
                <TableCell>회원 탈퇴시 즉시파기</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>게시물(채용정보) 등록</TableCell>
                <TableCell>채용정보 등록자 확인, 등록 결과 안내</TableCell>
                <TableCell>[필수] 이름, 닉네임, 전화번호, 이메일</TableCell>
                <TableCell>지원취소 또는 회원 탈퇴 시 즉시 파기</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>게시물(채용정보)신청</TableCell>
                <TableCell>채용정보 지원자 확인 및 관리, 지원 결과 안내</TableCell>
                <TableCell>[필수] 이름, 닉네임, 전화번호, 이메일</TableCell>
                <TableCell>지원취소 또는 회원 탈퇴 시 즉시 파기</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>작업 정보전송</TableCell>
                <TableCell>작업자의 작업현황(상태)을 확인하기 위함.</TableCell>
                <TableCell>[필수] 개인위치정보</TableCell>
                <TableCell>
                  1 년 보관 후 파기 (회원탈퇴시 1년 이내 개인을 식별할 수 없는 형태로 보관 및 통계
                  활용)
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>서류 제출</TableCell>
                <TableCell>작업자의 작업결과를 확인하기 위함.</TableCell>
                <TableCell>[선택] 작업결과 관련 서류</TableCell>
                <TableCell>1 년 보관 후 파기</TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
        <br />
        <Typography variant="body1">
          <br />
          단, 수집한 개인정보를 특정 개인을 알아볼 수 없도록 가명처리하여 통계작성, 과학적 연구,
          공익적 기록보존 등을 위하여 처리할 수 있습니다. 이 때 가명정보는 재식별되지 않도록
          추가정보와 분리하여 별도 저장・관리하고 필요한 기술적・관리적 보호조치를 취합니다.
          <br />
          <br />
          서비스 이용 과정에서 쿠키, IP 주소, 로그인 기록, 불량 이용 기록 등의 서비스 이용 기록,
          기기정보가 생성되어 수집될 수 있습니다.
          <br />- 서비스 이용 과정에서 회원에 관한 정보를 정보통신서비스 제공자가 자동화된 방법으로
          생성하여 이를 저장(수집)
          <br />- 회원 기기의 고유한 정보를 원래의 값을 확인하지 못하도록 안전하게 변환한 후에
          수집하는 경우를 의미
          <br />
          <br />
          추가로 개인정보를 수집할 경우에는 해당 개인정보 수집 시점에서 회원에게 ‘수집하는 개인정보
          항목, 개인정보의 수집 및 이용목적, 개인정보의 보관기간’에 대해 안내 드리고 동의를
          받습니다.
          <br />
          <br />
          <strong>3. 개인정보의 처리 및 보유기간 </strong>
          <br />
          개인정보는 수집 및 이용목적이 달성되면 지체없이 파기하며, 절차 및 방법은 다음과 같습니다.
          수집 및 이용 목적의 달성 또는 회원 탈퇴 등 파기 사유가 발생한 개인정보는 개인정보의 형태를
          고려하여 파기방법을 정합니다. 전자적 파일 형태인 경우 복구 및 재생되지 않도록 안전하게
          삭제하고, 그 밖에 기록물, 인쇄물, 서면 등의 경우 분쇄하거나 소각하여 파기합니다.
          <br />
          다만, 내부방침에 보관 후 파기하는 정보는 아래와 같습니다.
          <br />- 개인정보는 탈퇴 즉시 파기(예외 개인작업 위치정보) 이 외에 법령에 따라 일정기간
          보관해야 하는 개인정보 및 해당 법령은 아래 표와 같습니다.
        </Typography>
        <TableContainer>
          <Table sx={{ width: 1000, height: 300 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>보존항목</strong>
                </TableCell>
                <TableCell>
                  <strong>근거법령</strong>
                </TableCell>
                <TableCell>
                  <strong>보존기간</strong>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>계약 또는 청약철회 등에 관한 기록</TableCell>
                <TableCell>전자상거래 등에서의 소비자 보호에 관한 법률</TableCell>
                <TableCell>5년</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>대금결제 및 재화 등의 공급에 관한 기록</TableCell>
                <TableCell>전자상거래 등에서의 소비자 보호에 관한 법률</TableCell>
                <TableCell>5년</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>소비자의 불만 또는 분쟁처리에 관한 기록</TableCell>
                <TableCell>전자상거래 등에서의 소비자 보호에 관한 법률</TableCell>
                <TableCell>3년</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>표시/광고에 관한 기록</TableCell>
                <TableCell>전자상거래 등에서의 소비자 보호에 관한 법률</TableCell>
                <TableCell>6개월</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>세법이 규정하는 모든 거래에 관한 장부 및 증빙서류</TableCell>
                <TableCell>국세기본법</TableCell>
                <TableCell>5년</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>전자금융 거래에 관한 기록</TableCell>
                <TableCell>전자금융거래법</TableCell>
                <TableCell>5년</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>서비스 방문 기록</TableCell>
                <TableCell>통신비밀보호법</TableCell>
                <TableCell>3개월</TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
        <br />
        <Typography variant="body1">
          <br />
          회원"의 개인정보를 가장 소중한 가치로 여기고 개인정보를 처리함에 있어서 다음과 같은 노력을
          다하고 있습니다.
          <br />
          <br />
          <strong>회원의 개인정보를 암호화하고 있습니다.</strong>
          <br />
          회원의 개인정보를 암호화된 통신구간을 이용하여 전송하고, 비밀번호 등 중요정보는 암호화하여
          보관하고 있습니다.
          <br />
          <br />
          <strong>해킹이나 컴퓨터 바이러스로부터 보호하기 위하여 노력하고 있습니다.</strong>
          <br />
          해킹이나 컴퓨터 바이러스 등에 의해 회원의 개인정보가 유출되거나 훼손되는 것을 막기 위해
          외부로부터 접근이 통제된 구역에 시스템을 설치하고 있습니다. 해커 등의 침입을 탐지하고
          차단할 수 있는 시스템을 설치하여 24시간 감시하고 있으며, 백신 프로그램을 설치하여 시스템이
          최신 악성코드나 바이러스에 감염되지 않도록 노력하고 있습니다. 또한 새로운 해킹/보안 기술에
          대해 지속적으로 연구하여 서비스에 적용하고 있습니다.
          <br />
          <br />
          <strong>소중한 개인정보에 접근할 수 있는 사람을 최소화하고 있습니다.</strong>
          <br />
          개인정보를 처리하는 직원을 최소화 하며, 개인정보를 보관하는 데이터베이스 시스템과
          개인정보를 처리하는 시스템에 대한 비밀번호의 생성과 변경, 그리고 접근할 수 있는 권한에
          대한 체계적인 기준을 마련하고 지속적인 감사를 실시하고 있습니다.
          <br />
          <br />
          임직원에게 회원의 개인정보 보호에 대해 정기적인 교육을 실시하고 있습니다. 개인정보를
          처리하는 모든 임직원들을 대상으로 개인정보보호 의무와 보안에 대한 정기적인 교육과 캠페인을
          실시하고 있습니다.
          <br />
          <br />
          <strong>4. 개인정보의 제3자 제공</strong>
          <br />① "회사"는 회원의 개인정보를 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의
          특별한 규정에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.
          <br />② 법령의 규정에 의거하거나, 수사, 조사 목적으로 법령에 정해진 절차와 방법에 따라
          수사기관 및 감독당국의 요구가 있는 경우
          <br />③ "회사"는 "회원" 당사자간의 거래에서 계약의 체결 및 이행과 대금정산, 분쟁의 해결을
          위해 필요한 경우 상대방에게 회원의 개인정보를 제공합니다.
          <br /> - 전화번호, 성명, 생년월일, 사업자정보(사업자번호)
          <br /> - 작업자의 작업위치(GPS)정보
          <br />④ 그 밖에 개인정보를 제3자에게 제공이 필요한 경우에는 "회원"의 동의를 얻는 등 적법한
          절차를 통하여 제3자에게 개인정보를 제공할 수 있습니다. "회사"는 "회원"들의 거래 이행을
          위하여 필요한 경우 회원의 동의를 얻는 등 적법한 절차를 통하여 아래와 같이 개인정보를
          제공할 수 있습니다.
        </Typography>
        <TableContainer>
          <Table sx={{ width: 1000 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>제공받는 자</strong>
                </TableCell>
                <TableCell>
                  <strong>개인정보 이용목적</strong>
                </TableCell>
                <TableCell>
                  <strong>제공하는 개인정보의 항목</strong>
                </TableCell>
                <TableCell>
                  <strong>보유 및 이용기간</strong>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>게시물 등록한 "회원"</TableCell>
                <TableCell>지원 및 채용절차 진행</TableCell>
                <TableCell>닉네임, 연락처, 이메일, 위치정보</TableCell>
                <TableCell>지원 취소, 게시물 삭제 및 회원탈퇴시 즉시 파기</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>게시물 등록한 "회원"</TableCell>
                <TableCell>지원 및 작업절차 진행</TableCell>
                <TableCell>닉네임, 연락처</TableCell>
                <TableCell>지원 취소, 게시물 삭제 및 회원탈퇴시 즉시 파기</TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
        <Typography variant="body1">
          <br />
          <br />
          <strong>5. 스마트폰 어플리케이션 관리</strong>
          <br />
          스마트폰 어플리케이션을 통해 서비스 이용 시, "회원"에게 개인정보 수집·이용 동의를 받은
          범위 내에서 단말기정보 등 정보가 수집되거나 전송됩니다. 어플리케이션 상에서 "회원"이
          단말기 접근 권한을 허용하였다고 하여 허용권한과 관련된 모든 정보가 즉시 수집되거나
          전송되는 것은 아닙니다. 서비스 제공을 위하여 스마트폰 어플리케이션의 접근 권한을 필수 또는
          선택적으로 "회원"에게 요청하고 있으며, 단말기 내 "설정" 메뉴를 통하여 "회원"이 직접 권한을
          변경할 수 있습니다. 어플리케이션 권한에 대한 자세한 사항은 애플리케이션 스토어를 통해
          확인하실 수 있습니다.
          <br />
          <br />
          스마트폰 상에서 어플리케이션을 삭제하더라도 "회원"의 회원계정은 유지되므로, 회원탈퇴를
          원하실 경우 “회원탈퇴” 기능을 이용하시거나 고객센터로 연락하여 주시기 바랍니다.
          <br />
          <br />
          <strong>6. 개인위치정보의 처리</strong>
          <br />
          위치정보의 보호 및 이용 등에 관한 법률(이하 ‘위치정보법’)에 따라 다음과 같이
          개인위치정보를 처리합니다.
          <br />① 위치기반서비스 제공을 위해 최소한의 기간 동안 개인위치정보를 보유할 수 있습니다.
          <br />② 수집 및 이용 목적의 달성 또는 회원 탈퇴 등 개인위치정보 처리목적이 달성된 경우,
          개인위치정보를 복구 및 재생되지 않도록 안전하게 삭제합니다.
          <br />
          다만, 다른 법령에 따라 보관해야 하는 등 정당한 사유가 있는 경우에는 그에 따릅니다. 또한,
          위치정보법 제16조2항에 따라 회원의 위치정보의 이용ㆍ제공사실 확인자료를 위치정보시스템에
          1년간 보관합니다.
          <br />
          <br />
          <strong>7. 기타</strong>
          <br /> "회사"는 "회원" 개개인에게 개인화되고 맞춤화된 서비스를 제공하기 위해 "회원"의
          정보를 저장하고, 수시로 불러오는 '쿠키(cookie)'를 사용합니다. PC 기반 서비스의 제공을
          위하여 쿠키를 이용하는 경우가 있습니다.
          <br />
          <br />
          쿠키는 보다 빠르고 편리한 웹사이트 사용을 지원하고 맞춤형 서비스를 제공하기 위해
          사용됩니다.
          <br />- 쿠키란? 웹사이트를 운영하는데 이용되는 서버가 "회원"의 브라우저에 보내는 아주 작은
          텍스트 파일로서 회원 컴퓨터에 저장됩니다.
          <br />- 사용목적 개인화되고 맞춤화된 서비스를 제공하기 위해서 "회원"의 정보를 저장하고
          수시로 불러오는 쿠키를 사용합니다. "회원"이 웹사이트에 방문할 경우 웹 사이트 서버는
          "회원"의 디바이스에 저장되어 있는 쿠키의 내용을 읽어 "회원"의 환경설정을 유지하고 맞춤화된
          서비스를 제공하게 됩니다. 쿠키는 "회원"이 웹 사이트를 방문할 때, 웹 사이트 사용을
          설정한대로 접속하고 편리하게 사용할 수 있도록 돕습니다. 또한, "회원"의 웹사이트 방문 기록,
          이용 형태를 통해서 최적화된 광고 등 맞춤형 정보를 제공하기 위해 활용됩니다.
          <br />- 쿠키 수집 거부
          <br />
          "회원"은 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, "회원"은 웹 브라우저에서
          옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 모든
          쿠키의 저장을 거부할 수도 있습니다. 다만, 쿠키 설치를 거부할 경우 웹 사용이 불편해지며,
          로그인이 필요한 일부 서비스 이용에 어려움이 있을 수 있습니다.
          <br />- 설정 방법의 예
          <br /> 1) Internet Explorer의 경우
          <br />웹 브라우저 상단의 도구 메뉴 {'>'} 인터넷 옵션 {'>'}
          개인정보 {'>'} 설정
          <br /> 2) Chrome의 경우
          <br />웹 브라우저 우측의 설정 메뉴 {'>'} 화면 하단의 고급 설정 표시 {'>'} 개인정보의
          콘텐츠 설정 버튼 {'>'} 쿠키
          <br />
          <br />
          개인정보보호와 관련해서 궁금하신 사항은? 서비스를 이용하면서 발생하는 모든 개인정보보호
          관련 문의, 불만, 조언이나 기타 사항은 개인정보 보호책임자 및 담당부서로 연락해 주시기
          바랍니다. "회사"는 여러분의 목소리에 귀 기울이고 신속하고 충분한 답변을 드릴 수 있도록
          최선을 다하겠습니다.
        </Typography>
        <TableContainer>
          <Table sx={{ width: 500, height: 50 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>개인정보 보호책임자 및 담당부서</strong>
                </TableCell>
                <TableCell>
                  <strong>위치정보 보호책임자 및 담당부서</strong>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  책임자: 박상영 (개인정보 보호책임자)
                  <br />
                  문의 : sypark@yeonisoft.com
                </TableCell>
                <TableCell>
                  책임자: 이경한 (위치정보관리책임자)
                  <br />
                  문의 : bzey2018@yeonisoft.com
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
        <Typography variant="body1">
          <br />
          <br />
          또한 개인정보가 침해되어 이에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하셔서
          도움을 받으실 수 있습니다.
          <br />
        </Typography>
        <TableContainer>
          <Table sx={{ width: 550, height: 50 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>개인정보침해 신고센터</strong>
                  <br />⦁ (국번없이)118 <br />⦁ https://privacy.kisa.or.kr
                </TableCell>
                <TableCell>
                  <strong>대검찰청 사이버수사과</strong>
                  <br />⦁ (국번없이)1301 <br />⦁ cid@spo.go.kr
                </TableCell>
                <TableCell>
                  <strong>경찰청 사이버수사국</strong>
                  <br />⦁ (국번없이)182 <br />⦁ https://ecrm.cyber.go.kr
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
        <Typography variant="body1">
          개인정보 처리방침이 변경되는 경우 법률이나 서비스의 변경사항을 반영하기 위한 목적 등으로
          개인정보 처리방침을 수정할 수 있습니다. 개인정보 처리방침이 변경되는 경우 "회사"는 변경
          사항을 게시하며, 변경된 개인정보 처리방침은 게시한 날로부터 15일 후 부터 효력이
          발생합니다.
          <br />
          <br />
          다만, 수집하는 개인정보의 항목, 이용목적의 변경 등과 같이 회원 권리의 중대한 변경이 발생할
          때에는 최소 15일 전 에 미리 알려드리겠습니다.
          <br />
          <br />
          공고일자: 2022.11.01
          <br />
          시행일자: 2022.11.01
          <br />
          <br />
        </Typography>
      </Stack>
    </div>
  );
}
