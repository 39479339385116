import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Stack, TextField, Button, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Withdrawal from './Withdrawal';
import ChangePassWordPopup from './ChangePassWordPopup';

const BASE_URL = process.env.REACT_APP_API_URL;
// component
// ----------------------------------------------------------------------

export default function UpdateRegisterForm() {
  // const navigate = useNavigate();
  // const [deleteAccountOpen, setDeleteAccountOpen] = useState(false);
  const [changePasswordOpen, setChangePasswordOpen] = useState(false);
  const [changePasswordClose, setChangePasswordClose] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [checkid, setCheckId] = useState(false);
  const [checkNickname, setCheckNickname] = useState(false);
  const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,24}$/;
  const idRegex = /^(?=.*[a-z])(?=.*[^A-Z0-9]).{4,12}$/;
  const phoneRegex = /^(?=.*[0-9]).{9,11}$/;
  // ----------------------------------------API 호출----------------------------------------------

  const checkIdApi = async (checkid) => {
    const apiUrl = `${BASE_URL}member/checkId`;
    const sendParams = {
      id: checkid
    };
    axios
      .post(apiUrl, sendParams)
      .then((response) => {
        console.log(response.data);
        if (response.data.code === '200') {
          setCheckId(true);
        } else if (response.data.code === '500') {
          setCheckId(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return checkid;
  };
  // ----------------------------------------API 호출----------------------------------------------
  const getData = async () => {
    const sendParams = { id: sessionStorage.getItem('id') };
    const apiUrl = `${BASE_URL}/member/getMemberInfo`;
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    };
    axios
      // .post(apiUrl, sendParams, headers)
      .post(apiUrl, sendParams, headers)
      .then((response) => {
        console.log(response.data);
        // console.log(strBinary);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // ----------------------------------------API 호출----------------------------------------------
  const checkNicknameApi = async (checkNickname) => {
    const apiUrl = `${BASE_URL}/member/checkNcm`;
    const sendParams = {
      param: checkNickname
    };
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    };
    axios
      .post(apiUrl, sendParams, headers)
      .then((response) => {
        console.log(response.data);
        if (response.data.code === '200') {
          setCheckNickname(true);
        } else if (response.data.code === '500') {
          setCheckNickname(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return checkNickname;
  };
  const UpdateRegisterSchema = Yup.object().shape({
    username: Yup.string().required('이름을 입력해주세요'),
    ncm: Yup.string()
      .required('닉네임을 입력해주세요')
      .min(2, '2자이상 입력해주세요')
      .test('checkNickname', '이미사용중인 닉네임 입니다.', (value) => {
        if (sessionStorage.getItem('ncm') === value) {
          return true;
        }
        if (sessionStorage.getItem('ncm') !== value) {
          console.log(sessionStorage.getItem('ncm'));
          console.log(value);
          checkNicknameApi(value);
          if (checkNickname === false) {
            return false;
          }
          if (checkNickname === true) {
            return true;
          }
        }
      }),
    email: Yup.string().email('Email 주소가 아닙니다').required('Email을 입력해주세요'),
    phoneNum: Yup.string()
      .required('전화번호를 입력해주세요')
      .min(10, '형식이 맞지 않습니다')
      .max(13, '형식이 맞지 않습니다')
    // .test('checkPhoneNum', '형식에 맞지 않습니다', (value) => {
    // })
    // .matches(phoneRegex, { message: '숫자만 가능합니다.' })
  });
  const formik = useFormik({
    initialValues: {
      id: sessionStorage.getItem('id'),
      username: sessionStorage.getItem('mberNm'),
      ncm: sessionStorage.getItem('ncm'),
      phoneNum: sessionStorage.getItem('mbtlnum'),
      email: sessionStorage.getItem('mberEmailAdres'),
      bizrno: sessionStorage.getItem('bizrno'),
      passWord: sessionStorage.getItem('password')
    },
    validationSchema: UpdateRegisterSchema,
    onSubmit: (values) => {
      console.log(values);
      if (!values.bizrno) {
        values.bizrno = ' ';
      }
      saveApiCall(values);
    }
  });
  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  // -------------------------------------------------------------------------------------
  const saveApiCall = async (value) => {
    const apiUrl = `${BASE_URL}/member/updMember`;
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    };
    const sendParams = {
      mberNm: value.username,
      id: value.id,
      ncm: value.ncm,
      mbtlnum: value.phoneNum,
      mberEmailAdres: value.email,
      bizrno: value.bizrno
    };
    axios
      // .post(apiUrl, sendParams, headers)
      .post(apiUrl, sendParams, headers)
      .then((response) => {
        console.log(response.data);
        if (response.data.code === '200') {
          window.alert('수정되었습니다');
          sessionStorage.setItem('id', value.id);
          sessionStorage.setItem('mberNm', value.username);
          sessionStorage.setItem('ncm', value.ncm);
          sessionStorage.setItem('mbtlnum', value.phoneNum);
          sessionStorage.setItem('mberEmailAdres', value.email);
          sessionStorage.setItem('bizrno', value.bizrno);
          getData();
          window.location.reload();
        }
        if (response.data.code === '500') {
          window.alert('다시 시도해주세요');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // ---------------------------------------------------------------------------------------
  // 비밀번호 변경 API
  // const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  // const ChangePasswordApi = async (values) => {
  //   const apiUrl = `${BASE_URL}/member/updMemberPasswd`;
  //   const headers = {
  //     headers: {
  //       Authorization: `Bearer ${sessionStorage.getItem('token')}`
  //     }
  //   };
  //   const sendParams = {
  //     id: sessionStorage.getItem('id'),
  //     pw: values.password
  //   };
  //   axios
  //     .post(apiUrl, sendParams, headers)
  //     .then((response) => {
  //       // console.log(response.data);
  //       if (response.data.code === '200') {
  //         window.alert('변경되었습니다. 로그인 페이지로 이동합니다.');
  //         // getMemberInfo();
  //         navigate('/login');
  //       } else {
  //         window.alert('변경에 실패하였습니다.');
  //       }
  //       // setMyWorkList(response.data);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };
  // ---------------------------------------------------------------------------------------
  const handleChangePasswordDialogOpen = () => {
    setChangePasswordOpen(true);
  };
  const handleChangePasswordDialogClose = () => {
    setChangePasswordOpen(false);
  };
  // ----------------------------------------------------------------------------------
  // useEffect
  useEffect(() => {
    // getData();
  }, []);
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={2}>
          <Stack direction="row" justifyContent="space-between" spacing={1}>
            <TextField
              fullWidth
              size="small"
              label="아이디"
              {...getFieldProps('id')}
              error={Boolean(touched.id && errors.id)}
              helperText={touched.id && errors.id}
              InputProps={{
                readOnly: true
              }}
            />
          </Stack>
          <TextField
            fullWidth
            size="small"
            label="닉네임 - 중복체크는 자동 입니다."
            {...getFieldProps('ncm')}
            error={Boolean(touched.ncm && errors.ncm)}
            helperText={touched.ncm && errors.ncm}
            // onChange={onChangeNickname}
            // value={sessionNcm}
          />
          <TextField
            fullWidth
            size="small"
            autoComplete="username"
            type="username"
            label="이름"
            {...getFieldProps('username')}
            error={Boolean(touched.username && errors.username)}
            helperText={touched.username && errors.username}
            // onChange={onChangeName}
            // value={sessionName}
          />

          <TextField
            fullWidth
            size="small"
            autoComplete="phoneNum"
            label="전화번호"
            {...getFieldProps('phoneNum')}
            error={Boolean(touched.phoneNum && errors.phoneNum)}
            helperText={touched.phoneNum && errors.phoneNum}
            // onChange={onChangeTel}
            // value={sessionPhone}
          />

          <TextField
            fullWidth
            size="small"
            autoComplete="email"
            type="email"
            label="이메일"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
            // onChange={onChangeEmail}
            // value={sessionEmail}
          />
          <TextField
            fullWidth
            size="small"
            autoComplete="bizrno"
            type="bizrno"
            label="사업자번호"
            {...getFieldProps('bizrno')}
            error={Boolean(touched.bizrno && errors.bizrno)}
            helperText={touched.bizrno && errors.bizrno}
            // onChange={(e) => e.target.value}
            // value={sessionCompanyNo}
          />
          <Stack direction="row" justifyContent="space-between" spacing={0.5}>
            {/* <Typography variant="body1">비밀번호 변경시 버튼을 클릭해주세요</Typography> */}
            <Button
              size="medium"
              variant="outlined"
              sx={{ width: '100%' }}
              onClick={handleChangePasswordDialogOpen}
            >
              비밀번호 변경
            </Button>
            {changePasswordOpen && (
              <ChangePassWordPopup
                changePasswordOpen={changePasswordOpen}
                handleChangePasswordDialogClose={handleChangePasswordDialogClose}
              />
            )}
            {/* <FormikProvider value={formik}>
              <Dialog
                open={changePasswordOpen}
                onClose={handleChangePasswordDialogClose}
                PaperProps={{ sx: { width: '20%', height: '40%' } }}
              >
                <DialogTitle align="center">비밀번호 재설정</DialogTitle>
                <br />
                <DialogContent>
                  <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Stack direction="column" justifyContent="space-between" spacing={2}>
                      <TextField
                        fullWidth
                        size="small"
                        autoComplete="new-password"
                        // type={showPassword ? 'text' : 'password'}
                        type="password"
                        label="신규 비밀번호"
                        {...getFieldProps('password')}
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                        // onChange={validate}
                        value={formik.values.password}
                      />
                      <TextField
                        fullWidth
                        size="small"
                        autoComplete="new-password"
                        // type={showPassword ? 'text' : 'password'}
                        type="password"
                        label="신규 비밀번호 확인"
                        {...getFieldProps('passwordCheck')}
                        error={Boolean(touched.passwordCheck && errors.passwordCheck)}
                        helperText={touched.passwordCheck && errors.passwordCheck}
                        // onChange={onChangeCheckPw}
                        value={formik.values.passwordCheck}
                      />
                    </Stack>
                    <br />
                    <Typography variant="body2">
                      ※ 비밀번호는 최소 8자 이상 최대 24자 이하
                      <br />
                      ※ 숫자/영어 대소문자/특수문자 모두 포함
                      <br />
                      ※ 사용가능 특수문자 : ! @ # $ % ^ & + =
                      <br />
                    </Typography>
                    <br />

                    <Stack direction="row" justifyContent="center" spacing={1}>
                      <LoadingButton
                        size="medium"
                        type="submit"
                        variant="contained"
                        // disabled={!isValid || handleSubmit}
                        loading={isSubmitting}
                      >
                        저장
                      </LoadingButton>
                      <Button variant="contained" onClick={handleChangePasswordDialogClose}>
                        닫기
                      </Button>
                    </Stack>
                  </Form>
                </DialogContent>
              </Dialog>
            </FormikProvider> */}
          </Stack>
          {/* <Typography>
            ※ 비밀번호는 최소 8자 이상 최대 24자 이하
            <br />
            ※ 숫자/영어 대소문자/특수문자 모두 포함
            <br />
            ※ 사용가능 특수문자 : ! @ # $ % ^ & + =
            <br />
          </Typography> */}

          <LoadingButton
            fullWidth
            size="medium"
            type="submit"
            variant="contained"
            // disabled={!isValid || handleSubmit}
            loading={isSubmitting}
            // sx={{ width: '80%' }}
          >
            저장
          </LoadingButton>
        </Stack>
        {/* <Button variant="outlined" onClick={handleDeleteAccountOpen}>
            회원탈퇴
          </Button>
          <Dialog
            open={deleteAccountOpen}
            onClose={handleDeleteAccountClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">회원을 탈퇴하시겠습니까?</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                회원탈퇴을 하시면 개인정보는 모두 삭제되며, 해당 계정의 복구가 불가능합니다. 탈퇴를
                원하시면 동의 버튼을 눌러주세요
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={DeleteAccountApi} autoFocus>
                동의
              </Button>
              <Button onClick={handleDeleteAccountClose}>취소</Button>
            </DialogActions>
          </Dialog> */}
      </Form>
    </FormikProvider>
  );
}
