import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
// material
import { Stack, Button, Container, Typography, Box } from '@mui/material';
// component
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
// ----------------------------------------------------------------------
const BASE_URL = process.env.REACT_APP_API_URL;

export default function UpdateRegisterEquipmentForm() {
  const todayMonth = new Date();
  const year = todayMonth.getFullYear();
  const month = todayMonth.getMonth() + 1;
  const day = todayMonth.getDate();
  const nowMonth = month < 10 ? `0${month}` : `${month}`;
  const nowday = day < 10 ? `0${day}` : `${day}`;
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  // TEST [] 추가
  const [equipmentList, setEequipmentList] = useState([]);
  const [equipmentNm, setEquipmentNm] = useState(); // 장비명
  const [equipmentNo, setEquipmentNo] = useState(); // 장비고유번호
  const [equipmentType, setEquipmentType] = useState(); // 장비종류
  const [equipmentNoplt, setEquipmentNoplt] = useState(); // 장비번호판
  const [equipmentQy, setEquipmentQy] = useState(); // 장비년식
  // 수정 Parameter
  const [modiEquipmentNm, setModiEquipmentNm] = useState(); // 장비명
  const [modiEquipmentNo, setModiEquipmentNo] = useState(); // 장비고유번호
  const [modiEquipmentType, setModiEquipmentType] = useState(); // 장비종류
  const [modiEquipmentNoplt, setModiEquipmentNoplt] = useState(); // 장비번호판
  const [modiEquipmentQy, setModiEquipmentQy] = useState(); // 장비년식
  // Dialog
  const [equipmentRegOpen, setEquipmentRegOpen] = useState(false);
  const [equipmentModiOpen, setEquipmentModiOpen] = useState(false);

  const MY_EQUIPMENT_HEAD = [
    {
      field: 'id',
      headerName: '번호',
      align: 'center',
      width: 10,
      hide: true,
      valueGetter: (params) => params.row.eqpmnNo
    },
    { field: 'eqpmnNm', headerName: '장비명', alignRight: false, width: 200 },
    { field: 'eqpmnKnd', headerName: '장비종류', alignRight: false, width: 200 },
    { field: 'eqpmnNoplt', headerName: '장비번호판', type: 'date', alignRight: false, width: 200 },
    { field: 'eqpmnQy', headerName: '장비년식', type: 'date', alignRight: false, width: 200 },
    // { field: 'etc', headerName: '비고', type: 'date', alignRight: false, width: 200 },
    { field: 'eqpmnNo', headerName: 'workNo', alignRight: false, hide: true }
  ];

  // ---------------------------------------장비-----------------------------------------
  const getEqpmnList = async () => {
    const sendParams = { id: sessionStorage.getItem('id') };
    const apiUrl = `${BASE_URL}/member/getEqpmnList`;
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    };
    axios
      // .post(apiUrl, sendParams, headers)
      .post(apiUrl, sendParams, headers)
      .then((response) => {
        console.log(response.data);
        setEequipmentList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const regEquipmentApi = async () => {
    const apiUrl = `${BASE_URL}/member/regEqpmn`;
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    };
    const sendParams = {
      id: sessionStorage.getItem('id'),
      eqpmnNm: equipmentNm,
      eqpmnNoplt: equipmentNoplt,
      eqpmnQy: equipmentQy,
      eqpmnKnd: equipmentType,
      delYn: 'N'
    };
    axios
      .post(apiUrl, sendParams, headers)
      .then((response) => {
        console.log(response.data);
        if (response.data.code === '200') {
          window.alert('저장되었습니다.');
          handleUpdDialogClose();
          getEqpmnList();
        } else if (response.data.code === '500') {
          window.alert('저장에 실패하였습니다. 다시 시도해주세요');
          handleUpdDialogClose();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const updateEquipmentApi = async () => {
    const apiUrl = `${BASE_URL}/member/updEqpmn`;
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    };
    const sendParams = {
      id: sessionStorage.getItem('id'),
      eqpmnNo: modiEquipmentNo,
      eqpmnNm: modiEquipmentNm,
      eqpmnNoplt: modiEquipmentNoplt,
      eqpmnQy: modiEquipmentQy,
      eqpmnKnd: modiEquipmentType,
      delYn: 'N'
    };
    axios
      .post(apiUrl, sendParams, headers)
      .then((response) => {
        console.log(response.data);
        if (response.data.code === '200') {
          window.alert('저장되었습니다.');
          getEqpmnList();
          handleModiDialogClose();
        } else if (response.data.code === '500') {
          window.alert('저장에 실패하였습니다. 다시 시도해주세요');
          handleModiDialogClose();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const deleteEquipmentApi = async () => {
    const apiUrl = `${BASE_URL}/member/delEqpmn`;
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    };
    const sendParams = {
      id: sessionStorage.getItem('id'),
      eqpmnNo: modiEquipmentNo,
      // eqpmnNm: modiEquipmentNm,
      // eqpmnNoplt: modiEquipmentNoplt,
      // eqpmnQy: modiEquipmentQy,
      // eqpmnKnd: modiEquipmentType,
      delYn: 'N'
    };
    axios
      .post(apiUrl, sendParams, headers)
      .then((response) => {
        console.log(response.data);
        if (response.data.code === '200') {
          window.alert('저장되었습니다.');
          getEqpmnList();
          setModiEquipmentNo('');
          handleUpdDialogClose();
        } else if (response.data.code === '500') {
          window.alert('저장에 실패하였습니다. 다시 시도해주세요');
          handleUpdDialogClose();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // ----------------------------------Dialog Open Event--------------------------------
  // 등록 Dialog Open
  const handleUpdDialogOpen = () => {
    setEquipmentNm();
    setEquipmentNoplt();
    setEquipmentQy();
    setEquipmentType();
    setEquipmentRegOpen(true);
  };
  // 등록 Dialog Close
  const handleUpdDialogClose = () => {
    setEquipmentRegOpen(false);
    setEquipmentNm();
    setEquipmentNoplt();
    setEquipmentQy();
    setEquipmentType();
  };
  // 수정 Dialog Open
  const handleModiDialogOpen = () => {
    if (!equipmentNo) {
      window.alert('수정할 목록을 선택해주세요');
    } else {
      setEquipmentModiOpen(true);
    }
  };
  // 수정 Dialog Close
  const handleModiDialogClose = () => {
    setEquipmentModiOpen(false);
  };
  // -----------------------------------onChang Event------------------------------------
  // 장비명
  const onChangeEquipmentNm = (e) => {
    setEquipmentNm(e.target.value);
  };
  // 장비종류
  const onChangeEquipmentType = (e) => {
    setEquipmentType(e.target.value);
  };
  // 장비번호판
  const onChangeEquipmentNoplt = (e) => {
    setEquipmentNoplt(e.target.value);
  };
  // 장비년식
  const onChangeEquipmentQy = (e) => {
    setEquipmentQy(e.target.value);
  };
  // -------------------------------------수정------------------------------------------------
  // 장비명
  const onChangeModiEquipmentNm = (e) => {
    setModiEquipmentNm(e.target.value);
  };
  // 장비종류
  const onChangeModiEquipmentType = (e) => {
    setModiEquipmentType(e.target.value);
  };
  // 장비번호판
  const onChangeModiEquipmentNoplt = (e) => {
    setModiEquipmentNoplt(e.target.value);
  };
  // 장비년식
  const onChangeModiEquipmentQy = (e) => {
    setModiEquipmentQy(e.target.value);
  };
  // const handleRegEquipment = () => {
  //   if (!equipmentNo) {
  //     window.alert('수정할 장비를 선택해주세요');
  //   } else if (window.confirm('선택한 장비를 수정하시겠습니까?')) {
  //     updateEquipmentApi();
  //   }
  // };
  // ------------------------------Validation 및 Comfirm---------------------------------------------
  const regValidationCheck = () => {
    const today = `${year}-${nowMonth}-${nowday}`;
    // equipmentList
    // equipmentNm // 장비명
    // equipmentNo // 장비고유번호
    // equipmentType // 장비종류
    // equipmentNoplt // 장비번호판
    // equipmentQy // 장비년식
    if (!equipmentNm) {
      window.alert('장비명을 입력해주세요!');
      return false;
    }
    if (!equipmentNoplt) {
      window.alert('장비번호를 입력해주세요');
      return false;
    }
    if (!equipmentType) {
      window.alert('장비종류를 입력해주세요');
      return false;
    }
    if (!equipmentQy) {
      window.alert('장비연식을 입력해주세요');
      return false;
    }
    // 장비연식이 오늘 보다 미래일때
    if (equipmentQy > today) {
      window.alert('장비연식을 확인해주세요');
      return false;
    }
    return true;
  };
  const saveQuestion = () => {
    if (!regValidationCheck()) return;
    if (window.confirm('저장 하시겠습니까?')) {
      regEquipmentApi();
      handleUpdDialogClose();
    }
  };
  const updValidationCheck = () => {
    const today = `${year}-${nowMonth}-${nowday}`;
    if (!modiEquipmentNo) {
      window.alert('수정할 장비를 선택해주세요');
    }
    // equipmentList
    // equipmentNm // 장비명
    // equipmentNo // 장비고유번호
    // equipmentType // 장비종류
    // equipmentNoplt // 장비번호판
    // equipmentQy // 장비년식
    if (!modiEquipmentNm) {
      window.alert('장비명을 입력해주세요!');
      return false;
    }
    if (!modiEquipmentType) {
      window.alert('장비종류를 입력해주세요');
      return false;
    }
    if (!modiEquipmentNoplt) {
      window.alert('장비 번호를 입력해주세요');
      return false;
    }
    if (!modiEquipmentQy) {
      window.alert('장비연식을 입력해주세요');
      return false;
    }
    // if (!equipmentQy) {
    //   window.alert('적성검사유효 시작일을 입력해주세요');
    //   return false;
    // }
    // 장비연식이 오늘 보다 미래일때
    if (modiEquipmentQy > today) {
      window.alert('장비연식을 확인해주세요');
      return false;
    }
    return true;
  };
  const updateQuestion = () => {
    if (!updValidationCheck()) return;
    if (window.confirm('저장 하시겠습니까?')) {
      updateEquipmentApi();
      handleModiDialogClose();
    }
  };
  // const handleModiEquipment = () => {
  //   if (!equipmentNo) {
  //     window.alert('수정할 장비를 선택해주세요');
  //   } else if (window.confirm('선택한 장비를 수정하시겠습니까?')) {
  //     updateEquipmentApi();
  //   }
  // };
  const handleDeleteEquipment = () => {
    if (!modiEquipmentNo) {
      window.alert('삭제할 장비를 선택해주세요');
    } else if (window.confirm('선택한 장비를 삭제하시겠습니까?')) {
      deleteEquipmentApi();
    }
  };
  const onRowClick = (params) => {
    setEquipmentNo(params.eqpmnNo);
    setEquipmentNm(params.eqpmnNm); // 장비명
    setEquipmentType(params.eqpmnKnd); // 장비종류
    setEquipmentNoplt(params.eqpmnNoplt); // 장비번호판
    setEquipmentQy(params.eqpmnQy); // 장비년식
    // 수정 Parameter
    setModiEquipmentNo(params.eqpmnNo);
    setModiEquipmentNm(params.eqpmnNm); // 장비명
    setModiEquipmentType(params.eqpmnKnd); // 장비종류
    setModiEquipmentNoplt(params.eqpmnNoplt); // 장비번호판
    setModiEquipmentQy(params.eqpmnQy); // 장비년식
  };
  // -----------------------------------useEffect--------------------------------------
  useEffect(() => {
    getEqpmnList();
  }, []);
  // ----------------------------------------------------------------------------------
  return (
    // 추가 삭제 Button
    <Box spacing={1}>
      <Stack direction="row" justifyContent="space-between">
        <Stack>
          <Typography variant="h6" gutterBottom align="left">
            보유 장비
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
          <Button variant="outlined" onClick={handleUpdDialogOpen}>
            추가
          </Button>
          <Dialog
            open={equipmentRegOpen}
            onClose={handleUpdDialogClose}
            PaperProps={{ sx: { width: '50%', height: '32%' } }}
          >
            <DialogTitle align="center">보유장비 추가 등록</DialogTitle>
            <DialogContent>
              <Stack direction="column" justifyContent="space-between" spacing={3}>
                <Stack direction="row" justifyContent="space-between" spacing={1}>
                  <TextField
                    fullWidth
                    label="장비명"
                    type="text"
                    variant="standard"
                    value={equipmentNm || ''}
                    onChange={onChangeEquipmentNm}
                  />
                  <TextField
                    fullWidth
                    label="장비번호판"
                    type="text"
                    variant="standard"
                    value={equipmentNoplt || ''}
                    onChange={onChangeEquipmentNoplt}
                  />
                </Stack>
                <br />
                <Stack direction="row" justifyContent="space-between" spacing={1}>
                  <TextField
                    fullWidth
                    // label="장비종류"
                    type="text"
                    variant="standard"
                    value={equipmentType || ''}
                    helperText="장비종류"
                    onChange={onChangeEquipmentType}
                  />
                  <TextField
                    fullWidth
                    type="date"
                    variant="standard"
                    value={equipmentQy || ''}
                    helperText="장비년식"
                    onChange={onChangeEquipmentQy}
                  />
                </Stack>

                <br />
              </Stack>
              <Stack direction="row" justifyContent="center" spacing={1}>
                <Button variant="contained" onClick={saveQuestion}>
                  저장
                </Button>
                <Button variant="contained" onClick={handleUpdDialogClose}>
                  닫기
                </Button>
              </Stack>
            </DialogContent>
          </Dialog>
          <Button variant="outlined" onClick={handleModiDialogOpen}>
            수정
          </Button>
          <Dialog
            open={equipmentModiOpen}
            onClose={handleModiDialogClose}
            PaperProps={{ sx: { width: '50%', height: '32%' } }}
          >
            <DialogTitle align="center">보유장비 내용수정</DialogTitle>
            <DialogContent>
              <Stack direction="column" justifyContent="space-between" spacing={3}>
                <Stack direction="row" justifyContent="space-between" spacing={1}>
                  <TextField
                    fullWidth
                    label="장비명"
                    type="text"
                    variant="standard"
                    value={modiEquipmentNm || ''}
                    onChange={onChangeModiEquipmentNm}
                  />
                  <TextField
                    fullWidth
                    label="장비번호판"
                    type="text"
                    variant="standard"
                    value={modiEquipmentNoplt || ''}
                    onChange={onChangeModiEquipmentNoplt}
                  />
                </Stack>
                <br />
                <Stack direction="row" justifyContent="space-between" spacing={1}>
                  <TextField
                    fullWidth
                    // label="장비종류"
                    type="text"
                    variant="standard"
                    value={modiEquipmentType || ''}
                    helperText="장비종류"
                    onChange={onChangeModiEquipmentType}
                  />
                  <TextField
                    fullWidth
                    type="date"
                    variant="standard"
                    value={modiEquipmentQy || ''}
                    helperText="장비년식"
                    onChange={onChangeModiEquipmentQy}
                  />
                </Stack>

                <br />
              </Stack>
              <Stack direction="row" justifyContent="center" spacing={1}>
                <Button variant="contained" onClick={updateQuestion}>
                  저장
                </Button>
                <Button variant="contained" onClick={handleModiDialogClose}>
                  닫기
                </Button>
              </Stack>
            </DialogContent>
          </Dialog>
          <Button variant="outlined" onClick={handleDeleteEquipment}>
            삭제
          </Button>
        </Stack>
      </Stack>
      <div style={{ height: 300, width: '100%' }}>
        <DataGrid
          rows={equipmentList}
          rowHeight={30}
          columns={MY_EQUIPMENT_HEAD}
          getRowId={(row) => row.eqpmnNo}
          onRowClick={(params) => onRowClick(params.row)}
          hideFooterPagination
          disableColumnMenu
        />
      </div>
    </Box>
  );
}
