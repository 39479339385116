import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { Card, CardHeader, Box } from '@mui/material';
//
import { BaseOptionChart } from '../../../components/charts';
import chartData from '../../../chart_data/MainChart';

// ----------------------------------------------------------------------

const income = chartData.chartlist.map((value) => value.income);
const sales = chartData.chartlist.map((value) => value.sales);
const vat = chartData.chartlist.map((value) => value.vat);
// console.log(jan.income);

const CHART_DATA = [
  {
    name: '매출',
    type: 'column',
    data: [income]
  },
  {
    name: '매입',
    type: 'column',
    data: [sales]
  },
  {
    name: '부가세',
    type: 'line',
    data: [vat]
  }
];

export default function AppWebsiteVisits() {
  const chartOptions = merge(BaseOptionChart(), {
    stroke: { width: [2, 2, 3] },
    plotOptions: { bar: { columnWidth: '15%', borderRadius: 4 } },
    fill: { type: ['solid', 'gradient', 'solid'] },
    labels: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
    xaxis: { type: 'month' },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)} 지출`;
          }
          return y;
        }
      }
    }
  });

  return (
    <Card>
      <CardHeader title="월별 통계" subheader="" />
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart type="line" series={CHART_DATA} options={chartOptions} height={364} />
      </Box>
    </Card>
  );
}
