import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
// import { Box, Card, Link, Container, Typography } from '@mui/material';
import { Box, Link, Container, Typography, Stack, Grid } from '@mui/material';
// layouts
import HomeIcon from '@mui/icons-material/Home';
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { FindIdForm, FindPasswordForm } from '../sections/authentication/login';
import Image from '../imgs/architecture-1541086_1920.jpg';
// import AuthSocial from '../sections/authentication/AuthSocial';

// ----------------------------------------------------------------------

const styles = {
  paperContainer: {
    height: 929,
    backgroundImage: `url(${Image})`,
    opacity: 0.9
  }
};
// ----------------------------------------------------------------------

export default function FindIdPassword() {
  return (
    <Page style={styles.paperContainer}>
      <AuthLayout>
        <Stack direction="row" spacing={1} sx={{ my: 1 }}>
          <Link underline="none" variant="subtitle2" component={RouterLink} to="/">
            <HomeIcon label="홈" size="large" style={{ color: 'white', fontSize: '32px' }}>
              홈으로
            </HomeIcon>
          </Link>
        </Stack>
      </AuthLayout>
      <Box
        style={{
          borderRadius: 10,
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)'
        }}
        // minHeight="100vh"
        display="flex"
        justifyContent="center"
        sx={{
          // opacity: 0.5,
          width: '50%',
          height: 'flex',
          backgroundColor: 'white'
          // '&:hover': {
          //   backgroundColor: 'white',
          //   opacity: [0.5, 0.5, 0.9]
          // }
        }}
      >
        <Grid item xs={12} md={6} lg={5}>
          <Box m={2} pt={3}>
            <Typography align="center" variant="h4" gutterBottom>
              아이디 찾기
            </Typography>
            <FindIdForm />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={5}>
          <Box m={2} pt={3}>
            <Typography align="center" variant="h4" gutterBottom>
              비밀번호 찾기
            </Typography>
            <FindPasswordForm />
          </Box>
        </Grid>
      </Box>
    </Page>
  );
}
