// material
// import { Box, Grid, Container, Typography } from '@mui/material';
import * as Yup from 'yup';
import {
  Stack,
  Button,
  TextField,
  Typography,
  Paper,
  BottomNavigation,
  BottomNavigationAction
} from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { LoadingButton } from '@mui/lab';
// components
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Page from '../components/Page';
import Image1 from '../imgs/home2.png';
// import Image1 from '../imgs/home1.png';
// import Image2 from '../imgs/home2.png';
// import Image3 from '../imgs/home3.png';

const styles1 = {
  paperContainer: {
    height: '100%',
    width: '100%',
    backgroundImage: `url(${Image1})`
    // opacity: 0.9
  }
};
// const styles2 = {
//   paperContainer: {
//     height: '100%',
//     width: '100%',
//     backgroundImage: `url(${Image2})`
//     // opacity: 0.9
//   }
// };

const BASE_URL = process.env.REACT_APP_API_URL;
// https://play.google.com/store/apps/details?id=com.yeonisoft.geondal

export default function DashboardApp() {
  const handlePasswordChange = () => {
    if (sessionStorage.getItem('passwordInitlYn') === 'Y') {
      setChangePasswordOpen(true);
      // handleChangePasswordDialogOpen();
    }
  };
  useEffect(() => {
    // getMemberInfo();
    console.log(sessionStorage);
    handlePasswordChange();
    if (sessionStorage.getItem('token') === undefined || sessionStorage.getItem('token') === null) {
      navigate('/login');
    }
  }, []);
  // console.log(sessionStorage);
  // console.log(sessionStorage.getItem('ncm'));
  const [changePasswordOpen, setChangePasswordOpen] = useState(false);
  const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,24}$/;
  // const [errorMsg, setErrorMsg] = useState();
  const [show, setShow] = useState(false);
  const [bottomValue, setBottomValue] = useState();
  const navigate = useNavigate();
  // console.log('dashboard');
  // ------------------------------------------------------------------------------
  const newPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required('비밀번호를 입력해주세요')
      .min(7, '8자 이상 입력해주세요')
      .max(24, '24자 이하로 입력해주세요')
      .matches(passwordRegex, {
        message: '숫자+영문자+특수문자 조합으로 8자리 이상 입력해주세요!'
      }),
    passwordCheck: Yup.string()
      .required('비밀번호를 한번 더 입력해주세요')
      .oneOf([Yup.ref('password'), null], '비밀번호가 일치하지 않습니다.')
  });
  const formik = useFormik({
    initialValues: {
      id: '',
      password: '',
      passwordCheck: ''
    },
    validationSchema: newPasswordSchema,
    onSubmit: (values) => {
      ChangePasswordApi(values);
    }
  });
  // ---------------------------------------------------------------------------------------
  // 비밀번호 변경 API
  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  const ChangePasswordApi = async (values) => {
    const apiUrl = `${BASE_URL}/member/updMemberPasswd`;
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    };
    const sendParams = {
      id: sessionStorage.getItem('id'),
      pw: values.password
    };
    axios
      .post(apiUrl, sendParams, headers)
      .then((response) => {
        // console.log(response.data);
        if (response.data.code === '200') {
          window.alert('변경되었습니다. 로그인 페이지로 이동합니다.');
          // getMemberInfo();
          navigate('/login');
        } else {
          window.alert('변경에 실패하였습니다.');
        }
        // setMyWorkList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // ---------------------------------------------------------------------------------------
  const handleChangePasswordDialogOpen = () => {
    setChangePasswordOpen(true);
  };
  const handleChangePasswordDialogClose = () => {
    setChangePasswordOpen(false);
  };
  const onClickService = () => {
    navigate('/dashboard/ALTermsOfService');
  };
  const onClickPersonal = () => {
    navigate('/dashboard/ALTermsOfPersonalInfo');
  };
  const onClickLocation = () => {
    navigate('/dashboard/ALTermsOfLocationInfo');
  };
  // ---------------------------------------------------------------------------------------
  return (
    <Page title="중기365" styles={styles1.paperContainer}>
      {/* <Stack sx={{ width: '30%' }} spacing={2} alignItems="left">
        <Alert severity="success">
          <AlertTitle>로그인</AlertTitle>
          로그인되었습니다
        </Alert>
      </Stack> */}
      {/* <Container maxWidth="xl"> */}
      {/* <Box
        style={{
          borderRadius: 10,
          position: 'absolute'
          // left: '100%',
          // top: '100%',
          // transform: 'translate(-50%, -50%)'
        }}
        // minHeight="100vh"
        display="flex"
        justifyContent="center"
        sx={{
          // opacity: 0.5,
          width: 'flex',
          height: 'flex',
          backgroundColor: 'white'
          // '&:hover': {
          //   backgroundColor: 'white',
          // opacity: [0.9, 0.9, 1]
          // }
        }}
      /> */}
      {/* <div className="container">
        <img src={Image1} alt="fireSpot" />
      </div>
      <div className="container">
        <img src={Image2} alt="fireSpot" />
      </div>
      <div className="container">
        <img src={Image3} alt="fireSpot" />
      </div> */}
      {/* <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={3}>
            <AppInOutComeTotal />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppInOutCome />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppExcclcKnd />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppOrderTotal />
          </Grid>
          <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits />
          </Grid> */}
      {/* <Grid item xs={12} md={6} lg={6}>
            <AppRecrumentNotice />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <AppNotice />
          </Grid> */}
      {/* <Grid item xs={12} md={6} lg={4}>
            <AppNews />
          </Grid> */}
      {/* <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits />
          </Grid> */}
      {/* </Grid> */}
      {/* </Container> */}
      <div>
        {show && <Button onClick={handleChangePasswordDialogOpen} />}
        <FormikProvider value={formik}>
          <Dialog
            open={changePasswordOpen}
            onClose={handleChangePasswordDialogClose}
            PaperProps={{ sx: { width: '20%', height: '40%' } }}
          >
            <DialogTitle align="center">비밀번호 재설정</DialogTitle>
            <br />
            <DialogContent>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack direction="column" justifyContent="space-between" spacing={2}>
                  <TextField
                    fullWidth
                    size="small"
                    autoComplete="new-password"
                    // type={showPassword ? 'text' : 'password'}
                    type="password"
                    label="비밀번호"
                    {...getFieldProps('password')}
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                    // onChange={validate}
                    value={formik.values.password}
                  />
                  <TextField
                    fullWidth
                    size="small"
                    autoComplete="new-password"
                    // type={showPassword ? 'text' : 'password'}
                    type="password"
                    label="비밀번호 확인"
                    {...getFieldProps('passwordCheck')}
                    error={Boolean(touched.passwordCheck && errors.passwordCheck)}
                    helperText={touched.passwordCheck && errors.passwordCheck}
                    // onChange={onChangeCheckPw}
                    value={formik.values.passwordCheck}
                  />
                </Stack>
                <br />
                <Typography variant="body2">
                  ※ 비밀번호는 최소 8자 이상 최대 24자 이하
                  <br />
                  ※ 숫자/영어 대소문자/특수문자 모두 포함
                  <br />
                  ※ 사용가능 특수문자 : ! @ # $ % ^ & + =
                  <br />
                </Typography>
                <br />

                <Stack direction="row" justifyContent="center" spacing={1}>
                  <LoadingButton
                    size="medium"
                    type="submit"
                    variant="contained"
                    // disabled={!isValid || handleSubmit}
                    loading={isSubmitting}
                  >
                    저장
                  </LoadingButton>
                  <Button variant="contained" onClick={handleChangePasswordDialogClose}>
                    닫기
                  </Button>
                </Stack>
              </Form>
            </DialogContent>
          </Dialog>
        </FormikProvider>
      </div>
      <Paper
        sx={{ position: 'fixed', bottom: 0, left: 1, right: 0 }}
        elevation={10}
        // backgroundcolor="dark"
      >
        <BottomNavigation
          showLabels
          value={bottomValue}
          onChange={(event, newValue) => {
            setBottomValue(newValue);
          }}
        >
          <BottomNavigationAction label="서비스 이용약관" onClick={onClickService} />
          <BottomNavigationAction label="개인정보 처리방침" onClick={onClickPersonal} />
          <BottomNavigationAction label="위치기반 서비스 이용약관" onClick={onClickLocation} />
          {/* <BottomNavigationAction label="Contact Us" icon={<CallRoundedIcon />} /> */}
        </BottomNavigation>
      </Paper>
    </Page>
  );
}
