/* eslint-disable prettier/prettier */
// import { filter } from 'lodash';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Stack,
  Button,  
  Container,
  Typography,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@mui/material';
// component
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
// import DialogActions from '@mui/material/DialogActions';
// ----------------------------------------------------------------------
// Set the Style
// const useStyle = makeStyle( () => ({
//   paper: { minWidth: "400px" }
// }));
const TABLE_HEAD = [
  { id: 'cntrwkmNo', label: '번호', align: 'center', width: 10 },
  { id: 'fair', label: '공정', align: 'center' },
  { id: 'division', label: '구분', alignRight: false },
  { id: 'productName', label: '품목/이름', alignRight: false },
  { id: 'standard', label: '규격', alignRight: false, width: 15 },
  { id: 'ea', label: '수량', alignRight: false, width: 10 },
  { id: 'price', label: '금액', alignRight: false },
  { id: 'etc', label: '비고', alignRight: false }
];

function createData(
  id, 
  cntwkmDailyNo, // 공사일보 고유번호
  cntrwkmNo, // 공사 고유번호
  cntrwkmKnd, // 공종
  cntrwkmGnr, // 세대, 위치
  cntrwkmDailDate, // 날짜
  cntrwkmDailyUser, // 등록자
  cntrwkmDailEtc // 특이사항
) {
return { 
  id, 
  cntwkmDailyNo, // 공사일보 고유번호
  cntrwkmNo, // 공사 고유번호
  cntrwkmKnd, // 공종
  cntrwkmGnr, // 세대, 위치
  cntrwkmDailDate, // 날짜
  cntrwkmDailyUser, // 등록자
  cntrwkmDailEtc // 특이사항
};
}

export default function ConstructionThirdCell() {  
  const [fair, setFair] = useState();
  const [division, setDivision] = useState();
  const [productName, setProductName] = useState();
  const [standard, setStandard] = useState();
  const [ea, setEa] = useState();
  const [price, setPrice] = useState();
  const [etc, setEtc] = useState();
  const [dialogOpen, setDialogOpen] = useState(false);
  // Dialog Open
  const handleClickOpen = () => {
    setDialogOpen(true);
  };
  const handleClickClose = () => {
    setFair('');
    setDivision('');
    setProductName('');
    setStandard('');
    setEa('');
    setPrice('');
    setEtc('');    
    setDialogOpen(false);
  };
  return (
    // 추가 삭제 Button
    <Container spacing={1}>
      <Stack direction="row" justifyContent="space-between">
        <Stack>
          <Typography variant="h6" gutterBottom align="center">
            금일작업목록
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
          <Button variant="outlined" onClick={handleClickOpen}>
            추가
          </Button>
          <Button variant="outlined" onClick={handleClickOpen}>
            저장
          </Button>
          <Dialog open={dialogOpen} onClose={handleClickClose} PaperProps={{ sx: { width: "50%", height: "85%"}}}>
            <DialogTitle align="center">금일작업목록</DialogTitle>
            <DialogContent>
              <Stack direction="column" justifyContent="space-between" spacing={3}>
                <TextField
                  label="공정"
                  type="text"
                  variant="standard"
                  name="fair"
                  value={fair}
                />
                <TextField
                label="구분"
                type="text"
                variant="standard"
                name="division"
                value={division}
                />
                <TextField
                label="품목/이름"
                type="text"
                variant="standard"
                name="productName"
                value={productName}
                />
                <TextField
                label="규격"
                type="text"
                variant="standard"
                name="standard"
                value={standard}
                />
                <TextField
                label="수량"
                type="number"
                variant="standard"
                name="ea"
                value={ea}
                />              
                <TextField
                label="금액"
                type="number"
                variant="standard"
                name="price"
                value={price}
                />
                <TextField
                label="비고"
                type="text"
                variant="standard"
                name="etc"
                value={etc}
                />
                <br/>
              </Stack>
              <Stack direction="row" justifyContent="center" spacing={1}>
                <Button variant="contained">저장</Button>
                <Button variant="contained" onClick={handleClickClose}>
                  취소
                </Button>
              </Stack>
            </DialogContent>
          </Dialog>
          <Button variant="contained" component={RouterLink} to="#">
            삭제
          </Button>
        </Stack>
      </Stack>
      <div>
        <TableContainer sx={{ height: 200, width: '100%' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>번호</TableCell>
                <TableCell>공정</TableCell>
                <TableCell>위치</TableCell>
                <TableCell>날짜</TableCell>
                <TableCell>특이사항</TableCell>
                <TableCell>작성자</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {/* <TableCell align="left" onClick={onClick}>
                  {constructionList.cntrwkmNo}
                </TableCell>
                <TableCell align="left" onClick={onClick}>
                  {constructionList.cntrwkmKnd}
                </TableCell>
                <TableCell align="left" onClick={onClick}>
                  {constructionList.cntrwkmGnr}
                </TableCell>
                <TableCell align="left" onClick={onClick}>
                  {constructionList.cntrwkmDailDate}
                </TableCell>
                <TableCell align="left" onClick={onClick}>
                  {constructionList.cntrwkmDailEtc}
                </TableCell>
                <TableCell align="left" onClick={onClick}>
                  {constructionList.cntrwkmDailyUser}
                </TableCell> */}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Container>
  );
}
