import { useCallback, useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import {
  Stack,
  Box,
  Link,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  TableHead
} from '@mui/material';
// import { Card, Stack, Link, Container, Typography } from '@mui/material';
// layouts
import HomeIcon from '@mui/icons-material/Home';
// components
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import AuthLayout from '../layouts/AuthLayout';
import Page from '../components/Page';

// const BASE_URL = process.env.REACT_APP_API_URL;
const divStyle = {
  // opacity: 0.5,
  top: '15%',
  left: '15%',
  width: 'flex',
  height: 'flex',
  justifyContent: 'center',
  position: 'absolute'
  // backgroundColor: 'white',
  // '&:hover': {
  //   backgroundColor: 'white',
};

// --------------------------------------------------------------------------------------------------------------
export default function ALTermsOfService() {
  useEffect(() => {}, []);
  return (
    <Page>
      <Box>
        <div>
          {/* <Box
        style={{
          borderRadius: 10,
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)'
        }}
        // minHeight="100vh"
        display="flex"
        justifyContent="center"
        // overflow="scroll"
        sx={{
          // opacity: 0.5,
          width: 'flex',
          height: 700,
          // backgroundColor: 'white',
          // '&:hover': {
          //   backgroundColor: 'white',
          opacity: [0.7, 0.7, 0.95]
          // overflow="scroll"
          // }
        }}
      > */}
          <Stack direction="column">
            <Typography variant="h4" align="left">
              위치기반 서비스 이용약관
            </Typography>
            <br />
            <Typography variant="body1">
              <strong>제 1 조 (목적)</strong>
              <br />
              본 약관은 주식회사 여니(이하 "회사")가 제공하는 위치기반서비스에 대해 "회사" 와
              위치기반서비스를 이용하는 개인위치정보주체(이하 "회원")간의 권리·의무 및 책임사항,
              기타 필요한 사항 규정을 목적으로 합니다.
              <br />
              <br />
              <strong>제 2 조 (약관 외 준칙)</strong>
              <br />① 본 약관은 "회원"이 본 약관에 동의하고 "회사"가 정한 절차에 따라
              위치기반서비스의 "회원"으로 등록됨으로써 효력이 발생합니다.
              <br />② "회원"이 본 약관의 “동의하기” 버튼을 클릭하였을 경우 본 약관의 내용을 모두
              읽고 이를 충분히 이해하였으며, 그 적용에 동의한 것으로 봅니다.
              <br />③ "회사"는 위치기반서비스의 변경사항을 반영하기 위한 목적 등으로 필요한 경우
              관련 법령을 위배하지 않는 범위에서 본 약관을 수정할 수 있습니다.
              <br />④ 약관이 변경되는 경우 "회사"는 변경사항을 그 적용일자 최소 15일 전에 서비스의
              웹사이트 또는 모바일 어플리케이션을 통해 공지합니다. 다만, 개정되는 내용이 "회원"
              권리의 중대한 변경을 발생시키는 경우 적용일 최소 15일 전에 이메일(이메일주소가 없는
              경우 서비스 내 전자쪽지 발송, 서비스 내 알림 메시지를 띄우는 등의 별도의 전자적 수단)
              발송 또는 등록한 휴대폰번호로 문자메시지를 발송하는 방법 등으로 개별적으로 고지합니다.
              <br />⑤ "회사"가 전항에 따라 공지 또는 통지를 하면서 공지 또는 통지일로부터 개정약관
              시행일 15일 후까지 거부의사를 표시하지 아니하면 승인한 것으로 본다는 뜻을 명확하게
              고지하였음에도 "회원"의 의사표시가 없는 경우에는 변경된 약관을 승인한 것으로 봅니다.
              "회원"이 개정약관에 동의하지 않을 경우 본 약관에 대한 동의를 철회할 수 있습니다. 다만,
              서비스 이용 및 제공이 제한 될 수 있습니다.
              <br />
              <br />
              <strong>제 3 조 (이용약관의 효력 및 변경) </strong>
              <br />① 본 약관은 "회원"이 본 약관에 동의하고 "회사"가 정한 절차에 따라
              위치기반서비스의 "회원"으로 등록됨으로써 효력이 발생합니다.
              <br />② "회원"이 본 약관의 “동의하기” 버튼을 클릭하였을 경우 본 약관의 내용을 모두
              읽고 이를 충분히 이해하였으며, 그 적용에 동의한 것으로 봅니다.
              <br />③ "회사"는 위치기반서비스의 변경사항을 반영하기 위한 목적 등으로 필요한 경우
              관련 법령을 위배하지 않는 범위에서 본 약관을 수정할 수 있습니다.
              <br />④ 약관이 변경되는 경우 "회사"는 변경사항을 그 적용일자 최소 15일 전에 서비스의
              웹사이트 또는 모바일 어플리케이션을 통해 공지합니다. 다만, 개정되는 내용이 "회원"
              권리의 중대한 변경을 발생시키는 경우 적용일 최소 15일 전에 이메일(이메일주소가 없는
              경우 서비스 내 전자쪽지 발송, 서비스 내 알림 메시지를 띄우는 등의 별도의 전자적 수단)
              발송 또는 등록한 휴대폰번호로 문자메시지를 발송하는 방법 등으로 개별적으로 고지합니다.
              <br />⑤ "회사"가 전항에 따라 공지 또는 통지를 하면서 공지 또는 통지일로부터 개정약관
              시행일 15일 후까지 거부의사를 표시하지 아니하면 승인한 것으로 본다는 뜻을 명확하게
              고지하였음에도 "회원"의 의사표시가 없는 경우에는 변경된 약관을 승인한 것으로 봅니다.
              "회원"이 개정약관에 동의하지 않을 경우 본 약관에 대한 동의를 철회할 수 있습니다. 다만,
              서비스 이용 및 제공이 제한 될 수 있습니다.
              <br />
              <br />
              <strong>제 4 조 (서비스의 내용) </strong>
              <br />
              “회사”는 "회원"의 위치정보를 이용하여 아래와 같은 위치기반서비스를 제공합니다.
              <br />
            </Typography>
            <TableContainer>
              <Table sx={{ width: 500, height: 100 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong>"회원" 유형</strong>
                    </TableCell>
                    <TableCell>
                      <strong>서비스 내용</strong>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      작업자
                      <br />
                      (게시물 신청사)
                    </TableCell>
                    <TableCell>
                      1. 작업자의 위치제공
                      <br />
                      2. 작업장소 위치 정보 제공
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
            <br />
            <strong>제 5 조 (서비스 이용요금)</strong>
            <br />
            "회사"가 제공하는 위치기반서비스는 무료입니다. 단, 무선 서비스 이용 시 발생하는 데이터
            통신료는 별도이며, "회원"이 가입한 각 이동통신사의 정책에 따릅니다.
            <br />
            <br />
            <strong>제 6 조(서비스 변경·제한·중지) </strong>
            <br />① "회사"는 정책변경 또는 관련법령 변경 등과 같은 제반 사정을 이유로
            위치기반서비스를 유지할 수 없는 경우 위치기반서비스의 전부 또는 일부를 변경·제한·중지할
            수 있습니다.
            <br />② "회사"는 아래 각호의 경우에는 "회원"의 서비스 이용을 제한하거나 중지시킬 수
            있습니다.
            <br /> - "회원"이 "회사" 서비스의 운영을 고의 또는 중과실로 방해하는 경우
            <br /> - 서비스용 설비 점검, 보수 또는 공사로 인하여 부득이한 경우
            <br /> - 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지했을 경우
            <br /> - 국가비상사태, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 서비스 이용에
            지장이 있는 때
            <br /> - 기타 중대한 사유로 인하여 "회사"가 서비스 제공을 지속하는 것이 부적당하다고
            인정하는 경우
            <br /> - 다른 "회원"의 개인위치정보를 수집, 저장, 공개하는 행위
            <br />③ "회사"가 제1항 및 제2항의 규정에 의하여 서비스 이용을 제한하거나 중지한 때에는
            그 사유 및 제한기간 등을 서비스 웹사이트 및 모바일 어플리케이션을 통해 사전 공지하거나
            "회원"에게 통지합니다.
            <br />④ "회사"가 전항에 따른 게시 및 통지를 할 수 없는 부득이한 사유가 있는 경우 사후에
            게시 및 통지할 수 있습니다.
            <br />
            <br />
            <strong>제 7 조(개인위치정보의 이용 또는 제공)</strong>
            <br />① "회사"는 개인위치정보를 이용하여 위치기반서비스를 제공하는 경우 본 약관에
            고지하고 동의를 받습니다.
            <br />② "회사"는 위치기반서비스를 제공하기 위하여 본 약관 동의시 제3자에게
            개인위치정보를 제공하는 것을 동의 한 것으로 간주합니다.
            <br />
            <br />
            <strong>제 8 조(개인위치정보의 보유 목적 및 보유기간)</strong>
            <br />
            "회사"는 위치기반서비스 제공을 위해 아래와 같이 개인위치정보를 보유합니다.
            <br /> - "회사"는 "회원"이 탈퇴 후 1년간 보관합니다.
            <br /> - "회사"는 위치정보의 보호 및 이용 등에 관한 법률 제16조 제2항에 근거하여
            개인위치정보 이용·제공사실 확인자료를 자동으로 기록·보존하며, 해당 자료는 1년간
            보관합니다.
            <br />
            <br />
            <strong>제 9 조(개인위치정보주체의 권리 및 행사방법)</strong>
            <br />① "회원"은 "회사"에 대하여 언제든지 개인위치정보를 이용한 위치기반서비스 제공 및
            개인위치정보의 제3자 제공에 대한 동의를 철회할 수 있습니다. 이 경우 "회사"는 제공받은
            개인위치정보 및 위치정보 이용, 제공사실 확인자료를 파기합니다. 단, "회사"는 "회원"의
            서비스 이용을 제한 할 수 있다.
            <br />② "회원"은 "회사"에 대하여 언제든지 개인위치정보의 이용 또는 제공의 일시적인
            중지를 요구할 수 있고, "회사"는 이를 거절할 수 없으며 이를 위한 기술적 수단을 갖추고
            있습니다.
            <br />③ "회원"은 "회사"에 대하여 아래 자료에 대한 열람 또는 고지를 요구할 수 있고, 해당
            자료에 오류가 있는 경우에는 그 정정을 요구할 수 있습니다. 이 경우 "회사"는 정당한 사유
            없이 "회원"의 요구를 거절할 수 없습니다.
            <br /> - 본인에 대한 위치정보 이용, 제공사실 확인자료
            <br /> - 본인의 개인위치정보가 위치정보의 보호 및 이용 등에 관한 법률 또는 다른 법률
            규정에 의하여 제3자에게 제공된 이유 및 내용
            <br />④ "회원"은 권리행사를 위해 본 약관 제13조의 연락처를 이용하여 "회사"에 요청할 수
            있습니다.
            <br />
            <br />
            <strong>제 10 조 (위치정보관리책임자의 지정)</strong>
            <br />① "회사"는 위치정보를 적절히 관리․보호하고 개인위치정보주체의 불만을 원활히 처리할
            수 있도록 실질적인 책임을 질 수 있는 지위에 있는 자를 위치정보관리책임자로 지정해
            운영합니다.
            <br />
            <br />
            <strong>제 11 조(손해배상) </strong>
            <br />
            "회사"가 위치정보의 보호 및 이용 등에 관한 법률 제15조 내지 제26조의 규정을 위반한
            행위로 "회원"에게 손해가 발생한 경우 "회원"은 "회사"에 대하여 손해배상 청구를 할 수
            있습니다. 이 경우 "회사"는 고의, 과실이 없음을 입증하지 못하는 경우 책임을 면할 수
            없습니다.
            <br />
            <br />
            <strong>제 12조 (면책)</strong>
            <br />① "회사"는 다음 각 호의 경우로 위치기반서비스를 제공할 수 없는 경우 이로 인하여
            "회원"에게 발생한 손해에 대해서는 책임을 부담하지 않습니다.
            <br /> - 천재지변 또는 이에 준하는 불가항력의 상태가 있는 경우
            <br /> - "회원"의 귀책사유로 위치기반서비스 이용에 장애가 있는 경우
            <br /> - 기타 "회사"의 고의·과실이 없는 사유로 인한 경우
            <br />② "회사"는 위치기반서비스 및 위치기반서비스에 게재된 정보, 자료, 사실의 신뢰도,
            정확성 등에 대해서는 보증을 하지 않으며 이로 인해 발생한 "회원"의 손해에 대하여는 책임을
            부담하지 아니합니다.
            <br />
            <br />
            <strong>제 13 조(분쟁의 조정 및 기타) </strong>
            <br />① "회사"는 위치정보와 관련된 분쟁의 해결을 위해 "회원"과 성실히 협의합니다.
            <br />② 전항의 협의에서 분쟁이 해결되지 않은 경우, "회사"와 "회원"은 위치정보의 보호 및
            이용 등에 관한 법률 제28조의 규정에 의해 방송통신위원회에 재정을 신청하거나,
            개인정보보호법 제43조의 규정에 의해 개인정보 분쟁조정위원회에 조정을 신청할 수 있습니다.
            <br />
            <br />
            <strong>제 14조 ("회사"의 주소 및 연락처) </strong>
            <br />
            "회사"의 상호 및 주소 등은 다음과 같습니다.
            <br />
            1. 상 호 : 주식회사 여니
            <br />
            2. 대 표 자 : 송연희
            <br />
            3. 주 소 : 경기도 의왕시 이미로 40 인덕원IT밸리 C동 706-2호
            <br />
            4. 대표전화 : 031-8084-3476
            <br />
            <br />
            <Typography variant="h6" align="left">
              부 칙
            </Typography>
            <br />
            <strong>제 1 조(시행일) </strong>
            <br />이 약관은 2022년 11월 01일부터 시행한다.
            <br />
            <br />
            <strong>제 2 조 </strong>
            <br />
            위치정보관리책임자는 2022년 11월을 기준으로 다음과 같이 지정합니다.
            <br />
            1. 책임자 : 이경한 (위치정보관리책임자）
            <br />
            2. 문 의 : bzey2018@yeonisoft.com
            <br />
            <br />
          </Stack>
        </div>
      </Box>
    </Page>
  );
}
