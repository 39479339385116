// material
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';
// component
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(2, 0),
  width: 300,
  height: 100
  // color: theme.palette.error.darker,
  // backgroundColor: theme.palette.error.lighter
}));

// const IconWrapperStyle = styled('div')(({ theme }) => ({
//   margin: 'auto',
//   display: 'flex',
//   borderRadius: '50%',
//   alignItems: 'center',
//   width: theme.spacing(8),
//   height: theme.spacing(8),
//   justifyContent: 'center',
//   marginBottom: theme.spacing(3),
//   color: theme.palette.info.dark,
//   backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.info.dark, 0)} 0%, ${alpha(
//     theme.palette.info.dark,
//     0.24
//   )} 100%)`
// }));

// ----------------------------------------------------------------------
export default function AppAccumulation({
  accumulationIncome,
  accumulationOutcome,
  accumulationVatIncome,
  accumulationVatOutcome
}) {
  return (
    <RootStyle>
      {/* <Typography variant="h3">{fShortenNumber(TOTAL)}</Typography> */}
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        <strong>누적 합계</strong>
        <br />
        매출 : {accumulationOutcome} 원 (부가세 : {accumulationVatOutcome}원)
        <br />
        매입 : {accumulationIncome} 원 (부가세 : -{accumulationVatIncome}원)
      </Typography>
    </RootStyle>
  );
}
