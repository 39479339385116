import { useRef, useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { alpha } from '@mui/material/styles';
import {
  Button,
  Box,
  Divider,
  MenuItem,
  Typography,
  Avatar,
  IconButton,
  Stack
} from '@mui/material';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// components
// import Withdrawal from '../../pages/Withdrawal';
import Iconify from '../../components/Iconify';
import MenuPopover from '../../components/MenuPopover';
//
const BASE_URL = process.env.REACT_APP_API_URL;
// ----------------------------------------------------------------------
// API 정보
// const BASE_URL = 'https://site.yeonisoft.com/';
// const BASE_URL = 'https://apidev.yeonisoft.com/';
// const BASE_URL = 'http://localhost:8080/';

const MENU_OPTIONS = [
  // {
  //   label: '홈',
  //   icon: 'eva:home-fill',
  //   linkTo: '/dashboard/app'
  // },
  {
    label: '개인정보변경',
    icon: 'eva:person-fill',
    linkTo: '/dashboard/UpdateMyInformation'
  }
  // },
  // {
  //   label: '설정',
  //   icon: 'eva:settings-2-fill',
  //   linkTo: '#'
  // }
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [deleteAccountOpen, setDeleteAccountOpen] = useState(false);

  const getData = async () => {
    const sendParams = { id: sessionStorage.getItem('id') };
    const apiUrl = `${BASE_URL}/member/getMemberInfo`;
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    };
    axios
      // .post(apiUrl, sendParams, headers)
      .post(apiUrl, sendParams, headers)
      .then((response) => {
        console.log(response.data);
        // sessionStorage.setItem('token', response.data.token);
        sessionStorage.setItem('id', response.data.id);
        sessionStorage.setItem('mberEmailAdres', response.data.mberEmailAdres);
        sessionStorage.setItem('ncm', response.data.ncm);
        sessionStorage.setItem('mbtlnum', response.data.mbtlnum);
        sessionStorage.setItem('mberNm', response.data.mberNm); // passwordInitlYn
        sessionStorage.setItem('passwordInitlYn', response.data.passwordInitlYn);
        if (!response.data.bizrno) {
          sessionStorage.setItem('bizrno', '');
        } else {
          sessionStorage.setItem('bizrno', response.data.bizrno);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // --------------------------------------------------------------------------------
  // 회원탈퇴 API
  const DeleteAccountApi = async () => {
    const apiUrl = `${BASE_URL}/member/secsnMember`;
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    };
    const sendParams = {};
    axios
      .post(apiUrl, sendParams, headers)
      .then((response) => {
        if (response.data.code === '200') {
          navigate('/login');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // ---------------------------------------------------------------------------------

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleLogout = () => {
    sessionStorage.clear();
    navigate('/login');
  };
  const handleLogin = () => {
    sessionStorage.removeItem('token');
    navigate('/login');
  };
  // --------------------------------------------------------------------------------
  // 회원탈퇴 추가
  const handleDeleteAccountOpen = () => {
    setDeleteAccountOpen(true);
  };
  const handleDeleteAccountClose = () => {
    setDeleteAccountOpen(false);
  };

  useEffect(() => {
    if (sessionStorage.getItem('token') === undefined || sessionStorage.getItem('token') === null) {
      sessionStorage.clear();
      navigate('/dashboard/app');
    } else if (
      sessionStorage.getItem('token') !== undefined ||
      sessionStorage.getItem('token') !== null
    ) {
      getData();
    }
  }, [sessionStorage.getItem('token')]);
  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <Avatar src="" alt="photoURL" />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Stack direction="row" spacing={1}>
            <Stack>
              <>
                <Typography variant="subtitle1" noWrap>
                  {sessionStorage.getItem('ncm')}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                  {sessionStorage.getItem('email')}
                </Typography>
              </>
            </Stack>
            <Stack>
              <>
                {sessionStorage.getItem('token') === null ||
                sessionStorage.getItem('token') === undefined ? (
                  ''
                ) : (
                  <Button size="small" color="inherit" onClick={handleDeleteAccountOpen}>
                    회원탈퇴
                  </Button>
                )}
                <Dialog
                  open={deleteAccountOpen}
                  onClose={handleDeleteAccountClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">회원을 탈퇴하시겠습니까?</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      회원탈퇴을 하시면 개인정보는 모두 삭제되며, 해당 계정의 복구가 불가능합니다.
                      탈퇴를 원하시면 동의 버튼을 눌러주세요
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={DeleteAccountApi} autoFocus>
                      동의
                    </Button>
                    <Button onClick={handleDeleteAccountClose}>취소</Button>
                  </DialogActions>
                </Dialog>
              </>
            </Stack>
          </Stack>
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2 }}
          >
            <Iconify
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />

            {option.label}
          </MenuItem>
        ))}

        <Box sx={{ p: 1, pt: 1.5 }}>
          <Stack spacing={0.5}>
            {sessionStorage.getItem('token') === null ||
            sessionStorage.getItem('token') === undefined ? (
              <Button fullWidth color="inherit" variant="outlined" onClick={handleLogin}>
                로그인
              </Button>
            ) : (
              <Button fullWidth color="inherit" variant="outlined" onClick={handleLogout}>
                로그아웃
              </Button>
            )}
          </Stack>
        </Box>
      </MenuPopover>
    </>
  );
}
