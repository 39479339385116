import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// import { useNavigate } from 'react-router-dom';
// material
import axios from 'axios';
import { Stack, TextField, Button, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// import Iconify from '../../../components/Iconify';
const BASE_URL = process.env.REACT_APP_API_URL;

// ----------------------------------------------------------------------

export default function FindIdForm() {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const [sendEmail, setSendEmail] = useState();
  const [sendMberNm, setSendMberNm] = useState();
  const [certificationNo, setCertificationNo] = useState();
  // const [showPassword, setShowPassword] = useState(false);

  const CheckSchema = Yup.object().shape({
    username: Yup.string().required('이름을 입력해주세요'),
    email: Yup.string().email('Email 주소가 아닙니다').required('Email을 입력해주세요')
  });

  const formik = useFormik({
    initialValues: {
      username: '',
      email: ''
    },
    validationSchema: CheckSchema,
    onSubmit: (value) => {
      // navigate('/dashboard', { replace: true });
      setSendMberNm(value.username);
      setSendEmail(value.email);
      requestCertificationNo(value);
    }
  });

  const requestCertificationNo = async (value) => {
    const apiUrl = `${BASE_URL}/member/requestCrtfckey`;
    const sendParams = {
      email: sendEmail
    };
    axios
      // .post(apiUrl, sendParams, headers)
      .post(apiUrl, sendParams)
      .then((response) => {
        console.log(response.data);
        if (response.data.code === '200') {
          window.alert('Email로 인증번호를 전송하였습니다');
          setShow(true);
          // navigate('/dashboard/app');
        } else if (response.data.code === '500') {
          // setError(response.data.reason);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const findIdApi = async () => {
    const apiUrl = `${BASE_URL}/member/findById`;
    const sendParams = {
      // username: value.username,
      mberNm: sendMberNm,
      crtfckey: certificationNo
    };
    axios
      // .post(apiUrl, sendParams, headers)
      .post(apiUrl, sendParams)
      .then((response) => {
        console.log(response.data);
        if (response.data.code === '200') {
          window.alert(`ID : ${response.data.id} 입니다.`);
          setCertificationNo();
          setSendEmail();
          setShow(false);
          navigate('/login');
        } else if (response.data.code === '500') {
          window.alert(response.data.desc);
          // setError(response.data.reason);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleSendCrtfcKey = () => {
    findIdApi();
  };

  const onChangeCertificationNo = (e) => {
    setCertificationNo(e.target.value);
  };
  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={1}>
          <TextField
            size="small"
            autoComplete="username"
            type="name"
            label="이름"
            {...getFieldProps('username')}
            error={Boolean(touched.username && errors.username)}
            helperText={touched.username && errors.username}
          />
          <TextField
            size="small"
            autoComplete="email"
            type="email"
            label="이메일"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
          {show && (
            <Stack direction="column" justifyContent="space-between" spacing={1}>
              <TextField
                size="small"
                // autoComplete="email"
                type="certicationNo"
                label="인증번호"
                {...getFieldProps('certificationNo')}
                onChange={onChangeCertificationNo}
                // error={Boolean(touched.email && errors.email)}
                helperText="Timer"
              />
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={0.5}>
                <Button fullWidth variant="contained" onClick={handleSendCrtfcKey}>
                  전송
                </Button>
                <Button fullWidth variant="contained" onClick={requestCertificationNo}>
                  인증번호다시받기
                </Button>
              </Stack>
            </Stack>
          )}
          <LoadingButton
            fullWidth
            size="medium"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            아이디 찾기
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

// function certificationPopup() {
//   const [open, setOpen] = useState(false);
//   return (
//     <strong>
//       <Dialog
//         open={open}
//         // onClose={tab3ClickDetailDialogClose}
//         PaperProps={{ sx: { width: '50%', height: '30%' } }}
//       >
//         <DialogTitle align="center">인증번호 입력</DialogTitle>
//         <DialogContent>
//           <Stack direction="column" justifyContent="space-between" spacing={1}>
//             <Stack direction="row" justifyContent="flex-end" spacing={0.5}>
//               <TextField
//                 fullWidth
//                 label="장비명"
//                 type="text"
//                 variant="standard"
//                 // value={tab1EquitmentNm || ''}
//                 // onChange={onChangeTab1Equitment}
//                 inputProps={{
//                   readOnly: true
//                 }}
//               />
//               <TextField
//                 fullWidth
//                 label="작업내용"
//                 type="text"
//                 variant="standard"
//                 // value={tab1WorkCn || ''}
//                 // onChange={onChangeTab1WorkContents}
//                 inputProps={{
//                   readOnly: true
//                 }}
//               />
//             </Stack>
//           </Stack>
//         </DialogContent>
//       </Dialog>
//     </strong>
//   );
// }
