import React from 'react';
import { alpha, styled } from '@mui/material/styles';
import { Card, Stack, Typography } from '@mui/material';
import { Box, height } from '@mui/system';
// import { Row, Col } from 'reactstrap';

function Footer() {
  const thisYear = () => {
    const year = new Date().getFullYear();
    return year;
  };

  return (
    <Box textAlign="left" padding={2}>
      {/* <Typography variant="h3">{fShortenNumber(TOTAL)}</Typography> */}
      <Stack direction="row" justifyContent="left">
        <Typography variant="body2" sx={{ opacity: 0.72 }}>
          위치 : 경기도 의왕시 이미로 40 (인덕원아이티밸리 C동 706-2호)
          <br />
          전화 : 031-8084-3476 팩스 : 031-8084-3477
          <br />
          Copyright 2022. yeonisoft Inc. all rights reserved.
        </Typography>
      </Stack>
    </Box>
  );
}

export default Footer;
