import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
// material
import { Box, Stack, Link, Card, Button, Divider, Typography, CardHeader } from '@mui/material';
// utils
import axios from 'axios';
import Scrollbar from '../../../components/Scrollbar';
import Iconify from '../../../components/Iconify';
// ----------------------------------------------------------------------
// API 정보
const BASE_URL = process.env.REACT_APP_API_URL;
// const BASE_URL = 'https://site.yeonisoft.com/';
// const DEV_BASE_URL = 'https://apidev.yeonisoft.com/';
// const LOCAL_BASE_URL = 'http://localhost:8080/';

export default function AppRecrumentNotice() {
  const [newWorkList, setNewWorkList] = useState([]);
  // -----------------------------------------API 호출--------------------------------------------------
  const getData = async () => {
    const apiUrl = `${BASE_URL}/getWorkListTop5`;
    axios
      // .post(apiUrl, sendParams, headers)
      .post(apiUrl)
      .then((response) => {
        setNewWorkList(response.data);
        // ----------------------------
        NewsItem(response.data);
        // console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // --------------------------------------------------------------------------------------------------
  // ------------------------------------------useEffect-----------------------------------------------
  useEffect(() => {
    getData();
    // const timer = setInterval(() => {
    //   getData();
    // }, 5000);

    // return () => clearInterval(timer);
  }, []);
  // --------------------------------------------------------------------------------------------------
  return (
    <Card sx={{ width: 460, height: 270 }}>
      <CardHeader title="모집공고" />

      <Scrollbar>
        <Stack spacing={0.3} sx={{ p: 3, pr: 0 }}>
          {newWorkList.map((newWorkList) => (
            <NewsItem key={newWorkList.workNo} newWorkList={newWorkList} />
          ))}
        </Stack>
      </Scrollbar>

      {/* <Divider />

      <Box sx={{ p: 0.5, textAlign: 'right' }}>
        <Button
          to="/dashboard/RecruitmentNotice"
          size="small"
          color="inherit"
          component={RouterLink}
          endIcon={<Iconify icon="eva:arrow-ios-forward-fill" />}
        >
          전체보기
        </Button>
      </Box> */}
    </Card>
  );
}

NewsItem.propTypes = {
  newWorkList: PropTypes.object.isRequired
};

function NewsItem({ newWorkList }) {
  // console.log(newWorkList);
  const {
    eqpmnNm,
    regDate,
    workOrnt,
    workAdres,
    pymntMthd,
    workAmount,
    workBegin,
    workEndde,
    beginTime,
    enddeTime
  } = newWorkList;
  return (
    <Stack direction="row" alignItems="space-between" spacing={1}>
      <Box sx={{ minWidth: 300, maxHeight: 270 }}>
        <Link to="/" color="inherit" underline="hover" component={RouterLink}>
          <Typography variant="subtitle2" noWrap>
            {workOrnt}
          </Typography>
        </Link>
        <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
          <strong>{eqpmnNm}</strong> | {workAdres} | <strong>{workAmount}</strong>({pymntMthd})
          <br />
          <strong>
            {workBegin} {beginTime.substr(0, 2)}시 ~ {workEndde} {enddeTime.substr(0, 2)}시
          </strong>
        </Typography>
      </Box>
      <Typography variant="caption" sx={{ pr: 3, flexShrink: 0, color: 'text.secondary' }}>
        등록일 <br /> {regDate.substr(0, 16)}
      </Typography>
    </Stack>
  );
}
