import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
// import { Box, Card, Link, Container, Typography } from '@mui/material';
import { Box, Link, Container, Typography, Stack, TextField, Button, Grid } from '@mui/material';
// layouts
import HomeIcon from '@mui/icons-material/Home';
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import {
  RegisterForm,
  RegisterCertificateForm,
  RegisterEquipmentForm
} from '../sections/authentication/register';
import Image from '../imgs/architecture-1541086_1920.jpg';
// ----------------------------------------------------------------------
const styles = {
  paperContainer: {
    height: '100%',
    backgroundImage: `url(${Image})`
    // opacity: 0.9
  }
};
// ----------------------------------------------------------------------

export default function Register(props) {
  // -----------------------------------------------------------------------------
  // const { errors, touched, handleSubmit, isSubmitting } = formik;
  const [certifiData, setCertifiData] = useState([]);
  const [equipmentData, setEquipmentData] = useState();
  return (
    <Page title="회원가입" style={styles.paperContainer}>
      <AuthLayout>
        <Stack direction="row" spacing={1} sx={{ my: 1 }}>
          <Link underline="none" variant="subtitle2" component={RouterLink} to="/">
            <HomeIcon label="홈" size="large" style={{ color: 'white', fontSize: '32px' }}>
              홈으로
            </HomeIcon>
          </Link>
        </Stack>
      </AuthLayout>
      <Box
        style={{
          borderRadius: 10,
          position: 'absolute',
          left: '50%',
          top: '50%',
          overflowY: 'scroll',
          transform: 'translate(-50%, -50%)'
        }}
        // minHeight="100vh"
        display="100%"
        justifyContent="center"
        // overflow="scroll"
        // textOverflow="scroll"
        sx={{
          // opacity: 0.5,
          // width: '70%',
          width: '30%',
          height: '80%',
          backgroundColor: 'white'
          // '&:hover': {
          //   backgroundColor: 'white',
          // opacity: [0.9, 0.9, 1]
          // }
        }}
      >
        <Typography align="center" variant="h4" gutterBottom>
          <br />
          회원가입
        </Typography>
        <Grid padding={3} container justifyContent="space-between" columns={6}>
          <Grid item xs={6}>
            <RegisterForm />
          </Grid>
        </Grid>
        {/* <Grid padding={5} container justifyContent="space-between" spacing={10} columns={16}>
          <Grid item xs={6}>
            <Typography align="center" variant="h4" gutterBottom>
              회원가입
            </Typography>
            <RegisterForm />
          </Grid>
          <Grid item xs={10}>
            <Typography align="center" variant="h4" gutterBottom>
              회원가입 추가정보
            </Typography>
            <RegisterCertificateForm setCertifiData={setCertifiData} />
            <br />
            <RegisterEquipmentForm setEquipmentData={}/>
            <RegisterEquipmentForm />
          </Grid>
        </Grid> */}
        {/* <Grid padding={5} container justifyContent="space-between" spacing={10} columns={16}>
          <Grid item xs={6}>
            <Typography align="center" variant="h4" gutterBottom>
              회원가입
            </Typography>
            <RegisterForm />
          </Grid>
          <Grid item xs={10}>
            <Typography align="center" variant="h4" gutterBottom>
              회원가입 추가정보
            </Typography>
            <RegisterCertificateForm setCertifiData={setCertifiData} />
            <br />
            {/* <RegisterEquipmentForm setEquipmentData={}/> */}
        {/* <RegisterEquipmentForm />
          </Grid>
        </Grid> */}
      </Box>
    </Page>
  );
}
