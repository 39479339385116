/* eslint-disable no-restricted-syntax */
/* eslint-disable no-plusplus */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
// import { createStore } from 'redux';
// import { Provider, useSelector, useDispatch, connect } from 'react-redux';
// material
import { Stack, Button, Typography, Select, MenuItem, Checkbox, Grid } from '@mui/material';
// components
import { DataGrid } from '@mui/x-data-grid';
import { LocalizationProvider, DatePicker } from '@mui/lab';
import { ko } from 'date-fns/locale';
import RefreshIcon from '@mui/icons-material/Refresh'; // RoomIcon
// import { RoomIcon } from '@mui/icons-material/Room';
// import { GridCellParams, GridCellValue, GridCellProps } from '@material-ui/data-grid';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TextareaAutosize from '@mui/material/TextareaAutosize';
// import CurrencyTextField from '@unicef/material-ui-currency-textfield';
// import OutlinedInput from '@mui/material/OutlinedInput';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import { Item } from 'framer-motion/types/components/Reorder/Item';
import Page from '../components/Page';

const BASE_URL = process.env.REACT_APP_API_URL;
// console.log(REACT_APP_API_URL);
// const BASE_URL = 'https://site.yeonisoft.com/';
// const BASE_URL = 'https://apidev.yeonisoft.com/';
// const BASE_URL = 'http://localhost:8080/';

// import { array } from 'yup/lib/locale';
// import SearchNotFound from '../components/SearchNotFound';
// ----------------------------------------------------------------------
// 순서
// 클래스의 경우 -> constructor -> ref -> componentDidMount
// -> (setState/props 바뀔때 -> shouldComponentUpdate -> render -> componentDidUpdate)
// 부모가 나를 없앴을 때 -> componentWillUnmount -> 소멸
// componentDidMount() { 컴포넌트가 첫 렌더링된 후
// };
// componentDidUpdate() { 리렌더링 후
// };
// componentWillUnmount() { 컴포넌트가 제거되기 직전
// };
// ----------------------------------------------------------------------
const theme = createTheme({
  Button: {
    apply: {
      // 지원 A
      main: '#43a047',
      fontWeight: 'bold'
    },
    reject: {
      // 미승인 R
      main: '#b71c1c',
      fontWeight: 'bold'
    },
    applyCancel: {
      // 지원취소 C
      main: '#424242',
      fontWeight: 'bold'
    },
    accept: {
      // 승인 S
      main: '#1a237e',
      fontWeight: 'bold'
    },
    workCancel: {
      // 작업취소
      main: '#BA4798',
      fontWeight: 'bold'
    }
  }
});

const themeTypo = createTheme({
  typography: {
    // 승인
    h5: {
      fontSize: 15,
      color: '#1a237e'
    },
    // 미승인
    h6: {
      fontSize: 15,
      // fontWeight: 500,
      color: '#b71c1c'
    },
    // 지원취소
    body1: {
      fontSize: 15,
      color: '#424242'
    },
    // 지원중
    body2: {
      fontSize: 15,
      color: '#43a047'
    },
    // 작업취소
    body3: {
      fontSize: 15,
      color: '#BA4798'
    }
  }
});
const themeCheck = createTheme({
  typography: {
    body1: {
      fontSize: 12,
      color: '#b71c1c'
    }
  }
});
// ----------------------------------------------------------------------
// ----------------------------------------------------------------------
function RecruitmentNotice() {
  const tempData = [];
  const todayMonth = new Date();
  const year = todayMonth.getFullYear();
  const month = todayMonth.getMonth() + 1;
  const day = todayMonth.getDate();
  const nowMonth = month < 10 ? `0${month}` : `${month}`;
  const nowday = day < 10 ? `0${day}` : `${day}`;
  // 글자수제한
  const EQUIPMENT_LIMIT = 10;
  const PYMNT_MTHD_LIMIT = 10;
  const AMOUNT_LIMIT = 10;

  const [selectedRows, setSelectedRows] = useState([]);
  // const [selectedApplyRows, setSelectedApplyRows] = useState([]);
  // const [applyListParams, setApplyListParams] = useState([]);
  // const [timer, setTimer] = useState(0); // 디바운싱 타이머
  const [checkApplyCnt, setCheckApplyCnt] = useState();
  // const [buttonColor, setButtonColor] = useState();
  // Dialog Parameter
  const navigate = useNavigate();
  const [value, setValue] = useState('1');
  const [myWorkList, setMyWorkList] = useState([]);
  const [myRegWorkList, setMyRegWorkList] = useState([]);
  const [myReqWorkList, setMyReqWorkList] = useState([]);
  const [applyList, setApplyList] = useState([]);
  // 지원자 승인 공사번호 저장
  const [applyMbernm, setApplyMbernm] = useState();
  const [sendApplyNo, setSendApplyNo] = useState();
  const [sendApplyId, setSendApplyId] = useState();
  const [applyStatus, setApplyStatus] = useState();
  // 작업일
  // const [workDate, setWorkDate] = useState();
  // dialog
  const [tab1RecuitmentRegOpen, setRecuitmentRegOpen] = useState(false);
  const [tab1RecuitmentDetailOpen, setTab1RecuitmentDetailOpen] = useState(false);
  const [tab2RecuitmentDetailOpen, setTab2RecuitmentDetailOpen] = useState(false);
  const [tab3RecuitmentDetailOpen, setTab3RecuitmentDetailOpen] = useState(false);
  const [applyListOpen, setApplyListOpen] = useState(false);
  const [tab1DeclarationOpen, setTab1DeclarationOpen] = useState(false);
  const [tab3DeclarationOpen, setTab3DeclarationOpen] = useState(false);
  // tab1
  const [tab1SearchEquitmentNm, setTab1SearchEquitmentNm] = useState('');
  const [tab1SearchWorkAddres, setTab1SearchWorkAddres] = useState('');
  const [tab1SearchStartMonth, setTab1SearchStartMonth] = useState(`${year}-${nowMonth}-01`);
  const [tab1SearchSttus, setTab1SearchSttus] = useState('I');
  const [tab1Page, setTab1Page] = React.useState(100);
  // tab1 상세보기 parameter
  const [tab1WorkNo, setTab1WorkNo] = useState();
  const [tab1EquitmentNm, setTab1EquitmentNm] = useState();
  const [tab1WorkAddres, setTab1WorkAddres] = useState();
  const [tab1WorkCn, setTab1WorkCn] = useState();
  const [tab1WorkAmount, setTab1WorkAmount] = useState();
  const [tab1PymntMthd, setTab1PymntMthd] = useState();
  const [tab1WorkBegin, setTab1WorkBegin] = useState();
  const [tab1WorkEndde, setTab1WorkEndde] = useState();
  const [tab1WorkSttus, setTab1WorkSttus] = useState();
  const [tab1WorkMemo, setTab1WorkMemo] = useState();
  const [tab1WorkType, setTab1WorkType] = useState();
  const [tab1BeginTime, setTab1BeginTime] = useState();
  const [tab1EndTime, setTab1EndTime] = useState();
  const [tab1RegUser, setTab1RegUser] = useState();
  const [tab1EmployeeCnt, setTab1EmployeeCnt] = useState();
  const [tab1ApplySttus, setTab1ApplySttus] = useState();
  const [tab1MbtlNum, setTab1MbtlNum] = useState();
  const [tab1WorkOrnt, setTab1WorkOrnt] = useState();
  const [tab1WorkTimeShow, setTab1WorkTimeShow] = useState(false);
  const [tab1MbtlNumShow, setTab1MbtlNumShow] = useState(false);
  // const [tab1Declaration, setTab1Declaration] = useState();
  const [tab1DeclarationCn, setTab1DeclarationCn] = useState();
  const [tab1DeclarationResn, setTab1DeclarationResn] = useState('스팸홍보 · 도배글');

  // tab2
  const [tab2SearchEquitmentNm, setTab2SearchEquitmentNm] = useState('');
  const [tab2SearchWorkAddres, setTab2SearchWorkAddres] = useState('');
  const [tab2SearchStartMonth, setTab2SearchStartMonth] = useState(null);
  const [tab2SearchSttus, setTab2SearchSttus] = useState('ALL');
  const [tab2Page, setTab2Page] = React.useState(100);
  // tab2 상세내용 parameter
  const [tab2WorkNo, setTab2WorkNo] = useState();
  const [tab2EquitmentNm, setTab2EquitmentNm] = useState();
  const [tab2WorkAddres, setTab2WorkAddres] = useState();
  const [tab2WorkCn, setTab2WorkCn] = useState();
  const [tab2WorkAmount, setTab2WorkAmount] = useState();
  const [tab2PymntMthd, setTab2PymntMthd] = useState();
  const [tab2WorkBegin, setTab2WorkBegin] = useState();
  const [tab2WorkEndde, setTab2WorkEndde] = useState();
  const [tab2WorkSttus, setTab2WorkSttus] = useState();
  const [tab2WorkSttusChanged, setTab2WorkSttusChanged] = useState();
  const [tab2WorkMemo, setTab2WorkMemo] = useState();
  const [tab2WorkType, setTab2WorkType] = useState();
  const [tab2BeginTime, setTab2BeginTime] = useState();
  const [tab2EndTime, setTab2EndTime] = useState();
  const [tab2RegUser, setTab2RegUser] = useState();
  const [tab2EmployeeCnt, setTab2EmployeeCnt] = useState(); // 승인가능인원수
  const [tab2ApplyCnt, setTab2applyCnt] = useState(); // 지원자수
  const [tab2WorkTimeShow, setTab2WorkTimeShow] = useState(false);
  const [cancelReasonShow, setCancelReasonShow] = useState(false);
  const [cancelReasonDeatialShow, setCancelReasonDetailShow] = useState(false);
  const [tab2ReRegButtonShow, setTab2ReRegButtonShow] = useState(false);
  const [tab2UptButtonShow, setTab2UptButtonShow] = useState(false);
  // const [tab2UptButtonShow2, setTab2UptButtonShow2] = useState(false);
  const [tab2CancelReason, setTab2CancelReason] = useState();
  const [tab2CancelReasonDetail, setTab2CancelReasonDetail] = useState();
  const [tab2WorkOrnt, setTab2WorkOrnt] = useState();
  const [tab2CheckNormal, setTab2CheckNormal] = useState(false);
  const [tab2CheckMustRead, setTab2CheckMustRead] = useState(false);
  const [tab2CheckEmergency, setTab2CheckEmergency] = useState(false);
  // 재등록시 날짜 변경
  const [tab2WorkTypeChanged, setTab2WorkTypeChanged] = useState();
  const [tab2WorkBeginChanged, setTab2WorkBeginChanged] = useState();
  const [tab2WorkEnddeChanged, setTab2WorkEnddeChanged] = useState();
  const [tab2BeginTimeChanged, setTab2BeginTimeChanged] = useState();
  const [tab2EndTimeChanged, setTab2EndTimeChanged] = useState();
  const [tab2WorkMemoChanged, setTab2WorkMemoChanged] = useState();

  // tab3
  const [tab3SearchEquitmentNm, setTab3SearchEquitmentNm] = useState('');
  const [tab3SearchWorkAddres, setTab3SearchWorkAddres] = useState('');
  const [tab3SearchStartMonth, setTab3SearchStartMonth] = useState(null);
  const [tab3SearchSttus, setTab3SearchSttus] = useState('ALL');
  const [tab3Page, setTab3Page] = React.useState(100);

  const [tab3ApplyButtonShow, setTab3ApplyButtonShow] = useState(false);
  const [tab3Declaration, setTab3Declaration] = useState(1);

  // 등록 Parameter
  // const [workName, setWorkName] = useState(); // 작업명
  const [equitment, setEquitment] = useState(); // 장비명
  // const [place, setPlace] = useState(); // 작업장소
  const [address, setAddress] = useState(); // 작업주소
  // const [area, setArea] = useState(); // 작업지역
  const [workStartDate, setWorkStartDate] = useState(`${year}-${nowMonth}-${nowday}`); // 작업시작일
  const [workEndtDate, setWorkEndDate] = useState(); // 작업종료일
  const [workContents, setWorkContents] = useState(); // 작업내용
  const [workPrice, setWorkPrice] = useState(); // 작업금액
  const [howtoPay, setHowtoPay] = useState(); // 지불방법
  const [memo, setMemo] = useState();
  const [memoShow, setMemoShow] = useState();
  const [employeeCnt, setEmployeeCnt] = useState(1);
  const [workType, setWorkType] = useState('3');
  const [workBeginTime, setWorkBeginTime] = useState('08:00:00'); // 작업시작시간
  const [workEndTime, setWorkEndTime] = useState('17:00:00'); // 작업종료시간
  const [status, setStatus] = useState('I');
  const [workTimeShow, setWorkTimeShow] = useState(false);
  const [checkNormal, setCheckNormal] = useState(true);
  const [checkMustRead, setCheckMustRead] = useState(false);
  const [checkEmergency, setCheckEmergency] = useState(false);

  const refWorkStartDate = useRef();
  const refWorkEndDate = useRef();
  const refTab2WorkStartDate = useRef();
  const refTab2WorkEndDate = useRef();
  // -------------------------------------------------------------------------------------------------
  const makeDate = (params) =>
    `${params.workBegin} ${params.beginTime.substr(0, 2)}시 ~ ${
      params.workEndde
    } ${params.enddeTime.substr(0, 2)}시`;
  // 지원자 DATAGRID
  const APPLY_TABLE_HEAD = [
    {
      field: 'id',
      headerName: '번호',
      align: 'center',
      width: 10,
      hide: true,
      valueGetter: (params) => params.row.workNo
    },
    { field: 'ncm', headerName: '이름', alignRight: false, width: 150 },
    {
      field: 'applySttus',
      headerName: '상태',
      alignRight: false,
      width: 135,
      renderCell: (params) => (
        <ThemeProvider theme={themeTypo}>
          <Typography variant={handleButtonColor(params.row)}>
            <strong>{ApplyValueSet(params)}</strong>
          </Typography>
        </ThemeProvider>
      )
    },
    {
      field: 'applyDt',
      headerName: '지원일',
      type: 'date',
      alignRight: false,
      width: 135,
      valueGetter: (params) => params.row.applyDt.substr(0, 11)
    },
    { field: 'mbtlnum', headerName: '전화번호', alignRight: false, width: 120 }
  ];
  const NEW_TABLE_HEAD = [
    {
      field: 'Button',
      headerName: '지원현황',
      align: 'center',
      width: 90,
      // hide: true
      renderCell: (params) => (
        // <ThemeProvider theme={theme}></ThemeProvider>
        <div>
          <ThemeProvider theme={theme}>
            <Button variant="outlined" size="small" onClick={tab1ClickDetailDialogOpen}>
              <strong>신청</strong>
            </Button>
          </ThemeProvider>
          <Dialog
            open={tab1RecuitmentDetailOpen}
            onClose={tab1ClickDetailDialogClose}
            position="absolute"
            PaperProps={{ sx: { width: '70%', height: '60%' } }}
          >
            <DialogTitle align="center">공고내용</DialogTitle>
            <DialogContent>
              <Stack direction="column" justifyContent="space-between" spacing={3}>
                <Stack direction="row" justifyContent="flex-end" spacing={0.5}>
                  <Button
                    variant="contained"
                    onClick={handleRequestApplyApi}
                    disabled={tab1RegUser === sessionStorage.getItem('id')}
                  >
                    신청
                  </Button>
                  <Button variant="contained" onClick={tab1ClickDetailDialogClose}>
                    닫기
                  </Button>
                </Stack>
                <Stack direction="row" justifyContent="flex-end" spacing={0.5}>
                  <TextField
                    fullWidth
                    label="장비명"
                    type="text"
                    variant="standard"
                    value={tab1EquitmentNm}
                    InputProps={{
                      readOnly: true
                    }}
                  />
                  <TextField
                    fullWidth
                    label="작업내용"
                    type="text"
                    variant="standard"
                    value={tab1WorkCn || ''}
                    InputProps={{
                      readOnly: true
                    }}
                  />
                </Stack>
                <TextField
                  label="작업주소"
                  type="text"
                  variant="standard"
                  value={tab1WorkAddres || ''}
                  // onChange={onChangeTab1Address}
                  InputProps={{
                    readOnly: true
                  }}
                />
                <Stack direction="row" justifyContent="space-between" spacing={0.5}>
                  <TextField
                    fullWidth
                    label="작업금액"
                    type="text"
                    variant="standard"
                    value={tab1WorkAmount || ''}
                    // onChange={onChangeTab1WorkPrice}
                    InputProps={{
                      readOnly: true
                    }}
                  />
                  <TextField
                    fullWidth
                    label="지불방법"
                    type="text"
                    variant="standard"
                    value={tab1PymntMthd || ''}
                    // onChange={onChangeTab1HowtoPay}
                    InputProps={{
                      readOnly: true
                    }}
                  />
                </Stack>
                <Stack direction="row" justifyContent="space-between" spacing={0.5}>
                  <TextField
                    fullWidth
                    type="date"
                    variant="standard"
                    value={tab1WorkBegin || ''}
                    helpertext="작업시작일"
                    // onChange={onChangeTab1WorkStartDate}
                    InputProps={{
                      readOnly: true
                    }}
                  />
                  <TextField
                    fullWidth
                    type="date"
                    variant="standard"
                    value={tab1WorkEndde || ''}
                    helpertext="작업종료일"
                    // onChange={onChangeTab1WorkEndDate}
                    InputProps={{
                      readOnly: true
                    }}
                  />
                </Stack>
                <FormControl>
                  <FormLabel>작업시간</FormLabel>
                  <RadioGroup row>
                    <FormControlLabel
                      size="small"
                      checked={tab1WorkType === '1'}
                      // onChange={onChangeTab1WorkType}
                      value="1"
                      control={<Radio size="small" />}
                      label="오전(08:00~12:00)"
                      InputProps={{
                        readOnly: true
                      }}
                    />
                    <FormControlLabel
                      checked={tab1WorkType === '2'}
                      // onChange={onChangeTab1WorkType}
                      value="2"
                      control={<Radio size="small" />}
                      label="오후(13:00~17:00)"
                      InputProps={{
                        readOnly: true
                      }}
                    />
                    <FormControlLabel
                      checked={tab1WorkType === '3'}
                      // onChange={onChangeTab1WorkType}
                      value="3"
                      control={<Radio size="small" />}
                      label="종일(08:00~17:00)"
                      InputProps={{
                        readOnly: true
                      }}
                    />
                    <FormControlLabel
                      checked={tab1WorkType === '4'}
                      // onChange={onChangeTab1WorkType}
                      value="4"
                      control={<Radio size="small" />}
                      label="사용자지정"
                      InputProps={{
                        readOnly: true
                      }}
                    />
                    {tab1WorkTimeShow && (
                      <Stack direction="row" justifyContent="space-between" spacing={1}>
                        <TextField
                          fullWidth
                          type="time"
                          variant="standard"
                          value={tab1BeginTime}
                          helpertext="작업시작시간"
                          // onChange={onChangeTab1BeginTime}
                          // ref={refWorkStartDate}
                        />
                        <TextField
                          fullWidth
                          type="time"
                          variant="standard"
                          value={tab1EndTime || ''}
                          helpertext="작업종료시간"
                          // onChange={onChangeTab1EndTime}
                          // ref={refWorkEndDate}
                        />
                      </Stack>
                    )}
                  </RadioGroup>
                </FormControl>
                <TextField
                  label="메모"
                  type="text"
                  variant="standard"
                  value={tab1WorkMemo || ''}
                  // onChange={onChangeTab1Memo}
                  InputProps={{
                    readOnly: true
                  }}
                />
                <br />
              </Stack>
            </DialogContent>
          </Dialog>
        </div>
      )
    },
    { field: 'eqpmnNm', headerName: '장비명', alignRight: false, width: 150 },
    { field: 'workAdres', headerName: '작업지역', type: 'date', alignRight: false, width: 180 },
    {
      field: 'workBegin',
      headerName: '작업일',
      alignRight: false,
      width: 210,
      valueGetter: (params) => (params !== null || params !== undefined ? makeDate(params.row) : '')
    },
    // { field: 'workEndde', headerName: '작업종료일', alignRight: false },
    { field: 'workCn', headerName: '작업내용', alignRight: false, width: 220 },
    {
      field: 'workMemo',
      headerName: '메모',
      alignRight: false,
      width: 220,
      renderCell: (params) => (
        <ThemeProvider theme={themeCheck}>
          <Typography variant={handleWorkMemoColor(params.row)}>
            {handleWorkMemoStr1(params)}
          </Typography>
          {handleWorkMemoStr2(params)}
        </ThemeProvider>
      )
    },
    { field: 'pymntMthd', headerName: '지불방식', alignRight: false, width: 100 },
    {
      field: 'workAmount',
      headerName: '단가(금액)',
      type: 'number',
      alignRight: false,
      width: 100
    },
    {
      field: 'workSttus',
      headerName: '진행상태',
      alignRight: false,
      width: 80,
      // valueGetter: (params) => noticeSttus(params),
      renderCell: (params) => (
        <ThemeProvider theme={themeTypo}>
          <Typography variant={handleWorkSttusColor(params.row)}>
            <strong>{noticeSttus(params)}</strong>
          </Typography>
        </ThemeProvider>
      )
    },
    {
      field: 'workType',
      headerName: 'workNo',
      align: 'center',
      hide: true
    },
    { field: 'workOrnt', headerName: '등록자', alignRight: false, width: 100 },
    {
      field: 'regDate',
      headerName: '등록일',
      align: 'center',
      type: 'date',
      width: 150,
      valueGetter: (params) => params.row.regDate.substr(0, 16)
      // valueGetter: (params) => (params.row.regDate)
    },
    { field: 'regUser', headerName: 'regUser', align: 'center', hide: true },
    { field: 'workNo', headerName: 'workNo', align: 'center', hide: true }
  ];
  // ------------------------------------------------------------------------------------------------------------
  const MYWORK_TABLE_HEAD = [
    {
      field: 'Button',
      headerName: '지원자현황',
      align: 'center',
      width: 90,
      hide: true
    },
    {
      field: 'workSttus',
      headerName: '진행상태',
      alignRight: false,
      width: 110,
      // valueGetter: (params) => noticeSttus(params)
      renderCell: (params) => (
        <ThemeProvider theme={themeTypo}>
          <Typography variant={handleWorkSttusColor(params.row)}>
            <strong>{noticeSttus(params)}</strong>
          </Typography>
        </ThemeProvider>
      )
    },
    { field: 'eqpmnNm', headerName: '장비명', alignRight: false, width: 150 },
    { field: 'workAdres', headerName: '작업지역', alignRight: false, width: 180 },
    {
      field: 'workBegin',
      headerName: '작업일',
      type: 'date',
      alignRight: false,
      width: 210,
      valueGetter: (params) => (params !== null || params !== undefined ? makeDate(params.row) : '')
    },
    // { field: 'workEndde', headerName: '작업종료일', type: 'date', alignRight: false },
    { field: 'workCn', headerName: '작업내용', alignRight: false, width: 220 },
    {
      field: 'workMemo',
      headerName: '메모',
      width: 220,
      renderCell: (params) => (
        <ThemeProvider theme={themeCheck}>
          <Typography variant={handleWorkMemoColor(params.row)}>
            {handleWorkMemoStr1(params)}
          </Typography>
          {handleWorkMemoStr2(params)}
        </ThemeProvider>
      )
    },
    { field: 'pymntMthd', headerName: '지불방식', alignRight: false, width: 110 },
    {
      field: 'workAmount',
      headerName: '단가(금액)',
      type: 'number',
      alignRight: false,
      width: 110
    },
    // { field: 'workTotamt', headerName: '지원현황', type: 'number', alignRight: false },
    { field: 'workOrnt', headerName: '등록자', alignRight: false, width: 100 },
    // { field: 'regUser', headerName: '등록자', alignRight: false, width: 70, hide: true },
    {
      field: 'regDate',
      headerName: '등록일',
      align: 'center',
      width: 150,
      valueGetter: (params) => params.row.regDate.substr(0, 16)
    },
    { field: 'workNo', headerName: 'workNo', alignRight: false, hide: true }
  ];
  // ------------------------------------------------------------------------------------------------------------
  const MYREG_TABLE_HEAD = [
    {
      field: 'Button',
      headerName: '지원현황',
      align: 'center',
      width: 90,
      // hide: true
      renderCell: (params) => (
        <ThemeProvider theme={themeTypo}>
          <Typography variant={handleButtonColor(params.row)}>
            <strong>{ApplyValueSet(params)}</strong>
          </Typography>
        </ThemeProvider>
      )
    },
    {
      field: 'workSttus',
      headerName: '진행상태',
      type: 'number',
      alignRight: false,
      width: 80,
      // valueGetter: (params) => noticeSttus(params)
      renderCell: (params) => (
        <ThemeProvider theme={themeTypo}>
          <Typography variant={handleWorkSttusColor(params.row)}>
            <strong>{noticeSttus(params)}</strong>
          </Typography>
        </ThemeProvider>
      )
    },
    { field: 'eqpmnNm', headerName: '장비명', alignRight: false, width: 150 },
    { field: 'workAdres', headerName: '작업지역', alignRight: false, width: 180 },
    {
      field: 'workBegin',
      headerName: '작업일',
      type: 'date',
      alignRight: false,
      width: 210,
      valueGetter: (params) => (params !== null || params !== undefined ? makeDate(params.row) : '')
    },
    // { field: 'workEndde', headerName: '작업종료일', type: 'date', alignRight: false },
    { field: 'workCn', headerName: '작업내용', alignRight: false, width: 220 },
    {
      field: 'workMemo',
      headerName: '메모',
      width: 220,
      renderCell: (params) => (
        <ThemeProvider theme={themeCheck}>
          <Typography variant={handleWorkMemoColor(params.row)}>
            {handleWorkMemoStr1(params)}
          </Typography>
          {handleWorkMemoStr2(params)}
        </ThemeProvider>
      )
    },
    { field: 'pymntMthd', headerName: '지불방식', align: 'center', width: 100 },
    {
      field: 'workAmount',
      headerName: '단가(금액)',
      type: 'number',
      alignRight: false,
      width: 100
    },
    // { field: 'workTotamt', headerName: '지원현황', type: 'number', alignRight: false },
    { field: 'workOrnt', headerName: '등록자', align: 'center', width: 100 },
    { field: 'regUser', headerName: '등록자', align: 'center', width: 70, hide: true },
    {
      field: 'regDate',
      headerName: '등록일',
      align: 'center',
      width: 150,
      valueGetter: (params) => params.row.regDate.substr(0, 16)
    },
    { field: 'workNo', headerName: 'workNo', align: 'center', hide: true }
  ];
  // -----------------------------------------------API 호출-----------------------------------------------------
  // 목록 API 호출
  const WorkListGetData = async () => {
    const apiUrl = `${BASE_URL}/workpblanc/getWorkList`;
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    };
    const sendParams = {
      eqpmnNm: tab1SearchEquitmentNm,
      workAdres: tab1SearchWorkAddres
      // workBegin: tab1SearchStartMonth,
      // workSttus: tab1SearchSttus
    };
    axios
      // .post(apiUrl, sendParams, headers)
      .post(apiUrl, sendParams, headers)
      .then((response) => {
        console.log(response.data);
        setMyWorkList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // 내가 등록한 공고 목록
  const myWorkListGetData = async () => {
    const apiUrl = `${BASE_URL}/manager/workpblanc/getMyWorkListApp`;
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    };
    const sendParams = {
      regUser: `${sessionStorage.getItem('id')}`,
      eqpmnNm: tab2SearchEquitmentNm,
      workAdres: tab2SearchWorkAddres,
      // workBegin: tab2SearchStartMonth,
      regDate: tab2SearchStartMonth,
      workSttus: tab2SearchSttus
    };
    axios
      // .post(apiUrl, sendParams, headers)
      .post(apiUrl, sendParams, headers)
      .then((response) => {
        setMyRegWorkList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // 내가 신청한 공고 목록
  const myReqListGetData = async () => {
    const apiUrl = `${BASE_URL}/workpblanc/getMyWorkApplyListApp`;
    // const apiUrl = 'https://apidev.yeonisoft.com/workpblanc/getMyWorkApplyListApp';
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    };
    const sendParams = {
      regUser: `${sessionStorage.getItem('id')}`,
      eqpmnNm: tab3SearchEquitmentNm,
      workAdres: tab3SearchWorkAddres,
      // workBegin: tab3SearchStartMonth,
      regDate: tab3SearchStartMonth,
      applySttus: tab3SearchSttus
    };
    axios
      // .post(apiUrl, sendParams, headers)
      .post(apiUrl, sendParams, headers)
      .then((response) => {
        setMyReqWorkList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // 등록 API 호출
  const getSave = async () => {
    const apiUrl = `${BASE_URL}/workpblanc/regWorkpblanc`;
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    };
    const sendParams = {
      // workNm: workName,
      eqpmnNm: equitment,
      // workPlace: place,
      workAdres: address,
      // workArea: area,
      workBegin: workStartDate,
      workEndde: workEndtDate,
      workCn: workContents,
      workAmount: workPrice.replaceAll(',', ''),
      pymntMthd: howtoPay,
      workMemo: handleCheckData(),
      beginTime: workBeginTime,
      enddeTime: workEndTime,
      workSttus: status,
      workNmprCnt: employeeCnt,
      delYn: 'N'
    };
    axios
      // .post(apiUrl, sendParams, headers)
      .post(apiUrl, sendParams, headers)
      .then((response) => {
        setMyWorkList(response.data);
        if (response.data.code === '200') {
          window.alert('저장되었습니다');
          WorkListGetData();
          myWorkListGetData();
          handleRegDialogClose();
        } else if (response.data.cose === '500') {
          window.alert('등록에 실패하였습니다');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // 공고수정
  const handleUpdateApi = async () => {
    // console.log(tab2BeginTime);
    // console.log(tab2EndTime);
    const apiUrl = `${BASE_URL}/workpblanc/updWorkpblanc`;
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    };
    const sendParams = {
      // workNm: workName,
      workNo: tab2WorkNo,
      eqpmnNm: tab2EquitmentNm,
      // workPlace: place,
      workAdres: tab2WorkAddres,
      // workArea: area,
      workBegin: tab2WorkBegin,
      workEndde: tab2WorkEndde,
      workCn: tab2WorkCn,
      workAmount: tab2WorkAmount.replaceAll(',', ''),
      pymntMthd: tab2PymntMthd,
      workMemo: handleModiCheckData(),
      beginTime: tab2BeginTime,
      enddeTime: tab2EndTime,
      workSttus: tab2WorkSttus,
      workNmprCnt: tab2EmployeeCnt,
      delYn: 'N'
    };
    axios
      // .post(apiUrl, sendParams, headers)
      .post(apiUrl, sendParams, headers)
      .then((response) => {
        setMyWorkList(response.data);
        if (response.data.code === '200') {
          window.alert('수정되었습니다');
          setTab2WorkNo(); // 수정 후 버튼클릭시 수정 전 정보 삭제
          WorkListGetData();
          myWorkListGetData();
          tab2ClickDetailDialogClose();
        } else if (response.data.cose === '500') {
          window.alert('수정에 실패하였습니다');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // 강제마감 API
  const handleCompulsionApi = async () => {
    const apiUrl = `${BASE_URL}/workpblanc/updWorkpblanc`;
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    };
    const sendParams = {
      // workNm: workName,
      workNo: tab2WorkNo,
      eqpmnNm: tab2EquitmentNm,
      // workPlace: place,
      workAdres: tab2WorkAddres,
      // workArea: area,
      workBegin: tab2WorkBegin,
      workEndde: tab2WorkEndde,
      workCn: tab2WorkCn,
      workAmount: tab2WorkAmount.replaceAll(',', ''),
      pymntMthd: tab2PymntMthd,
      workMemo: tab2WorkMemo,
      beginTime: tab2BeginTime,
      enddeTime: tab2EndTime,
      workSttus: 'E',
      workNmprCnt: tab2EmployeeCnt,
      delYn: 'N'
    };
    axios
      // .post(apiUrl, sendParams, headers)
      .post(apiUrl, sendParams, headers)
      .then((response) => {
        setMyWorkList(response.data);
        if (response.data.code === '200') {
          window.alert('마감처리 되었습니다');
          setTab2WorkNo(); // 수정 후 버튼클릭시 수정 전 정보 삭제
          WorkListGetData();
          myWorkListGetData();
          // tab2ClickDetailDialogClose();
        } else if (response.data.cose === '500') {
          window.alert('수정에 실패하였습니다');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // 공고 작업취소 API
  const handleDeleteApi = async () => {
    const apiUrl = `${BASE_URL}/workpblanc/updWorkpblanc`;
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    };
    const sendParams = {
      // workNm: workName,
      workNo: tab2WorkNo,
      workSttus: 'C',
      canclResn: tab2CancelReasonDetail,
      workMemo: tab2WorkMemo
    };
    axios
      // .post(apiUrl, sendParams, headers)
      .post(apiUrl, sendParams, headers)
      .then((response) => {
        setMyWorkList(response.data);
        if (response.data.code === '200') {
          window.alert('취소되었습니다');
          // WorkListGetData();
          setTab2WorkNo(); // 수정 후 버튼클릭시 수정 전 정보 삭제
          myWorkListGetData();
          tab2ClickDetailDialogClose();
        } else if (response.data.cose === '500') {
          window.alert('실패하였습니다 관리자에 문의하세요');
        }
      })
      .then((response) => {
        WorkListGetData();
        myWorkListGetData();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // 지원자 목록 API
  const handleApplyListApi = async (value) => {
    const apiUrl = `${BASE_URL}/manager/workpblanc/getWorkapplyList`;
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    };
    const sendParams = {
      workNo: value
    };
    axios
      // .post(apiUrl, sendParams, headers)
      .post(apiUrl, sendParams, headers)
      .then((response) => {
        // console.log(response.data);
        setApplyList(response.data);
        // setCheckApplyCnt(handleCheckApplyCnt());
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // 지원신청 API
  const handleRequestApplyApi = async () => {
    const apiUrl = `${BASE_URL}/workpblanc/regWorkapply`;
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    };
    const sendParams = {
      workNo: tab1WorkNo,
      id: sessionStorage.getItem('id'),
      applySttus: 'A'
    };
    axios
      // .post(apiUrl, sendParams, headers)
      .post(apiUrl, sendParams, headers)
      .then((response) => {
        if (response.data.code === '200') {
          console.log(response.data.code);
          window.alert('신청되었습니다');
          tab1ClickDetailDialogClose();
          WorkListGetData();
          myReqListGetData();
        } else if (response.data.code === '500') {
          window.alert(response.data.desc);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // 지원신청 멀티 API (6.30 -> 멀티신청)
  const handleMultiRequestApplyApi = async () => {
    const apiUrl = `${BASE_URL}/workpblanc/regWorkapplyMulti`;
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    };
    const sendParams = makeRequestApplyData(selectedRows);
    // workNo: tab1WorkNo,
    // id: sessionStorage.getItem('id'),
    // applySttus: 'A'

    axios
      // .post(apiUrl, sendParams, headers)
      .post(apiUrl, sendParams, headers)
      .then((response) => {
        console.log(response.data);
        const result = response.data;
        result.map((r, idx) => {
          if (r.code === '200') {
            console.log(r.code);
            window.alert('신청되었습니다');
            setSelectedRows();
            tab1ClickDetailDialogClose();
            WorkListGetData();
            myReqListGetData();
          } else if (r.code === '500') {
            window.alert(r.desc);
          }
          return r.code;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // 지원자 지원신청취소 API
  const handleApplyCancelApi = async () => {
    const apiUrl = `${BASE_URL}/workpblanc/updWorkapply`;
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    };
    const sendParams = {
      workNo: tab1WorkNo,
      // id: sessionStorage.getItem('id')
      applySttus: 'UC'
    };
    axios
      // .post(apiUrl, sendParams, headers)
      .post(apiUrl, sendParams, headers)
      .then((response) => {
        if (response.data.code === '200') {
          tab1ClickDetailDialogClose();
          myReqListGetData();
          // setSendApplyNo('');
          window.alert('지원이 취소 되었습니다');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // 관리자 승인 API
  const handleApplyConfirmApi = async () => {
    const cnt = handleCheckApplyCnt();
    // const apiUrl = 'https://apidev.yeonisoft.com/workpblanc/updWorkapply';
    const apiUrl = `${BASE_URL}/workpblanc/updWorkapply`;
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    };
    const sendParams = {
      id: sendApplyId,
      workNo: sendApplyNo,
      applySttus: 'S',
      workBegin: tab2WorkBegin,
      workEndde: tab2WorkEndde
    };
    axios
      // .post(apiUrl, sendParams, headers)
      .post(apiUrl, sendParams, headers)
      .then((response) => {
        // setApplyList(response.data);
        console.log(response.data);
        if (response.data.code === '200') {
          window.alert('승인 되었습니다');
          if (tab2EmployeeCnt - cnt === 1) {
            if (window.confirm('작업가능인원이 모두 승인되었습니다. 공고를 마감하시겠습니까?')) {
              handleCompulsionApi();
            }
          }
          handleApplyListApi(tab2WorkNo);
          // myReqListGetData();
        } else if (response.data.code === '500') {
          // window.alert(`${response.data.desc}`);
          window.alert('이미 승인된 일정이 있습니다. 확인해주세요');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // 관리자신청거부 API
  const handleApplyRejectApi = async () => {
    const apiUrl = `${BASE_URL}/workpblanc/updWorkapply`;
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    };
    const sendParams = {
      id: sendApplyId,
      workNo: sendApplyNo,
      applySttus: 'R'
    };
    axios
      // .post(apiUrl, sendParams, headers)
      .post(apiUrl, sendParams, headers)
      .then((response) => {
        // setApplyList(response.data);
        console.log(response.data);
        if (response.data.code === '200') {
          handleApplyListApi(tab2WorkNo);
          // setSendApplyNo('');
          window.alert('미승인 되었습니다');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // 재등록 API 호출
  const reRegNotice = async () => {
    const apiUrl = `${BASE_URL}/workpblanc/regWorkpblanc`;
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    };
    const sendParams = {
      // workNm: workName,
      eqpmnNm: tab2EquitmentNm,
      // workPlace: place,
      workAdres: tab2WorkAddres,
      // workArea: area,
      workBegin: tab2WorkBegin,
      workEndde: tab2WorkEndde,
      workCn: tab2WorkCn,
      workAmount: tab2WorkAmount.replaceAll(',', ''),
      pymntMthd: tab2PymntMthd,
      // workMemo: tab2WorkMemo,
      workMemo: handleModiCheckData(),
      beginTime: tab2BeginTime,
      enddeTime: tab2EndTime,
      workSttus: 'I',
      workNmprCnt: tab2EmployeeCnt
    };
    axios
      // .post(apiUrl, sendParams, headers)
      .post(apiUrl, sendParams, headers)
      .then((response) => {
        setMyWorkList(response.data);
        if (response.data.code === '200') {
          window.alert('재등록 되었습니다');
          // WorkListGetData();
          myWorkListGetData();
          tab2ClickDetailDialogClose();
        } else if (response.data.cose === '500') {
          window.alert('재등록에 실패하였습니다. 관리자에게 문의하세요');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // 신고하기 등록 API 호출
  const regDeclaration = async () => {
    const apiUrl = `${BASE_URL}/notify/regNotify`;
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    };
    const sendParams = {
      notifyCn: tab1DeclarationCn,
      notifyId: sessionStorage.getItem('id'),
      notifyResn: tab1DeclarationResn,
      workNo: tab1WorkNo
    };
    axios
      // .post(apiUrl, sendParams, headers)
      .post(apiUrl, sendParams, headers)
      .then((response) => {
        // setMyWorkList(response.data);
        if (response.data.code === '200') {
          window.alert('정상처리 되었습니다.');
          setTab1DeclarationCn();
          handleTab1DeclarationClose();
          WorkListGetData();
        } else if (response.data.cose === '500') {
          window.alert('실패하였습니다. 관리자에게 문의하세요');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // -----------------------------------------------API 호출-----------------------------------------------------
  // -----------------------------------------------useEffect Tab1-----------------------------------------------
  useEffect(() => {
    // 토큰 값이 없을 경우 Login Page로 이동
    if (sessionStorage.getItem('token') === undefined || sessionStorage.getItem('token') === null) {
      navigate('/login');
    }
    WorkListGetData();
    // 3초에 한번씩 조회 시작

    // const timer = setInterval(() => {
    //   WorkListGetData();
    // }, 10000);

    // return () => clearInterval(timer);
  }, [tab1SearchEquitmentNm, tab1SearchWorkAddres, tab1SearchStartMonth, tab1SearchSttus]);
  // ----------------------------------------------------Tab2----------------------------------------------------
  useEffect(() => {
    if (sessionStorage.getItem('token') === undefined || sessionStorage.getItem('token') === null) {
      navigate('/login');
    }
    myWorkListGetData();
    // 3초에 한번씩 조회 시작

    // const timer = setInterval(() => {
    //   myWorkListGetData();
    // }, 10000);
    // return () => clearInterval(timer);
  }, [tab2SearchEquitmentNm, tab2SearchWorkAddres, tab2SearchStartMonth, tab2SearchSttus]);
  // ----------------------------------------------------Tab3----------------------------------------------------
  useEffect(() => {
    if (sessionStorage.getItem('token') === undefined || sessionStorage.getItem('token') === null) {
      navigate('/login');
    }
    myReqListGetData();
    // 3초에 한번씩 조회 시작

    // const timer = setInterval(() => {
    //   myReqListGetData();
    // }, 10000);
    // return () => clearInterval(timer);
  }, [tab3SearchEquitmentNm, tab3SearchWorkAddres, tab3SearchStartMonth, tab3SearchSttus]);
  // -----------------------------------------------------지원자--------------------------------------------------
  useEffect(() => {
    // const timer = setInterval(() => {
    //   console.log(tab2WorkNo);
    //   handleApplyListApi(tab2WorkNo);
    // }, 10000);
    // return () => clearInterval(timer);
  }, []);
  // ------------------------------------------------------------------------------------------------------------
  const noticeSttus = (params) => {
    if (params.row.workSttus === 'I') {
      return '모집중';
    }
    if (params.row.workSttus === 'E') {
      return '마감';
    }
    if (params.row.workSttus === 'C') {
      return '작업취소';
    }
  };
  // ------------------------------------------------------------------------------------------------------------
  // params.row.applySttus === 'A' : '지원'
  // params.row.applySttus === 'S' : '승인'
  // params.row.applySttus === 'C' : '지원취소'
  // params.row.applySttus === 'C' : '거부'
  const ApplyValueSet = (params) => {
    if (params.row.applySttus === 'A') {
      return '지원중';
    }
    if (params.row.applySttus === 'S') {
      return '승인';
    }
    if (params.row.applySttus === 'UC') {
      return '지원취소';
    }
    if (params.row.applySttus === 'R') {
      return '미승인';
    }
    if (params.row.applySttus === 'MC') {
      return '작업취소';
    }
  };
  // ------------------------------------------------Button 컬러 변경-------------------------------------------------
  const handleButtonColor = (params) => {
    // console.log(theme.palette.apply);
    // 지원중
    if (params.applySttus === 'A') {
      // setButtonColor(theme.palette.apply);
      return 'body2';
    }
    // 승인
    if (params.applySttus === 'S') {
      // setButtonColor(theme.palette.cancel);
      return 'h5';
    }
    // 지원취소
    if (params.applySttus === 'UC') {
      // setButtonColor(theme.palette.cancel);
      return 'body1';
    }
    // 미승인
    if (params.applySttus === 'R') {
      // setButtonColor(theme.palette.reject);
      return 'h6';
    }
    // 작업취소
    if (params.applySttus === 'MC') {
      // setButtonColor(theme.palette.reject);
      return 'body3';
    }
  };
  // -----------------------------------------------------------------------------------------------------------------
  const handleWorkSttusColor = (params) => {
    // console.log(theme.palette.apply);
    // 모집중
    if (params.workSttus === 'I') {
      // setButtonColor(theme.palette.apply);
      return 'body2';
    }
    // 마감
    if (params.workSttus === 'E') {
      // setButtonColor(theme.palette.cancel);
      return 'h5';
    }
    // 작업취소
    if (params.workSttus === 'C') {
      // setButtonColor(theme.palette.cancel);
      return 'h6';
    }
  };
  // -----------------------------------------------------------------------------------------------------------------
  // 메모 색상 변경
  const handleWorkMemoColor = (params) => {
    if (params.workMemo) {
      // console.log(params.workMemo);
      if (params.workMemo.includes('[긴급]') || params.workMemo.includes('[필독]')) {
        return 'body1';
      }
    }
  };
  const handleWorkMemoStr1 = (params) => {
    let newMemo = '';
    if (params.row.workMemo) {
      // console.log(params.row.workMemo);
      if (params.row.workMemo.includes('[긴급]') || params.row.workMemo.includes('[필독]')) {
        newMemo = params.row.workMemo.substr(0, 4);
      }
    }
    return newMemo;
  };
  const handleWorkMemoStr2 = (params) => {
    let newMemo = '';
    if (params.row.workMemo) {
      // console.log(params.row.workMemo);
      if (params.row.workMemo.includes('[긴급]') || params.row.workMemo.includes('[필독]')) {
        newMemo = params.row.workMemo.substr(4);
      } else {
        newMemo = params.row.workMemo;
      }
    }
    return newMemo;
  };
  // -----------------------------------------------------------------------------------------------------------------
  const makeRequestApplyData = (selectedRows) => {
    // console.log('');
    // console.log(selectedRows);
    // const arr = [];
    // eslint-disable-next-line guard-for-in
    for (const index in selectedRows) {
      const workNos = selectedRows[index].workNo;
      // console.log(workNos);
      // const workJson = { 'workNo': workNos, 'id': sessionStorage.getItem('id'), 'applySttus': 'A' };
      tempData.push({ workNo: workNos, id: sessionStorage.getItem('id'), applySttus: 'A' });
    }
    // const sendData = [
    //   {
    //     workNo: '',
    //     id: sessionStorage.getItem('id'),
    //     applySttus: 'A'
    //   }
    // ];
    // console.log(tempData);
    // setApplyListParams(arr);
    return tempData;
  };
  // -----------------------------------------------------------------------------------------------------------------
  const handleCheckData = () => {
    const memoData = memoShow;
    let newMemo = '';
    if (checkNormal) {
      if (memoData) {
        newMemo = `${memoData}`;
      }
    } else if (checkMustRead) {
      if (memoData) {
        newMemo = `[필독]${memoData}`;
      }
    } else if (checkEmergency) {
      if (memoData) {
        newMemo = `[긴급]${memoData}`;
      }
    }
    return newMemo;
  };
  const handleModiCheckData = () => {
    const memoData = tab2WorkMemo;
    let newMemo = '';
    if (tab2CheckNormal) {
      if (memoData) {
        newMemo = `${memoData}`;
      }
    } else if (tab2CheckMustRead) {
      if (memoData) {
        newMemo = `[필독]${memoData}`;
      }
    } else if (tab2CheckEmergency) {
      if (memoData) {
        newMemo = `[긴급]${memoData}`;
      }
    }
    return newMemo;
  };
  // ------------------------------------------------Event(tab1) 검색-------------------------------------------------
  // 검색 조건 이벤트 ( tab1 )
  const onClickSearchTab1 = () => {
    WorkListGetData();
  };
  const onChangeSearchEquitNm = (e) => {
    // const newValue = { ...tab1SearchEquitmentNm, [e.target.name]: e.target.value };
    // setTab1SearchEquitmentNm(newValue);
    setTab1SearchEquitmentNm(e.target.value);
  };
  const onChangeSearchWorkAddres = (e) => {
    // const newValue = { ...tab1SearchWorkAddres, [e.target.name]: e.target.value };
    // setTab1SearchWorkAddres(newValue);
    setTab1SearchWorkAddres(e.target.value);
  };
  // const onChangeSearchSttus = (e) => {
  //   setTab1SearchSttus(e.target.value);
  //   // WorkListGetData();
  // };
  // const onKeyPressTab1 = (e) => {
  // if (e.key === 'Enter') {
  //   onClickSearchTab1();
  // }
  // };
  // ------------------------------------------------Event(tab2) 검색-------------------------------------------------
  const onClickSearchTab2 = () => {
    myWorkListGetData();
  };
  const onChangeTab2SearchEquitNm = (e) => {
    setTab2SearchEquitmentNm(e.target.value);
  };
  const onChangeTab2SearchWorkAddres = (e) => {
    setTab2SearchWorkAddres(e.target.value);
  };
  const onChangeTab2SearchSttus = (e) => {
    setTab2SearchSttus(e.target.value);
    // myWorkListGetData();
  };
  // const onKeyPressTab2 = (e) => {
  // if (e.key === 'Enter') {
  //   onClickSearchTab2();
  // }
  // };
  // ------------------------------------------------Event(tab3) 검색---------------------------------------------
  const onClickSearchTab3 = () => {
    myReqListGetData();
  };
  const onChangeTab3SearchEquitNm = (e) => {
    setTab3SearchEquitmentNm(e.target.value);
  };
  const onChangeTab3SearchWorkAddres = (e) => {
    setTab3SearchWorkAddres(e.target.value);
  };
  const onChangeTab3SearchSttus = (e) => {
    setTab3SearchSttus(e.target.value);
    // myReqListGetData();
  };
  // ------------------------------------------------Event-------------------------------------------------------
  // 등록 Dialog Open
  const handleRegDialogOpen = () => {
    // console.log(workStartDate);
    setRecuitmentRegOpen(true);
  };
  // 등록 Dialog Close
  const handleRegDialogClose = () => {
    // setWorkName();
    setEquitment();
    // setPlace();
    setAddress();
    // setArea();
    setWorkStartDate(`${year}-${nowMonth}-${nowday}`);
    setWorkEndDate();
    setWorkContents();
    setWorkPrice();
    setHowtoPay();
    setMemo();
    setMemoShow();
    setEmployeeCnt('1');
    setWorkType('3');
    setWorkBeginTime('08:00:00');
    setWorkEndTime('17:00:00');
    setStatus('I');
    setRecuitmentRegOpen(false);
    setWorkTimeShow(false);
    setCheckNormal(true);
    setCheckMustRead(false);
    setCheckEmergency(false);
  };
  // ----------------------------------------tab1----------------------------------------
  // 상세보기 dialog open
  const tab1ClickDetailDialogOpen = () => {
    setTab1RecuitmentDetailOpen(true);
  };
  // 상세보기 dialog close
  const tab1ClickDetailDialogClose = () => {
    setTab1RecuitmentDetailOpen(false);
  };
  // 신고하기 tab1 dialog open
  const handleTab1DeclarationOpen = () => {
    if (!tab1WorkNo) {
      window.alert('신고하실 공고를 선택해주세요');
      return;
    }
    setTab1DeclarationOpen(true);
  };
  // 신고하기 tab1 dialog close
  const handleTab1DeclarationClose = () => {
    setTab1WorkNo();
    setTab1DeclarationCn();
    setTab1DeclarationResn('스팸홍보 · 도배글');
    setTab1DeclarationOpen(false);
  };
  // 신고하기 tab3 dialog open
  const handleTab3DeclarationOpen = () => {
    setTab3DeclarationOpen(true);
  };
  // 신고하기 tab3 dialog close
  const handleTab3DeclarationClose = () => {
    setTab2WorkNo();
    setTab3DeclarationOpen(false);
  };
  // checkbox
  const handleCheckNormal = (e) => {
    setCheckNormal(e.target.checked);
    setCheckMustRead(false);
    setCheckEmergency(false);
  };
  const handleCheckMustRead = (e) => {
    setCheckMustRead(e.target.checked);
    setCheckNormal(false);
    setCheckEmergency(false);
  };
  const handleCheckEmergency = (e) => {
    setCheckEmergency(e.target.checked);
    setCheckNormal(false);
    setCheckMustRead(false);
  };
  // checkbox 수정
  const handleTab2CheckNormal = (e) => {
    setTab2CheckNormal(e.target.checked);
    setTab2CheckMustRead(false);
    setTab2CheckEmergency(false);
  };
  const handleTab2CheckMustRead = (e) => {
    setTab2CheckMustRead(e.target.checked);
    setTab2CheckNormal(false);
    setTab2CheckEmergency(false);
  };
  const handleTab2CheckEmergency = (e) => {
    setTab2CheckEmergency(e.target.checked);
    setTab2CheckNormal(false);
    setTab2CheckMustRead(false);
  };
  // ----------------------------------------tab2----------------------------------------
  // 상세보기 dialog open
  const tab2ClickDetailDialogOpen = () => {
    const cnt = handleCheckApplyCnt();
    if (tab2WorkNo) {
      handleApplyListApi(tab2WorkNo);
      if (cnt === tab2EmployeeCnt && tab2WorkSttus === 'I') {
        if (window.confirm('승인할 수있는 인원이 없습니다. 공고를 마감하시겠습니까?')) {
          handleCompulsionApi();
        }
      }
      setTab2RecuitmentDetailOpen(true);
    } else {
      window.alert('공고를 선택해주세요');
    }
    if (tab2WorkSttus === 'C') {
      if (tab2CancelReasonDetail === '날씨로 인한 작업취소') {
        setTab2CancelReason('reason1');
        // setCancelReasonDetailShow(true);
        setCancelReasonShow(true);
        setCancelReasonDetailShow(false);
      } else if (tab2CancelReasonDetail === '현장사정으로 인한 작업취소') {
        setTab2CancelReason('reason2');
        setCancelReasonShow(true);
        setCancelReasonDetailShow(false);
      } else {
        setTab2CancelReason('reason3');
        setCancelReasonShow(true);
        setCancelReasonDetailShow(true);
      }
    } else {
      setCancelReasonShow(false);
      setCancelReasonDetailShow(false);
    }
    // if (tab2WorkMemo.startWith('[') && tab2WorkMemo.endsWith('[')) {
    //   if (tab2WorkMemo.includes('필독')) {
    //     setTab2CheckNormal(false);
    //     setTab2CheckMustRead(true);
    //     setTab2CheckEmergency(false);
    //   }
    //   if (tab2WorkMemo.includes('긴급')) {
    //     setTab2CheckNormal(false);
    //     setTab2CheckMustRead(false);
    //     setTab2CheckEmergency(true);
    //   }
    // } else {
    //   setTab2CheckNormal(true);
    //   setTab2CheckMustRead(false);
    //   setTab2CheckEmergency(false);
    // }
  };
  // 상세보기 dialog close
  // const tabDetailDialogClose = () => {
  //   if (tab2WorkSttus !== 'C') {
  //     setCancelReasonShow(false);
  //     setCancelReasonDetailShow(false);
  //   }
  //   setTab2RecuitmentDetailOpen(false);
  //   setTab2CancelReasonDetail();
  // }
  // Dialog를 그냥 닫았을때
  const tab2ClickDetailDialogClose = () => {
    const newValue = tab2WorkSttusChanged;
    const newType = tab2WorkTypeChanged;
    const newBegin = tab2WorkBeginChanged;
    const newEnd = tab2WorkEnddeChanged;
    const newBeginTime = tab2BeginTimeChanged;
    const newEndTime = tab2EndTimeChanged;
    const newMemo = tab2WorkMemoChanged;
    if (tab2WorkSttus !== newValue) {
      setTab2WorkSttus(newValue);
      setTab2WorkType(newType);
      setTab2WorkBegin(newBegin);
      setTab2WorkEndde(newEnd);
      setTab2BeginTime(newBeginTime);
      setTab2EndTime(newEndTime);
      setTab2WorkMemo(newMemo);
      // setTab2UptButtonShow2(false);
      setCancelReasonShow(false);
      setCancelReasonDetailShow(false);
    }
    // if (tab2WorkSttus !== 'C') {
    // }
    // setCancelReasonShow(false);
    setTab2RecuitmentDetailOpen(false);
  };
  // ----------------------------------------tab3 Dialog----------------------------------------
  // 상세보기 dialog open
  const tab3ClickDetailDialogOpen = () => {
    if (!tab1WorkNo) {
      window.alert('공고를 선택해주세요');
    } else {
      setTab3RecuitmentDetailOpen(true);
    }
    if (tab1ApplySttus === 'S') {
      setTab1MbtlNumShow(true);
    }
  };
  // 상세보기 dialog close
  const tab3ClickDetailDialogClose = () => {
    setTab3RecuitmentDetailOpen(false);
    setTab1MbtlNumShow(false);
  };
  // ----------------------------------------지원자 Dialog----------------------------------------
  const applyListDialogOpen = () => {
    const cnt = handleCheckApplyCnt();
    if (tab2WorkSttus !== 'C') {
      if (tab2WorkNo) {
        handleApplyListApi(tab2WorkNo);
        if (cnt === tab2EmployeeCnt && tab2WorkSttus === 'I') {
          if (window.confirm('승인할 수있는 인원이 없습니다. 공고를 마감하시겠습니까?')) {
            handleCompulsionApi();
          }
        }
        setApplyListOpen(true);
      } else {
        window.alert('공고를 선택해주세요');
      }
    } else {
      window.alert('취소된 작업의 지원자는 볼수없습니다');
    }
  };
  // 상세보기 dialog close
  const applyListDialogClose = () => {
    setApplyListOpen(false);
    setApplyMbernm();
    setSendApplyNo();
    setSendApplyId();
    // setTab2WorkNo();
  };
  // -------------------------------------------등록시 Validation check------------------------------------------------
  const regValidationCheck = () => {
    const today = `${year}-${nowMonth}-${nowday}`;
    if (!equitment) {
      window.alert('장비명을 입력해주세요!');
      return false;
    }
    if (!address) {
      window.alert('주소를 입력해주세요');
      return false;
    }
    if (!workStartDate) {
      window.alert('작업시작일을 입력해주세요');
      return false;
    }
    if (workStartDate < today) {
      window.alert('작업시작일을 확인해주세요');
      return false;
    }
    if (!workEndtDate) {
      window.alert('작업종료일을 입력해주세요');
      return false;
    }
    if (!workContents) {
      window.alert('작업내용을 입력해주세요');
      return false;
    }
    if (!workPrice) {
      window.alert('작업금액을 입력해주세요');
      return false;
    }
    if (!howtoPay) {
      window.alert('지불방식을 입력해주세요');
      return false;
    }
    if (workStartDate <= workEndtDate === false) {
      window.alert('작업종료일과 시작일을 확인해주세요');
      return false;
    }
    if (workStartDate === workEndtDate) {
      if (workBeginTime <= workEndTime === false) {
        window.alert('작업시작시간과 종료시간을 확인해주세요');
        return false;
      }
    }
    return true;
  };
  // ---------------------------------------------수정시 Validation----------------------------------------------
  const updValidationCheck = () => {
    const today = `${year}-${nowMonth}-${nowday}`;
    if (!tab2EquitmentNm) {
      window.alert('장비명을 입력해주세요!');
      return false;
    }
    if (!tab2WorkAddres) {
      window.alert('주소를 입력해주세요');
      return false;
    }
    if (tab2WorkBegin < today) {
      window.alert('작업시작일을 확인해주세요');
      return false;
    }
    if (!tab2WorkBegin) {
      window.alert('작업시작일을 입력해주세요');
      return false;
    }
    if (!tab2WorkEndde) {
      window.alert('작업종료일을 입력해주세요');
      return false;
    }
    if (!tab2WorkCn) {
      window.alert('작업내용을 입력해주세요');
      return false;
    }
    if (!tab2WorkAmount) {
      window.alert('작업금액을 입력해주세요');
      return false;
    }
    if (!tab2PymntMthd) {
      window.alert('지불방법을 입력해주세요');
      return false;
    }
    // 작업시작일과 종료일 확인
    if (tab2WorkBegin <= tab2WorkEndde === false) {
      window.alert('작업종료일과 시작일을 확인해주세요');
      return false;
    }
    // workType에 따른 시간설정 (Validation 할때 Parameter 저장)
    // 1 = 08:00 12:00
    // 2 = 13:00 17:00
    // 3 = 08:00 17:00
    if (tab2WorkBegin === tab2WorkEndde) {
      if (tab2BeginTime <= tab2EndTime === false) {
        window.alert('작업시작시간과 종료시간을 확인해주세요');
        return false;
      }
    }
    return true;
  };
  // -------------------------------------------------------------------------------------------
  // 등록 저장 APi 호출
  const handleRegSave = () => {
    if (!regValidationCheck()) return;
    if (window.confirm('저장 하시겠습니까?')) {
      // handleCheckData();
      getSave();
      handleRegDialogClose();
    }
  };
  // 내용수정 API 호출
  const handleUpdQuestion = () => {
    if (!updValidationCheck()) return;
    if (tab2WorkSttus === 'I') {
      if (window.confirm('내용을 수정 하시겠습니까?')) {
        handleUpdateApi();
        WorkListGetData();
        myWorkListGetData();
        myReqListGetData();
        tab2ClickDetailDialogClose();
      }
    } else if (tab2WorkSttus === 'E') {
      if (window.confirm('공고를 마감하시겠습니까?')) {
        handleCompulsionApi();
        WorkListGetData();
        myWorkListGetData();
        myReqListGetData();
        tab2ClickDetailDialogClose();
      }
    } else if (tab2WorkSttus === 'C') {
      console.log(tab2CancelReason);
      if (!tab2CancelReason) {
        window.alert('작업취소사유를 선택해주세요');
        return;
      }
      if (tab2CancelReason === 'reason3') {
        if (!tab2CancelReasonDetail) {
          window.alert('작업취소사유를 입력해주세요');
          return;
        }
      }
      if (window.confirm('공고를 취소 하시겠습니까?')) {
        handleDeleteApi();
        tab2ClickDetailDialogClose();
      }
    }
  };
  // 지원취소 tab3
  const handleCancelQuestion = () => {
    if (window.confirm('지원취소 하시겠습니까?')) {
      handleApplyCancelApi();
      tab3ClickDetailDialogClose();
    } else {
      // window.alert();
    }
  };
  // 미승인 tab2
  const handleApplyRejecQuestion = () => {
    // onRowClick을 했을경우만 가능
    if (sendApplyNo && applyMbernm) {
      // console.log(applyMbernm);
      if (window.confirm(`[ ${applyMbernm} ] 지원자를 미승인 하시겠습니까? `)) {
        handleApplyRejectApi(); // 지원자 거부 API
      } else {
        // window.alert('wl');
      }
    } else {
      window.alert('지원자를 선택해주세요');
    }
  };
  // 지원자 승인
  const handleApplyQuestion = () => {
    const cnt = handleCheckApplyCnt();
    // console.log(cnt);
    // console.log(cnt + selectedApplyRows.length);
    // if (tab2EmployeeCnt < cnt + selectedApplyRows.length) {
    //   window.alert('지원자를 선택해주세요');
    // } else {
    // onRowClick을 했을경우만 가능
    if (tab2EmployeeCnt === cnt) {
      window.alert('승인할 수 있는 가능인원이 없습니다');
      return;
    }
    if (sendApplyNo && applyMbernm) {
      // console.log(applyMbernm);
      if (window.confirm(`[ ${applyMbernm} ] 지원자를 승인 하시겠습니까? `)) {
        handleApplyConfirmApi(checkApplyCnt); // 지원자 승인 API
        applyListDialogClose();
      }
    } else {
      window.alert('지원자를 선택해주세요');
    }
    // }
  };
  const handleReRegNotice = () => {
    if (tab2WorkSttus === 'E') {
      window.alert('재등록을 원하시면 "모집중"으로 변경해주세요');
      return;
    }
    if (!updValidationCheck()) return;
    if (window.confirm('공고를 재등록 하시겠습니까?')) {
      reRegNotice();
    }
  };
  const handleDeclaration = () => {
    if (!tab1WorkNo) {
      window.alert('신고하실 공고를 선택해주세요');
      return;
    }
    regDeclaration();
  };
  // 멀티지원신청 - 보류
  // const handleApplyMultiQuestion = () => {
  //   if (window.confirm(`[ ${applyMbernm} ] 지원자를 승인 하시겠습니까? `)) {

  //   }
  // }
  const handleCheckApplyCnt = () => {
    let result = [];
    let checkApplyCnt = 0;
    result = applyList.map((value) => (value.applySttus === 'S' ? checkApplyCnt++ : 0));
    return checkApplyCnt;
  };
  // ------------------------------------------------Event tab1-------------------------------------------------------
  // 작업시작시간
  const onChangeWorkBeginTime = useCallback((e) => {
    // const newValue = { ...tab1SearchEquitmentNm, [e.target.name]: e.target.value };
    // setTab1SearchEquitmentNm(newValue);
    setWorkBeginTime(e.target.value);
  }, []);
  // 작업종료시간
  const onChangeWorkEndTime = useCallback((e) => {
    setWorkEndTime(e.target.value);
  }, []);
  const onChangeEquitment = useCallback((e) => {
    // const newValue = { ...equitment, [e.target.name]: e.target.value };
    // setEquitment(newValue);
    setEquitment(e.target.value);
  }, []);
  const onChangeAddress = useCallback((e) => {
    setAddress(e.target.value);
  }, []);
  const onChangeWorkContents = useCallback((e) => {
    setWorkContents(e.target.value);
  }, []);
  const onChangeWorkPrice = useCallback((e) => {
    e.target.value = e?.target?.value.replaceAll(',', '') || '';
    // onChange(e);
    // setWorkPrice(e.target.value);
  }, []);
  const onChangeStatus = useCallback((e) => {
    setStatus(e.target.value);
  }, []);
  const onChangeWorkStartDate = useCallback((e) => {
    setWorkStartDate(e.target.value);
  }, []);
  const onChangeWorkEndDate = useCallback((e) => {
    setWorkEndDate(e.target.value);
  }, []);
  const onChangeHowtoPay = useCallback((e) => {
    setHowtoPay(e.target.value);
  }, []);
  const onChangeMemo = useCallback((e) => {
    setMemoShow(e.target.value);
  }, []);
  // 지원자수 추가
  const onChangeEmployeeCnt = useCallback((e) => {
    setEmployeeCnt(e.target.value);
  }, []);
  // const onChangeWtime = (e) => {
  //   setWtime(e.target.value);
  // };
  const onChangeWorkType = useCallback((e) => {
    // workType에 따른 시간설정
    // 1 = 08:00 12:00
    // 2 = 13:00 17:00
    // 3 = 08:00 17:00
    if (e.target.value === '1') {
      setWorkType('1');
      setWorkTimeShow(false);
      setWorkBeginTime('08:00:00');
      setWorkEndTime('12:00:00');
    } else if (e.target.value === '2') {
      setWorkType('2');
      setWorkTimeShow(false);
      setWorkBeginTime('13:00:00');
      setWorkEndTime('17:00:00');
    } else if (e.target.value === '3') {
      setWorkType('3');
      setWorkTimeShow(false);
      setWorkBeginTime('08:00:00');
      setWorkEndTime('17:00:00');
    } else if (e.target.value === '4') {
      setWorkTimeShow(true);
      setWorkType('4');
    }
  }, []);
  const onChangeDeclarationResn = (e) => {
    setTab1DeclarationResn(e.target.value);
  };
  const onChangeDeclarationCn = (e) => {
    setTab1DeclarationCn(e.target.value);
  };
  // -------------------------------------------------------------------------------------------------------
  // ---------------------------------------------------수정------------------------------------------------
  const onChangeTab2Equitment = useCallback((e) => {
    setTab2EquitmentNm(e.target.value);
    // debounceFunction(onChangeTab2Equitment, 2000);
  }, []);
  const onChangeTab2Address = useCallback((e) => {
    setTab2WorkAddres(e.target.value);
  }, []);
  const onChangeTab2WorkContents = useCallback((e) => {
    setTab2WorkCn(e.target.value);
  }, []);
  const onChangeTab2WorkPrice = useCallback((e) => {
    setTab2WorkAmount(e.target.value);
  }, []);
  const onChangeTab2Status = (e) => {
    const newValue = tab2WorkSttusChanged;
    const today = `${year}-${nowMonth}-${nowday}`;
    // console.log(e.target.value);
    // console.log(tab2UptButtonShow2);
    // console.log(today);
    // console.log(`${year}-${nowMonth}-${nowday}`);
    if (e.target.value === 'I') {
      setTab2WorkBegin(`${year}-${nowMonth}-${nowday}`);
      setTab2WorkEndde(`${year}-${nowMonth}-${nowday}`);
      setTab2WorkType('3');
      setCancelReasonDetailShow(false);
      if (tab2WorkSttusChanged === 'I') {
        setTab2ReRegButtonShow(false);
      } else {
        setTab2ReRegButtonShow(true);
      }
    } else {
      setTab2ReRegButtonShow(false);
    }
    // 작업취소 사유 selectBox
    if (e.target.value === 'C') {
      if (tab2WorkSttusChanged === 'I') {
        setTab2CancelReason();
        setCancelReasonShow(true);
      } else if (tab2WorkSttusChanged === 'C') {
        // setTab2CancelReason();
        setCancelReasonShow(true);
      }
      // setTab2UptButtonShow(true);
    } else {
      setCancelReasonShow(false);
      // setTab2UptButtonShow(false);
    }
    // 수정버튼 (모집중)
    // 재등록일 경우만
    if ((e.target.value === 'C' || e.target.value === 'E') && tab2WorkSttusChanged === 'I') {
      setTab2WorkTimeShow(false);
    }
    setTab2WorkSttus(e.target.value);
  };
  // 작업취소사유
  const onChangeTab2CancelReason = useCallback((e) => {
    console.log(e.target.value);
    setTab2CancelReason(e.target.value);
    if (e.target.value === 'reason1') {
      setCancelReasonDetailShow(false);
      setTab2CancelReasonDetail('날씨로 인한 작업취소');
    } else if (e.target.value === 'reason2') {
      setCancelReasonDetailShow(false);
      setTab2CancelReasonDetail('현장사정으로 인한 작업취소');
    } else if (e.target.value === 'reason3') {
      setCancelReasonDetailShow(true);
      setTab2CancelReasonDetail();
    }
  }, []);
  // 작업취소사유 내용
  const onChangeCancelReasonDetail = useCallback((e) => {
    setTab2CancelReasonDetail(e.target.value);
  }, []);
  // 작업시작일
  const onChangeTab2WorkStartDate = useCallback((e) => {
    setTab2WorkBegin(e.target.value);
  }, []);
  // 작업종료일
  const onChangeTab2WorkEndDate = useCallback((e) => {
    setTab2WorkEndde(e.target.value);
  }, []);
  const onChangeTab2HowtoPay = useCallback((e) => {
    setTab2PymntMthd(e.target.value);
  }, []);
  const onChangeTab2Memo = useCallback((e) => {
    setTab2WorkMemo(e.target.value);
  }, []);
  const onChangeTab2WorkType = useCallback((e) => {
    // workType에 따른 시간설정
    // 1 = 08:00 12:00
    // 2 = 13:00 17:00
    // 3 = 08:00 17:00
    if (e.target.value === '1') {
      setTab2WorkType('1');
      setTab2WorkTimeShow(false);
      setTab2BeginTime('08:00:00');
      setTab2EndTime('12:00:00');
    } else if (e.target.value === '2') {
      setTab2WorkType('2');
      setTab2WorkTimeShow(false);
      setTab2BeginTime('13:00:00');
      setTab2EndTime('17:00:00');
    } else if (e.target.value === '3') {
      setTab2WorkType('3');
      setTab2WorkTimeShow(false);
      setTab2BeginTime('08:00:00');
      setTab2EndTime('17:00:00');
    } else {
      setTab2WorkType('4');
      setTab2WorkTimeShow(true);
      setTab2BeginTime();
      setTab2EndTime();
    }
  }, []);
  const onChangeTab2EmployeeCnt = useCallback((e) => {
    setTab2EmployeeCnt(e.target.value);
  }, []);
  const onChangeTab2WorkBeginTime = useCallback((e) => {
    setTab2BeginTime(e.target.value);
  }, []);
  const onChangeTab2WorkEndTime = useCallback((e) => {
    setTab2EndTime(e.target.value);
  }, []);
  // ---------------------------------------------------------------------------------------------------
  const onRowClickTab1 = useCallback((params) => {
    const price = inputPriceFormat(params.workAmount);
    setTab1WorkNo(params.workNo);
    setTab1RegUser(params.regUser);
    setTab1EquitmentNm(params.eqpmnNm);
    setTab1WorkAddres(params.workAdres);
    setTab1WorkCn(params.workCn);
    setTab1WorkAmount(price);
    setTab1PymntMthd(params.pymntMthd);
    setTab1WorkBegin(params.workBegin);
    setTab1WorkEndde(params.workEndde);
    setTab1WorkSttus(params.workSttus);
    setTab1WorkMemo(params.workMemo);
    setTab1EmployeeCnt(params.workNmprCnt);
    if (params.beginTime === '08:00:00' && params.enddeTime === '17:00:00') {
      setTab1WorkType('3');
      setTab1WorkTimeShow(false);
    } else if (params.beginTime === '08:00:00' && params.enddeTime === '12:00:00') {
      setTab1WorkType('1');
      setTab1WorkTimeShow(false);
    } else if (params.beginTime === '13:00:00' && params.enddeTime === '17:00:00') {
      setTab1WorkType('2');
      setTab1WorkTimeShow(false);
    } else {
      setTab1WorkType('4');
      setTab1WorkTimeShow(true);
      setTab1BeginTime(params.beginTime);
      setTab1EndTime(params.enddeTime);
    }
  }, []);
  const onRowClickTab2 = useCallback((params) => {
    console.log(params);
    const price = params.workAmount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
    if (params.workSttus === 'C') {
      setCancelReasonShow(true);
    } else {
      setCancelReasonShow(false);
    }
    if (params.workSttus === 'E' || params.workSttus === 'C') {
      setTab2ReRegButtonShow(true);
      setTab2UptButtonShow(false);
    } else {
      setTab2ReRegButtonShow(false);
      setTab2UptButtonShow(true);
    }
    setTab2WorkNo(params.workNo);
    setTab2RegUser(params.regUser);
    setTab2EquitmentNm(params.eqpmnNm);
    setTab2WorkAddres(params.workAdres);
    setTab2WorkCn(params.workCn);
    setTab2WorkAmount(price);
    setTab2PymntMthd(params.pymntMthd);
    setTab2WorkBegin(params.workBegin);
    setTab2WorkEndde(params.workEndde);
    setTab2WorkSttus(params.workSttus);
    setTab2WorkSttusChanged(params.workSttus);
    setTab2EmployeeCnt(params.workNmprCnt);
    setTab2applyCnt(params.applyCnt);
    // 기본값을 비교하는 값 저장
    setTab2WorkBeginChanged(params.workBegin);
    setTab2WorkEnddeChanged(params.workEndde);
    setTab2BeginTimeChanged(params.beginTime);
    setTab2EndTimeChanged(params.enddeTime);
    if (params.canclResn !== 'undefiend') {
      setTab2CancelReason('reason3');
      setCancelReasonDetailShow(true);
      setTab2CancelReasonDetail(params.canclResn);
    }
    setTab2WorkOrnt(params.workOrnt);
    setTab2WorkMemoChanged(params.workMemo);
    // setCheckApplyCnt(params.workNmprCnt);
    if (params.beginTime === '08:00:00' && params.enddeTime === '17:00:00') {
      setTab2WorkType('3');
      setTab2WorkTypeChanged('3');
      setTab2WorkTimeShow(false);
      setTab2BeginTime(params.beginTime);
      setTab2EndTime(params.enddeTime);
      setTab2BeginTimeChanged(params.beginTime);
      setTab2EndTimeChanged(params.enddeTime);
    } else if (params.beginTime === '08:00:00' && params.enddeTime === '12:00:00') {
      setTab2WorkType('1');
      setTab2WorkTypeChanged('1');
      setTab2WorkTimeShow(false);
      setTab2BeginTime(params.beginTime);
      setTab2EndTime(params.enddeTime);
      setTab2BeginTimeChanged(params.beginTime);
      setTab2EndTimeChanged(params.enddeTime);
    } else if (params.beginTime === '13:00:00' && params.enddeTime === '17:00:00') {
      setTab2WorkType('2');
      setTab2WorkTypeChanged('2');
      setTab2WorkTimeShow(false);
      setTab2BeginTime(params.beginTime);
      setTab2EndTime(params.enddeTime);
      setTab2BeginTimeChanged(params.beginTime);
      setTab2EndTimeChanged(params.enddeTime);
    } else {
      setTab2WorkType('4');
      setTab2WorkTypeChanged('4');
      setTab2WorkTimeShow(true);
      setTab2BeginTime(params.beginTime);
      setTab2EndTime(params.enddeTime);
      setTab2BeginTimeChanged(params.beginTime);
      setTab2EndTimeChanged(params.enddeTime);
    }
    handleApplyListApi(params.workNo);
    // 메모
    // console.log(params.workMemo);
    if (params.workMemo) {
      if (params.workMemo.includes('[')) {
        if (params.workMemo.includes('필독')) {
          setTab2CheckNormal(false);
          setTab2CheckEmergency(false);
          setTab2CheckMustRead(true);
          setTab2WorkMemo(params.workMemo.substr(4));
        }
        if (params.workMemo.includes('긴급')) {
          setTab2CheckEmergency(true);
          setTab2CheckNormal(false);
          setTab2CheckMustRead(false);
          setTab2WorkMemo(params.workMemo.substr(4));
        }
      } else {
        setTab2CheckMustRead(false);
        setTab2CheckNormal(true);
        setTab2CheckEmergency(false);
        setTab2WorkMemo(params.workMemo);
      }
    } else {
      setTab2CheckMustRead(false);
      setTab2CheckNormal(true);
      setTab2CheckEmergency(false);
      setTab2WorkMemo(params.workMemo);
    }
  }, []);
  const onRowClickTab3 = useCallback((params) => {
    const price = inputPriceFormat(params.workAmount);
    setTab1WorkNo(params.workNo);
    setTab1RegUser(params.regUser);
    setTab1EquitmentNm(params.eqpmnNm);
    setTab1WorkAddres(params.workAdres);
    setTab1WorkCn(params.workCn);
    setTab1WorkAmount(price);
    setTab1PymntMthd(params.pymntMthd);
    setTab1WorkBegin(params.workBegin);
    setTab1WorkEndde(params.workEndde);
    setTab1WorkSttus(params.workSttus);
    setTab1WorkMemo(params.workMemo);
    setTab1EmployeeCnt(params.workNmprCnt);
    setTab1ApplySttus(params.applySttus);
    if (params.mbtlnum) {
      console.log(params.mbtlnum);
      const autoMbtlnum = params.mbtlnum.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
      console.log(autoMbtlnum);
      setTab1MbtlNum(autoMbtlnum);
    }
    setTab1WorkOrnt(params.workOrnt);
    if (params.beginTime === '08:00:00' && params.enddeTime === '17:00:00') {
      setTab1WorkType('3');
      setTab1WorkTimeShow(false);
    } else if (params.beginTime === '08:00:00' && params.enddeTime === '12:00:00') {
      setTab1WorkType('1');
      setTab1WorkTimeShow(false);
    } else if (params.beginTime === '13:00:00' && params.enddeTime === '17:00:00') {
      setTab1WorkType('2');
      setTab1WorkTimeShow(false);
    } else {
      setTab1WorkType('4');
      setTab1WorkTimeShow(true);
      setWorkBeginTime(params.beginTime);
      setWorkEndTime(params.enddeTime);
    }
  }, []);
  // 신청자 클릭 Event (공사번호 저장)
  const onRowClickApplyList = (params) => {
    // const selectedData = [...selectedRows, JSON.stringify(params)];
    // console.log(selectedData);
    // getSelectedRows
    setApplyStatus(params.applySttus);
    setApplyMbernm(params.ncm);
    setSendApplyNo(params.workNo);
    setSendApplyId(params.id);
  };
  // -----------------------------------------------------------------------------------------------------------
  const handleChangeTab = useCallback((e, newValue) => {
    setValue(newValue);
    if (newValue === '1') {
      setTab2WorkNo();
      WorkListGetData();
    } else if (newValue === '2') {
      setTab1WorkNo();
      myWorkListGetData();
    } else if (newValue === '3') {
      setTab2WorkNo();
      myReqListGetData();
    }
  }, []);
  // -----------------------------------------------------------------------------------------------------------
  // Debounce 함수 적용 - 나중에 적용
  const debounceFunction = (callback, delay) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => callback(...args), delay);
    };
  };
  const inputPriceFormat = useCallback((str) => {
    // console.log('s', str);
    const comma = (str) => {
      str = String(str);
      return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
    };
    const uncomma = (str) => {
      str = String(str);
      return str.replace(/[^\d]+/g, '');
    };
    return comma(uncomma(str));
  }, []);
  // -----------------------------------------------------------------------------------------------------------
  return (
    <Page title="모집공고">
      <Box spacing={1}>
        <Stack justifyContent="flex-start">
          <Typography variant="h3" gutterBottom>
            모집공고
          </Typography>
        </Stack>
        <TabContext value={value} spacing={0.5}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChangeTab}>
              <Tab label="신규공고목록" value="1" />
              <Tab label="내가등록한목록" value="2" />
              <Tab label="내가신청한목록" value="3" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Stack direction="row" justifyContent="space-between" mb={0.5}>
              <Stack direction="row" spacing={0.5}>
                <TextField
                  fullWidth
                  label="장비명"
                  type="search"
                  size="small"
                  name="tab1SearchEqNm"
                  value={tab1SearchEquitmentNm}
                  onChange={onChangeSearchEquitNm}
                  sx={{ width: '200px' }}
                />
                <TextField
                  fullWidth
                  label="작업지역"
                  type="search"
                  size="small"
                  name="tab1SearchAddres"
                  value={tab1SearchWorkAddres}
                  onChange={onChangeSearchWorkAddres}
                  sx={{ width: '200px' }}
                />
              </Stack>
              <Stack direction="row" spacing={1} mb={1}>
                <Button size="small" onClick={() => WorkListGetData()}>
                  <RefreshIcon />
                  {/* handleApplyListApi(tab2WorkNo); setApplyListOpen(true); */}
                  새로고침
                </Button>
                <Button variant="contained" size="small" onClick={handleTab1DeclarationOpen}>
                  신고하기
                </Button>
                <Dialog
                  open={tab1DeclarationOpen}
                  onClose={handleTab1DeclarationClose}
                  PaperProps={{ sx: { width: '20%', height: '30%' } }}
                >
                  <DialogTitle align="center">신고하기</DialogTitle>
                  <DialogContent>
                    <Stack direction="column" spacing={1}>
                      <Select
                        value={tab1DeclarationResn}
                        onChange={onChangeDeclarationResn}
                        size="small"
                        sx={{
                          svg: { width: '30px' }
                        }}
                      >
                        <MenuItem value="스팸홍보 · 도배글">스팸홍보 · 도배글</MenuItem>
                        <MenuItem value="음란물 · 선정성">음란물 · 선정성</MenuItem>
                        <MenuItem value="불법정보 포함 게시글">불법정보 포함 게시글</MenuItem>
                        <MenuItem value="욕설 · 생명경기 · 혐오 · 차별적 표현 게시글">
                          욕설 · 생명경기 · 혐오 · 차별적 표현 게시글
                        </MenuItem>
                        <MenuItem value="불쾌한 표현 게시글">불쾌한 표현 게시글</MenuItem>
                        <MenuItem value="기타 사유 입력">기타 사유 입력</MenuItem>
                      </Select>
                      <TextareaAutosize
                        // defaultValue
                        value={tab1DeclarationCn}
                        onChange={onChangeDeclarationCn}
                        minRows={7}
                        placeholder="기타 사유 입력"
                        sx={{ width: '100%' }}
                        disabled={tab1DeclarationResn !== '기타 사유 입력'}
                      />
                      <Stack direction="row" justifyContent="center" spacing={1}>
                        <Button variant="contained" onClick={handleDeclaration}>
                          신고
                        </Button>
                        <Button variant="contained" onClick={handleTab1DeclarationClose}>
                          닫기
                        </Button>
                      </Stack>
                    </Stack>
                  </DialogContent>
                </Dialog>
                <Button variant="outlined" size="small" onClick={handleRegDialogOpen}>
                  신규공고등록
                </Button>
                <Dialog
                  open={tab1RecuitmentRegOpen}
                  onClose={handleRegDialogClose}
                  PaperProps={{ sx: { width: '50%', height: '85%' } }}
                >
                  <DialogTitle align="center">공고등록</DialogTitle>
                  <DialogContent>
                    <Stack direction="column" justifyContent="space-between" spacing={3}>
                      <Stack direction="column" spacing={1}>
                        <FormControl>
                          <FormLabel>상태</FormLabel>
                          <RadioGroup row>
                            <FormControlLabel
                              checked={status === 'I'}
                              onChange={onChangeStatus}
                              value="I"
                              control={<Radio />}
                              label="모집중"
                            />
                            <FormControlLabel
                              checked={status === 'E'}
                              onChange={onChangeStatus}
                              value="E"
                              control={<Radio />}
                              label="마감"
                              disabled={status === 'I'}
                            />
                          </RadioGroup>
                        </FormControl>
                      </Stack>
                      <Stack direction="row" justifyContent="space-between" spacing={1}>
                        <TextField
                          fullWidth
                          label="장비명"
                          type="text"
                          variant="standard"
                          name="equNm"
                          value={equitment || ''}
                          onChange={onChangeEquitment}
                          inputProps={{
                            maxLength: EQUIPMENT_LIMIT
                          }}
                        />
                        <TextField
                          label="모집인원수"
                          type="number"
                          variant="standard"
                          value={employeeCnt || ''}
                          onChange={onChangeEmployeeCnt}
                        />
                      </Stack>
                      <TextField
                        label="작업주소"
                        type="text"
                        variant="standard"
                        value={address || ''}
                        onChange={onChangeAddress}
                      />
                      <TextField
                        label="작업내용"
                        type="text"
                        variant="standard"
                        value={workContents || ''}
                        onChange={onChangeWorkContents}
                      />
                      <Stack direction="row" justifyContent="space-between" spacing={1}>
                        <TextField
                          type="text"
                          fullWidth
                          variant="standard"
                          label="작업금액"
                          value={workPrice || ''}
                          onChange={(e) => setWorkPrice(inputPriceFormat(e.target.value))}
                          inputProps={{
                            maxLength: AMOUNT_LIMIT
                          }}
                        />
                        <TextField
                          fullWidth
                          label="지불방법"
                          type="text"
                          variant="standard"
                          value={howtoPay || ''}
                          onChange={onChangeHowtoPay}
                          inputProps={{
                            maxLength: PYMNT_MTHD_LIMIT
                          }}
                        />
                      </Stack>
                      <br />

                      <Stack direction="row" justifyContent="space-between" spacing={1}>
                        <TextField
                          fullWidth
                          type="date"
                          variant="standard"
                          value={workStartDate}
                          helpertext="작업시작일"
                          onChange={onChangeWorkStartDate}
                          ref={refWorkStartDate}
                        />
                        <TextField
                          fullWidth
                          type="date"
                          variant="standard"
                          value={workEndtDate || ''}
                          helpertext="작업종료일"
                          onChange={onChangeWorkEndDate}
                          ref={refWorkEndDate}
                        />
                      </Stack>
                      <FormControl>
                        <FormLabel>작업시간</FormLabel>
                        <RadioGroup row>
                          <FormControlLabel
                            checked={workType === '1'}
                            onChange={onChangeWorkType}
                            value="1"
                            control={<Radio />}
                            label="오전(08:00~12:00)"
                          />
                          <FormControlLabel
                            checked={workType === '2'}
                            onChange={onChangeWorkType}
                            value="2"
                            control={<Radio />}
                            label="오후(13:00~17:00)"
                          />
                          <FormControlLabel
                            checked={workType === '3'}
                            onChange={onChangeWorkType}
                            value="3"
                            control={<Radio />}
                            label="종일(08:00~17:00)"
                          />
                          <FormControlLabel
                            checked={workType === '4'}
                            onChange={onChangeWorkType}
                            value="4"
                            control={<Radio />}
                            label="사용자지정"
                          />
                          {workTimeShow && (
                            <Stack direction="row" justifyContent="space-between" spacing={1}>
                              <TextField
                                fullWidth
                                type="time"
                                // views="hours"
                                variant="standard"
                                value={workBeginTime}
                                helpertext="작업시작시간"
                                onChange={onChangeWorkBeginTime}
                                // ref={refWorkStartDate}
                              />
                              <TextField
                                fullWidth
                                type="time"
                                // views="hours"
                                variant="standard"
                                value={workEndTime || ''}
                                helpertext="작업종료시간"
                                onChange={onChangeWorkEndTime}
                                // ref={refWorkEndDate}
                              />
                            </Stack>
                          )}
                        </RadioGroup>
                      </FormControl>
                      <Stack direction="row" justifyContent="flex-start">
                        <FormControlLabel
                          control={<Checkbox checked={checkNormal} onChange={handleCheckNormal} />}
                          label="일반"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox checked={checkMustRead} onChange={handleCheckMustRead} />
                          }
                          label="필독"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox checked={checkEmergency} onChange={handleCheckEmergency} />
                          }
                          label="긴급"
                        />
                      </Stack>
                      <TextField
                        label="메모"
                        type="text"
                        variant="standard"
                        value={memoShow}
                        onChange={onChangeMemo}
                      />
                      <br />
                    </Stack>
                    <Stack direction="row" justifyContent="center" spacing={1}>
                      <Button variant="contained" onClick={handleRegSave}>
                        저장
                      </Button>
                      <Button variant="contained" onClick={handleRegDialogClose}>
                        취소
                      </Button>
                    </Stack>
                  </DialogContent>
                </Dialog>
                {/* <Button variant="outlined" size="small" onClick={handleMultiRequestApplyApi}>
                  공고신청
                </Button> */}
                <Button variant="contained" onClick={onClickSearchTab1}>
                  검색
                </Button>
              </Stack>
            </Stack>
            <div style={{ height: 600, width: '100%' }}>
              <DataGrid
                rows={myWorkList}
                columns={NEW_TABLE_HEAD}
                rowHeight={40}
                getRowId={(row) => row.workNo}
                // checkboxSelection
                onRowClick={(params) => onRowClickTab1(params.row)}
                // onSelectionModelChange={(ids) => {
                //   const selectedIDs = new Set(ids);
                //   const selectedRows = myWorkList.filter((ids) => selectedIDs.has(ids.workNo));
                //   if (!selectedRows.includes(ids.workNo)) {
                //     setSelectedRows(selectedRows);
                //   }
                //   // makeRequestApplyData(selectedRows);
                //   console.log(selectedRows);
                // }}
                // initialState={{ pinnedColumns: { left: ['지원현황'] } }}
                disableColumnMenu
                onPageChange={(newPage) => setTab1Page(newPage)}
                // pageSize={5}
                rowsPerPageOptions={[25, 50, 100]}
                pagination
              />
            </div>
          </TabPanel>
          <TabPanel value="2">
            <Stack direction="row" justifyContent="space-between" mb={0.5}>
              <Stack direction="row" spacing={0.5}>
                <TextField
                  fullWidth
                  label="장비명"
                  type="search"
                  size="small"
                  value={tab2SearchEquitmentNm}
                  onChange={onChangeTab2SearchEquitNm}
                  sx={{ width: '200px' }}
                  // onKeyDown={onkeydown}
                />
                <TextField
                  fullWidth
                  label="작업지역"
                  type="search"
                  size="small"
                  value={tab2SearchWorkAddres}
                  onChange={onChangeTab2SearchWorkAddres}
                  sx={{ width: '200px' }}
                  // onKeyDown={onkeydown}
                />
                {/* <div style={{ width: '200px', height: '50px' }}> */}
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={ko}>
                  <DatePicker
                    size="small"
                    views={['year', 'month']}
                    label="등록일"
                    value={tab2SearchStartMonth}
                    inputFormat="yyyy-MM"
                    closeOnSelect="true"
                    defaultValue={null}
                    onChange={(newValue) => {
                      if (newValue) {
                        const year = newValue.getFullYear();
                        const month = newValue.getMonth() + 1;
                        setTab2SearchStartMonth(`${year}-${month}`);
                        // setTab2SearchStartMonth(newValue);
                      } else {
                        setTab2SearchStartMonth(null);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        {...params}
                        helpertext={null}
                        sx={{
                          svg: { width: '15px' }
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
                {/* </div> */}
                <Select
                  id="workSttus"
                  value={tab2SearchSttus}
                  label="진행상태"
                  onChange={onChangeTab2SearchSttus}
                  size="small"
                  helpertext={null}
                  sx={{
                    svg: { width: '10px' }
                  }}
                >
                  <MenuItem value="ALL">전체</MenuItem>
                  <MenuItem value="I">모집중</MenuItem>
                  <MenuItem value="E">마감</MenuItem>
                  <MenuItem value="C">작업취소</MenuItem>
                </Select>
              </Stack>
              <Stack direction="row" spacing={1} mb={1}>
                <Button size="small" onClick={() => myWorkListGetData()}>
                  <RefreshIcon />
                  {/* handleApplyListApi(tab2WorkNo); setApplyListOpen(true); */}
                  새로고침
                </Button>
                <Button variant="outlined" size="small" onClick={tab2ClickDetailDialogOpen}>
                  내용수정
                </Button>
                <Dialog
                  open={tab2RecuitmentDetailOpen}
                  onClose={tab2ClickDetailDialogClose}
                  PaperProps={{ sx: { width: '80%', height: '65%' } }}
                >
                  <DialogTitle align="center">공고내용</DialogTitle>
                  <DialogContent>
                    <Stack direction="column" justifyContent="space-between" spacing={1}>
                      <Stack direction="row" justifyContent="flex-end" spacing={0.5}>
                        {tab2ReRegButtonShow && (
                          <Button
                            variant="contained"
                            onClick={handleReRegNotice}
                            disabled={tab2WorkSttus !== 'I' || tab2WorkSttusChanged === 'I'}
                          >
                            재등록
                          </Button>
                        )}
                        {tab2UptButtonShow && (
                          <Button
                            variant="contained"
                            onClick={handleUpdQuestion}
                            disabled={tab2ApplyCnt > 0 && tab2WorkSttus === 'I'}
                          >
                            수정
                          </Button>
                        )}
                        {/* {tab2UptButtonShow2 && (
                          <Button
                            variant="contained"
                            onClick={handleUpdQuestion}
                            disabled={tab2WorkSttus === 'I' || tab2WorkSttus === 'C'}
                          >
                            공고마감
                          </Button>
                        )} */}
                        <Button variant="contained" onClick={tab2ClickDetailDialogClose}>
                          닫기
                        </Button>
                      </Stack>
                      <Stack direction="column" justifyContent="space-between" spacing={1}>
                        <Stack direction="row" spacing={0.5}>
                          <FormControl>
                            <FormLabel>상태</FormLabel>
                            <RadioGroup row>
                              <FormControlLabel
                                checked={tab2WorkSttus === 'I'}
                                onChange={onChangeTab2Status}
                                value="I"
                                control={<Radio />}
                                label="모집중"
                                // disabled={tab2WorkSttus === 'E'}
                              />
                              <FormControlLabel
                                checked={tab2WorkSttus === 'E'}
                                onChange={onChangeTab2Status}
                                value="E"
                                control={<Radio />}
                                label="마감"
                                disabled={tab2WorkSttusChanged === 'C'}
                              />
                              <FormControlLabel
                                checked={tab2WorkSttus === 'C'}
                                onChange={onChangeTab2Status}
                                value="C"
                                control={<Radio />}
                                label="작업취소"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Stack>
                        <Stack direction="row" justifyContent="flex-start" spacing={0.5}>
                          {cancelReasonShow && (
                            <Select
                              size="small"
                              label="선택(취소사유)"
                              id="cancelReason"
                              value={tab2CancelReason}
                              variant="standard"
                              onChange={onChangeTab2CancelReason}
                              sx={{ minWidth: 180 }}
                            >
                              <MenuItem value="reason1">날씨로 인한 작업취소</MenuItem>
                              <MenuItem value="reason2">현장사정으로 인한 작업취소</MenuItem>
                              <MenuItem value="reason3">직접입력</MenuItem>
                            </Select>
                          )}
                          {cancelReasonDeatialShow && (
                            <TextField
                              fullWidth
                              size="small"
                              label="취소사유"
                              type="text"
                              variant="standard"
                              value={tab2CancelReasonDetail || ''}
                              onChange={onChangeCancelReasonDetail}
                            />
                          )}
                        </Stack>
                        <Stack direction="row" justifyContent="space-between" spacing={0.5}>
                          <TextField
                            fullWidth
                            label="장비명"
                            type="text"
                            variant="standard"
                            value={tab2EquitmentNm || ''}
                            onChange={onChangeTab2Equitment}
                            disabled={tab2WorkSttus === 'E' || tab2WorkSttus === 'C'}
                            inputProps={{
                              maxLength: EQUIPMENT_LIMIT
                            }}
                          />
                          <TextField
                            label="모집인원수"
                            type="text"
                            variant="standard"
                            value={tab2EmployeeCnt || ''}
                            onChange={onChangeTab2EmployeeCnt}
                            // {...outChangeTab2Equitment}
                            // onKeyPress={onChangeTab1Equitment}
                            disabled={tab2WorkSttus === 'E' || tab2WorkSttus === 'C'}
                          />
                        </Stack>
                        <TextField
                          label="작업내용"
                          type="text"
                          variant="standard"
                          value={tab2WorkCn || ''}
                          onChange={onChangeTab2WorkContents}
                          // onKeyPress={onChangeTab1WorkContents}
                          disabled={tab2WorkSttus === 'E' || tab2WorkSttus === 'C'}
                        />
                        <TextField
                          label="작업주소"
                          type="text"
                          variant="standard"
                          value={tab2WorkAddres || ''}
                          onChange={onChangeTab2Address}
                          disabled={tab2WorkSttus === 'E' || tab2WorkSttus === 'C'}
                        />
                        <Stack direction="row" justifyContent="space-between" spacing={0.5}>
                          <TextField
                            fullWidth
                            label="작업금액"
                            type="text"
                            variant="standard"
                            value={tab2WorkAmount || ''}
                            onChange={(e) => setTab2WorkAmount(inputPriceFormat(e.target.value))}
                            disabled={tab2WorkSttus === 'E' || tab2WorkSttus === 'C'}
                            inputProps={{
                              maxLength: AMOUNT_LIMIT
                            }}
                          />
                          <TextField
                            fullWidth
                            label="지불방법"
                            type="text"
                            variant="standard"
                            value={tab2PymntMthd || ''}
                            onChange={onChangeTab2HowtoPay}
                            disabled={tab2WorkSttus === 'E' || tab2WorkSttus === 'C'}
                            inputProps={{
                              maxLength: PYMNT_MTHD_LIMIT
                            }}
                          />
                        </Stack>
                        <Stack direction="row" justifyContent="space-between" spacing={0.5}>
                          <TextField
                            fullWidth
                            type="date"
                            variant="standard"
                            value={tab2WorkBegin}
                            helpertext="작업시작일"
                            onChange={onChangeTab2WorkStartDate}
                            disabled={tab2WorkSttus === 'E' || tab2WorkSttus === 'C'}
                            ref={refTab2WorkStartDate}
                          />
                          <TextField
                            fullWidth
                            type="date"
                            variant="standard"
                            value={tab2WorkEndde || ''}
                            helpertext="작업종료일"
                            onChange={onChangeTab2WorkEndDate}
                            disabled={tab2WorkSttus === 'E' || tab2WorkSttus === 'C'}
                            ref={refTab2WorkEndDate}
                          />
                        </Stack>
                        <FormControl>
                          <FormLabel>작업시간</FormLabel>
                          <RadioGroup row>
                            <FormControlLabel
                              checked={tab2WorkType === '1'}
                              onChange={onChangeTab2WorkType}
                              value="1"
                              control={<Radio />}
                              label="오전(08:00~12:00)"
                              disabled={tab2WorkSttus === 'E' || tab2WorkSttus === 'C'}
                            />
                            <FormControlLabel
                              checked={tab2WorkType === '2'}
                              onChange={onChangeTab2WorkType}
                              value="2"
                              control={<Radio />}
                              label="오후(13:00~17:00)"
                              disabled={tab2WorkSttus === 'E' || tab2WorkSttus === 'C'}
                            />
                            <FormControlLabel
                              checked={tab2WorkType === '3'}
                              onChange={onChangeTab2WorkType}
                              value="3"
                              control={<Radio />}
                              label="종일(08:00~17:00)"
                              disabled={tab2WorkSttus === 'E' || tab2WorkSttus === 'C'}
                            />
                            <FormControlLabel
                              checked={tab2WorkType === '4'}
                              onChange={onChangeTab2WorkType}
                              value="4"
                              control={<Radio />}
                              label="사용자지정"
                              disabled={tab2WorkSttus === 'E' || tab2WorkSttus === 'C'}
                            />
                            {tab2WorkTimeShow && (
                              <Stack direction="row" justifyContent="space-between" spacing={1}>
                                <TextField
                                  fullWidth
                                  type="time"
                                  variant="standard"
                                  value={tab2BeginTime || ''}
                                  helpertext="작업시작시간"
                                  onChange={onChangeTab2WorkBeginTime}
                                  // ref={refWorkStartDate}
                                  disabled={tab2WorkSttus === 'E' || tab2WorkSttus === 'C'}
                                />
                                <TextField
                                  fullWidth
                                  type="time"
                                  variant="standard"
                                  value={tab2EndTime || ''}
                                  helpertext="작업종료시간"
                                  onChange={onChangeTab2WorkEndTime}
                                  // ref={refWorkEndDate}
                                  disabled={tab2WorkSttus === 'E' || tab2WorkSttus === 'C'}
                                />
                              </Stack>
                            )}
                          </RadioGroup>
                        </FormControl>
                        <Stack direction="row" justifyContent="flex-start">
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={tab2CheckNormal}
                                onChange={handleTab2CheckNormal}
                              />
                            }
                            label="일반"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={tab2CheckMustRead}
                                onChange={handleTab2CheckMustRead}
                              />
                            }
                            label="필독"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={tab2CheckEmergency}
                                onChange={handleTab2CheckEmergency}
                              />
                            }
                            label="긴급"
                          />
                        </Stack>
                        <TextField
                          label="메모"
                          type="text"
                          variant="standard"
                          value={tab2WorkMemo || ''}
                          onChange={onChangeTab2Memo}
                          // disabled={tab2RegUser !== sessionStorage.getItem('id')}
                        />
                      </Stack>
                    </Stack>
                  </DialogContent>
                </Dialog>
                <Button
                  // color={'reject'}
                  variant="outlined"
                  size="small"
                  onClick={applyListDialogOpen}
                >
                  지원자현황
                </Button>
                <Dialog
                  open={applyListOpen}
                  onClose={applyListDialogClose}
                  PaperProps={{ sx: { width: '50%', height: '55%' } }}
                >
                  <DialogTitle align="center" spacing={1}>
                    신청자현황
                  </DialogTitle>
                  <Stack direction="column" justifyContent="flex-end" spacing={0.5}>
                    {/* <Stack direction="row" justifyContent="flex-end" spacing={0.5}>
                      <Typography>총 모집가능인원 {tab2EmployeeCnt} 명</Typography>
                      <Typography>현재모집인원 {checkApplyCnt === 'undefined'} 명</Typography>
                    </Stack> */}
                    <Stack direction="row" justifyContent="flex-end" spacing={0.5}>
                      <Button size="small" onClick={() => handleApplyListApi(tab2WorkNo)}>
                        <RefreshIcon />
                        {/* handleApplyListApi(tab2WorkNo); setApplyListOpen(true); */}
                        새로고침
                      </Button>
                      {/* <ThemeProvider theme={theme}> */}
                      <Button
                        variant="contained"
                        onClick={handleApplyQuestion}
                        // 승인인 경우 비활성
                        disabled={tab2WorkSttus === 'E' || applyStatus === 'S'}
                      >
                        승인
                      </Button>
                      <Button
                        // color="apply"
                        variant="contained"
                        onClick={handleApplyRejecQuestion}
                        // 미승인인 경우 비활성
                        disabled={tab2WorkSttus === 'E' || applyStatus === 'R'}
                      >
                        미승인
                      </Button>
                      <Button variant="outlined" onClick={applyListDialogClose}>
                        닫기
                      </Button>
                    </Stack>
                    <div style={{ height: 400, width: '100%' }}>
                      <DataGrid
                        rows={applyList}
                        columns={APPLY_TABLE_HEAD}
                        rowHeight={40}
                        // checkboxSelection
                        onSelec
                        getRowId={(row) => row.applyNo}
                        onRowClick={(params) => onRowClickApplyList(params.row)}
                        // getSelectedRows={() => Map<gridRowId, gridRowData>}
                        hideFooterPagination
                        disableColumnMenu
                      />
                    </div>
                  </Stack>
                </Dialog>
                <Button variant="outlined" size="small" onClick={handleRegDialogOpen}>
                  신규공고등록
                </Button>
                <Dialog
                  open={tab1RecuitmentRegOpen}
                  onClose={handleRegDialogClose}
                  PaperProps={{ sx: { width: '50%', height: '85%' } }}
                >
                  <DialogTitle align="center">공고등록</DialogTitle>
                  <DialogContent>
                    <Stack direction="column" justifyContent="space-between" spacing={3}>
                      <Stack direction="column" spacing={1}>
                        <FormControl>
                          <FormLabel>상태</FormLabel>
                          <RadioGroup row>
                            <FormControlLabel
                              checked={status === 'I'}
                              onChange={onChangeStatus}
                              value="I"
                              control={<Radio />}
                              label="모집중"
                            />
                            <FormControlLabel
                              checked={status === 'E'}
                              onChange={onChangeStatus}
                              value="E"
                              control={<Radio />}
                              label="마감"
                              disabled={status === 'I'}
                            />
                          </RadioGroup>
                        </FormControl>
                      </Stack>
                      <Stack direction="row" justifyContent="space-between" spacing={1}>
                        <TextField
                          fullWidth
                          label="장비명"
                          type="text"
                          variant="standard"
                          value={equitment || ''}
                          onChange={onChangeEquitment}
                          inputProps={{
                            maxlength: EQUIPMENT_LIMIT
                          }}
                        />
                        <TextField
                          label="모집인원수"
                          type="number"
                          variant="standard"
                          value={employeeCnt || ''}
                          onChange={onChangeEmployeeCnt}
                        />
                      </Stack>
                      <TextField
                        label="작업주소"
                        type="text"
                        variant="standard"
                        value={address || ''}
                        onChange={onChangeAddress}
                      />
                      <TextField
                        label="작업내용"
                        type="text"
                        variant="standard"
                        value={workContents || ''}
                        onChange={onChangeWorkContents}
                      />
                      <Stack direction="row" justifyContent="space-between" spacing={1}>
                        <TextField
                          type="text"
                          fullWidth
                          variant="standard"
                          label="작업금액"
                          value={workPrice || ''}
                          onChange={(e) => setWorkPrice(inputPriceFormat(e.target.value))}
                          inputProps={{
                            maxLength: AMOUNT_LIMIT
                          }}
                        />
                        <TextField
                          fullWidth
                          label="지불방법"
                          type="text"
                          variant="standard"
                          value={howtoPay || ''}
                          onChange={onChangeHowtoPay}
                          inputProps={{
                            maxLength: PYMNT_MTHD_LIMIT
                          }}
                        />
                      </Stack>
                      <br />

                      <Stack direction="row" justifyContent="space-between" spacing={1}>
                        <TextField
                          fullWidth
                          type="date"
                          variant="standard"
                          value={workStartDate}
                          helpertext="작업시작일"
                          onChange={onChangeWorkStartDate}
                          ref={refWorkStartDate}
                        />
                        <TextField
                          fullWidth
                          type="date"
                          variant="standard"
                          value={workEndtDate || ''}
                          helpertext="작업종료일"
                          onChange={onChangeWorkEndDate}
                          ref={refWorkEndDate}
                        />
                      </Stack>
                      <FormControl>
                        <FormLabel>작업시간</FormLabel>
                        <RadioGroup row>
                          <FormControlLabel
                            checked={workType === '1'}
                            onChange={onChangeWorkType}
                            value="1"
                            control={<Radio />}
                            label="오전(08:00~12:00)"
                          />
                          <FormControlLabel
                            checked={workType === '2'}
                            onChange={onChangeWorkType}
                            value="2"
                            control={<Radio />}
                            label="오후(13:00~17:00)"
                          />
                          <FormControlLabel
                            checked={workType === '3'}
                            onChange={onChangeWorkType}
                            value="3"
                            control={<Radio />}
                            label="종일(08:00~17:00)"
                          />
                          <FormControlLabel
                            checked={workType === '4'}
                            onChange={onChangeWorkType}
                            value="4"
                            control={<Radio />}
                            label="사용자지정"
                          />
                          {workTimeShow && (
                            <Stack direction="row" justifyContent="space-between" spacing={1}>
                              <TextField
                                fullWidth
                                type="time"
                                // views="hours"
                                variant="standard"
                                value={workBeginTime}
                                helpertext="작업시작시간"
                                onChange={onChangeWorkBeginTime}
                                // ref={refWorkStartDate}
                              />
                              <TextField
                                fullWidth
                                type="time"
                                // views="hours"
                                variant="standard"
                                value={workEndTime || ''}
                                helpertext="작업종료시간"
                                onChange={onChangeWorkEndTime}
                                // ref={refWorkEndDate}
                              />
                            </Stack>
                          )}
                        </RadioGroup>
                      </FormControl>
                      <Stack direction="row" justifyContent="flex-start">
                        <FormControlLabel
                          control={<Checkbox checked={checkNormal} onChange={handleCheckNormal} />}
                          label="일반"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox checked={checkMustRead} onChange={handleCheckMustRead} />
                          }
                          label="필독"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox checked={checkEmergency} onChange={handleCheckEmergency} />
                          }
                          label="긴급"
                        />
                      </Stack>
                      <TextField
                        label="메모"
                        type="text"
                        variant="standard"
                        value={memo}
                        onChange={onChangeMemo}
                      />
                      <br />
                    </Stack>
                    <Stack direction="row" justifyContent="center" spacing={1}>
                      <Button variant="contained" onClick={handleRegSave}>
                        저장
                      </Button>
                      <Button variant="contained" onClick={handleRegDialogClose}>
                        취소
                      </Button>
                    </Stack>
                  </DialogContent>
                </Dialog>
                <Button variant="contained" onClick={onClickSearchTab2}>
                  검색
                </Button>
              </Stack>
            </Stack>
            <div style={{ height: 600, width: '100%' }}>
              <DataGrid
                rows={myRegWorkList}
                columns={MYWORK_TABLE_HEAD}
                getRowId={(row) => row.workNo}
                onRowClick={(params) => onRowClickTab2(params.row)}
                rowHeight={40}
                disableColumnMenu
                onPageChange={(newPage) => setTab2Page(newPage)}
                // pageSize={5}
                rowsPerPageOptions={[25, 50, 100]}
                pagination
              />
            </div>
          </TabPanel>
          <TabPanel value="3">
            <Stack direction="row" justifyContent="space-between" mb={0.5}>
              <Stack direction="row" spacing={0.5}>
                <TextField
                  fullWidth
                  label="장비명"
                  type="search"
                  size="small"
                  value={tab3SearchEquitmentNm}
                  onChange={onChangeTab3SearchEquitNm}
                  sx={{ width: '200px' }}
                  // onKeyDown={onkeydown}
                />
                <TextField
                  fullWidth
                  label="작업지역"
                  type="search"
                  size="small"
                  value={tab3SearchWorkAddres}
                  onChange={onChangeTab3SearchWorkAddres}
                  sx={{ width: '200px' }}
                  // onKeyDown={onkeydown}
                />
                {/* <div style={{ width: '200px', height: '50px' }}> */}
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={ko}>
                  <DatePicker
                    size="small"
                    // sx={{ width: 50, height: 10 }}
                    views={['year', 'month']}
                    label="신청일"
                    // mask="____-__"
                    value={tab3SearchStartMonth}
                    inputFormat="yyyy-MM"
                    closeOnSelect="true"
                    onChange={(newValue) => {
                      if (newValue) {
                        const year = newValue.getFullYear();
                        const month = newValue.getMonth() + 1;
                        setTab3SearchStartMonth(`${year}-${month}`);
                        // setTab3SearchStartMonth(newValue);
                      } else {
                        setTab3SearchStartMonth(null);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        {...params}
                        helpertext={null}
                        sx={{
                          svg: { width: '15px' }
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
                {/* </div> */}
                <Select
                  fullWidth
                  id="applySttus"
                  value={tab3SearchSttus}
                  label="지원상태"
                  onChange={onChangeTab3SearchSttus}
                  sx={{ width: '30%' }}
                  size="small"
                >
                  <MenuItem value="ALL">전체</MenuItem>
                  <MenuItem value="A">지원중</MenuItem>
                  <MenuItem value="UC">지원취소</MenuItem>
                  <MenuItem value="S">승인</MenuItem>
                  <MenuItem value="R">미승인</MenuItem>
                  <MenuItem value="MC">작업취소</MenuItem>
                </Select>
              </Stack>
              <Stack direction="row" spacing={1} mb={1}>
                <Button size="small" onClick={() => myReqListGetData()}>
                  <RefreshIcon />
                  {/* handleApplyListApi(tab2WorkNo); setApplyListOpen(true); */}
                  새로고침
                </Button>
                <Button variant="outlined" onClick={tab3ClickDetailDialogOpen}>
                  지원취소
                </Button>
                <Dialog
                  open={tab3RecuitmentDetailOpen}
                  onClose={tab3ClickDetailDialogClose}
                  PaperProps={{ sx: { width: '50%', height: '55%' } }}
                >
                  <DialogTitle align="center">공고내용</DialogTitle>
                  <DialogContent>
                    <Stack direction="column" justifyContent="space-between" spacing={1}>
                      {/* {tab1MbtlNumShow && (
                        // <Stack direction="row" justifyContent="flex-start" spacing={0.5}>
                        
                        // </Stack>
                      )} */}
                      {/* <Stack direction="row" justifyContent="flex-end" spacing={0.5}> */}
                      <Box sx={{ width: '100%' }}>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                          <Grid item xs={4}>
                            <Typography variant="subtitle">
                              <strong>등록자 : {tab1WorkOrnt} </strong>
                              <br />

                              {tab1MbtlNumShow && <strong>연락처 : {tab1MbtlNum}</strong>}
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <Stack spacing={0.5} direction="row" justifyContent="flex-end">
                              <Button
                                variant="contained"
                                onClick={handleRequestApplyApi}
                                disabled={tab1WorkSttus !== 'I' || tab1ApplySttus !== 'UC'}
                              >
                                지원신청
                              </Button>

                              <Button
                                variant="contained"
                                onClick={handleCancelQuestion}
                                disabled={
                                  // 공고중이며, 승인됐을때만 지원취소 가능
                                  tab1WorkSttus !== 'I' || tab1ApplySttus !== 'A'
                                }
                              >
                                지원취소
                              </Button>
                              <Button variant="contained" onClick={tab3ClickDetailDialogClose}>
                                닫기
                              </Button>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Box>
                      {/* </Stack> */}
                      <Stack direction="row" justifyContent="flex-end" spacing={0.5}>
                        <TextField
                          fullWidth
                          label="장비명"
                          type="text"
                          variant="standard"
                          value={tab1EquitmentNm || ''}
                          inputProps={{
                            readOnly: true
                          }}
                        />
                        <TextField
                          fullWidth
                          label="작업내용"
                          type="text"
                          variant="standard"
                          value={tab1WorkCn || ''}
                          inputProps={{
                            readOnly: true
                          }}
                        />
                      </Stack>
                      <TextField
                        label="작업주소"
                        type="text"
                        variant="standard"
                        value={tab1WorkAddres || ''}
                        inputProps={{
                          readOnly: true
                        }}
                      />
                      <Stack direction="row" justifyContent="space-between" spacing={0.5}>
                        <TextField
                          fullWidth
                          label="작업금액"
                          type="text"
                          variant="standard"
                          value={tab1WorkAmount || ''}
                          inputProps={{
                            readOnly: true
                          }}
                        />
                        <TextField
                          fullWidth
                          label="지불방법"
                          type="text"
                          variant="standard"
                          value={tab1PymntMthd || ''}
                          inputProps={{
                            readOnly: true
                          }}
                        />
                      </Stack>
                      <br />

                      <Stack direction="row" justifyContent="space-between" spacing={0.5}>
                        <TextField
                          fullWidth
                          type="date"
                          variant="standard"
                          value={tab1WorkBegin || ''}
                          helpertext="작업시작일"
                          inputProps={{
                            readOnly: true
                          }}
                        />
                        <TextField
                          fullWidth
                          type="date"
                          variant="standard"
                          value={tab1WorkEndde || ''}
                          helpertext="작업종료일"
                          inputProps={{
                            readOnly: true
                          }}
                        />
                      </Stack>
                      <FormControl>
                        <FormLabel>작업시간</FormLabel>
                        <RadioGroup row>
                          <FormControlLabel
                            checked={tab1WorkType === '1'}
                            // onChange={onChangeTab1WorkType}
                            value="1"
                            control={<Radio />}
                            label="오전(08:00~12:00)"
                            inputProps={{
                              readOnly: true
                            }}
                          />
                          <FormControlLabel
                            checked={tab1WorkType === '2'}
                            // onChange={onChangeTab1WorkType}
                            value="2"
                            control={<Radio />}
                            label="오후(13:00~17:00)"
                            inputProps={{
                              readOnly: true
                            }}
                          />
                          <FormControlLabel
                            checked={tab1WorkType === '3'}
                            // onChange={onChangeTab1WorkType}
                            value="3"
                            control={<Radio />}
                            label="종일(08:00~17:00)"
                            inputProps={{
                              readOnly: true
                            }}
                          />
                          <FormControlLabel
                            checked={tab1WorkType === '4'}
                            // onChange={onChangeWorkType}
                            value="4"
                            control={<Radio />}
                            label="사용자지정"
                            inputProps={{
                              readOnly: true
                            }}
                          />
                          {tab1WorkTimeShow && (
                            <Stack direction="row" justifyContent="space-between" spacing={1}>
                              <TextField
                                fullWidth
                                type="time"
                                variant="standard"
                                value={tab1BeginTime || ''}
                                helpertext="작업시작시간"
                                disabled={tab1WorkSttus === 'E' || tab1WorkSttus === 'C'}
                              />
                              <TextField
                                fullWidth
                                type="time"
                                variant="standard"
                                value={tab1EndTime || ''}
                                helpertext="작업종료시간"
                                disabled={tab1WorkSttus === 'E' || tab1WorkSttus === 'C'}
                              />
                            </Stack>
                          )}
                        </RadioGroup>
                      </FormControl>
                      <TextField
                        label="메모"
                        type="text"
                        variant="standard"
                        value={tab1WorkMemo || ''}
                        inputProps={{
                          readOnly: true
                        }}
                      />
                      <br />
                    </Stack>
                  </DialogContent>
                </Dialog>
                <Button variant="contained" onClick={onClickSearchTab3}>
                  검색
                </Button>
              </Stack>
            </Stack>
            <div style={{ height: 600, width: '100%' }}>
              <DataGrid
                rows={myReqWorkList}
                columns={MYREG_TABLE_HEAD}
                getRowId={(row) => row.workNo}
                rowHeight={40}
                onRowClick={(params) => onRowClickTab3(params.row)}
                disableColumnMenu
                onPageChange={(newPage) => setTab3Page(newPage)}
                // pageSize={5}
                rowsPerPageOptions={[25, 50, 100]}
                pagination
              />
            </div>
          </TabPanel>
        </TabContext>
      </Box>
    </Page>
  );
}

export default React.memo(RecruitmentNotice);
