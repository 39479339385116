import { useCallback, useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import {
  Stack,
  Link,
  Typography,
  TextField,
  Button,
  Card,
  Box,
  Divider,
  Grid,
  Icon
} from '@mui/material';
// import { Card, Stack, Link, Container, Typography } from '@mui/material';
// layouts
import HomeIcon from '@mui/icons-material/Home';
// components
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import AuthLayout from '../layouts/AuthLayout';
import Page from '../components/Page';

// const BASE_URL = process.env.REACT_APP_API_URL;
const divStyle = {
  // opacity: 0.5,
  top: '15%',
  left: '15%',
  width: 'flex',
  height: 'flex',
  justifyContent: 'center',
  position: 'absolute'
  // backgroundColor: 'white',
  // '&:hover': {
  //   backgroundColor: 'white',
};

// --------------------------------------------------------------------------------------------------------------
export default function ALTermsOfService() {
  useEffect(() => {}, []);
  return (
    <Page>
      <Box>
        <div style={divStyle}>
          {/* <Box
        style={{
          borderRadius: 10,
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)'
        }}
        // minHeight="100vh"
        display="flex"
        justifyContent="center"
        // overflow="scroll"
        sx={{
          // opacity: 0.5,
          width: 'flex',
          height: 700,
          // backgroundColor: 'white',
          // '&:hover': {
          //   backgroundColor: 'white',
          opacity: [0.7, 0.7, 0.95]
          // overflow="scroll"
          // }
        }}
      > */}
          <Stack direction="column">
            <Typography variant="h4" align="left">
              서비스 이용약관
            </Typography>
            <br />
            <Typography variant="body1">
              <strong>제 1 조 (목적)</strong>
              <br />본 약관은 주식회사 여니(이하 "회사")가 운영하는 웹사이트 및 모바일 어플리케이션
              서비스("중기365")를 이용함에 있어 "회사"와 "회원"간의 이용 조건 및 제반 절차, 권리,
              의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.
              <br />
              <br />
              <strong>제 2 조 (용어의 정의)</strong>
              <br />① "중기365"이라 함은 "회사"가 운영하는 웹사이트 및 모바일 어플리케이션을 통해
              "회원"이 구인, 구직등의 목적으로 등록하는 자료를 DB화하여 각각의 목적에 맞게 분류
              가공, 집계하여 정보를 제공하는 서비스를 말한다.
              <br />② "회원"이라 함은 "회사"가 제공하는 "중기365"을 이용하려는 자로, 아이디와
              비밀번호의 설정 등 회원가입 절차를 거쳐 회원가입확인 이메일 등을 통해 "회사"로부터
              "회원"으로 인정받은 "회원"을 말한다.
              <br />
              ③"아이디"라 함은 회원가입시 "회원"의 식별과 회원의 서비스 이용을 위하여 "회원"이
              선정한 문자와 숫자의 조합을 말한다.
              <br />④ "비밀번호"라 함은 회원가입시 "회원"의 식별과 회원의 서비스 이용을 위하여
              "회원"이 선정한 문자와 숫자, 특수문자의 조합을 말한다.
              <br />
              <br />
              <strong>제 3 조 ("회사"의 의무)</strong>
              <br />① "회사"는 본 약관에서 정한 바에 따라 계속적, 안정적으로 "중기365"을 제공할 수
              있도록 최선의 노력을 다해야 한다.
              <br />② "회사"는 "중기365"와 관련한 "회원"의 불만사항이 접수되는 경우 이를 즉시
              처리하여야 하며, 즉시 처리가 곤란한 경우에는 그 사유와 처리일정을 웹사이트 또는 기타
              방법을 통해 동 "회원"에게 통지하여야 한다.
              <br />③ "회사"는 유료 결제와 관련한 결제 사항 정보를 1년 이상 보존한다. 다만 회원
              자격이 없는 회원은 예외로 한다.
              <br />④ 천재지변 등 예측하지 못한 일이 발생하거나 시스템의 장애가 발생하여 "중기365"가
              중단될 경우 이에 대한 손해에 대해서는 "회사"가 책임을 지지 않는다. 다만 자료의 복구나
              다만 자료의 복구나 정상적인 서비스 지원이 되도록 최선을 다할 의무를 진다.
              <br />
              <br />
              <strong>제 4조 ("회원"의 의무)</strong>
              <br />① "회원"은 관계법령과 본 약관의 규정 및 기타 "회사"가 통지하는 사항을 준수하여야
              하며, 기타 "회사"의 업무에 방해되는 행위를 해서는 안 된다.
              <br />② "회원"이 결제 수단으로 신용카드를 사용할 경우 비밀번호 등 정보 유실 방지는
              "회원" 스스로 관리해야 한다. 단, 서비스의 결함에 따른 정보유실의 발생에 대한 책임은
              "회원"의 의무에 해당하지 아니한다. ③ "회원"은 "중기365"을 이용하여 얻은 정보를
              "회사"의 사전동의 없이 복사, 복제, 번역, 출판, 방송 기타의 방법으로 사용하거나 이를
              타인에게 제공할 수 없다.
              <br />④ "회원"은 본 서비스를 건전한 구인, 구직 이외의 목적으로 사용해서는 안되며, 이용
              중 다음 각 호의 행위를 해서는 안 된다.
              <br />- 다른 회원의 아이디를 부정 사용하는 행위
              <br />- 범죄행위를 목적으로 하거나 기타 범죄행위와 관련된 행위
              <br />- 타인의 명예를 훼손하거나 모욕하는 행위
              <br />- 타인의 지적재산권 등의 권리를 침해하는 행위 - 해킹행위 또는 바이러스의 유포
              행위
              <br />- 타인의 의사에 반하여 광고성 정보 등 일정한 내용을 계속적으로 전송하는 행위
              <br />- 서비스의 안정적인 운영에 지장을 주거나 줄 우려가 있다고 판단되는 행위 -
              사이트의 정보 및 서비스를 이용한 영리 행위
              <br />- 그밖에 선량한 풍속, 기타 사회질서를 해하거나 관계법령에 위반하는 행위
              <br />⑤ "회원"은 회원정보 기재 내용에 변경이 발생한 경우, 즉시 변경사항을 정정하여
              기재하여야 합니다.
              <br />⑥ "회원은 언제든지 ‘회원탈퇴’ 등을 통해 개인정보의 수집 및 이용 동의를 철회할 수
              있습니다.
              <br />
              <br />
              <strong>제 5 조 (회원 가입)</strong>
              <br />① "회원"이 되고자 하는 자는 "회사"가 정한 가입 양식에 따라 ‘서비스 이용 약관’ 및
              '개인 정보 보호 정책' 등에 동의하고 회원정보를 기입하는 방법으로 회원 가입을
              신청합니다.
              <br />② 제1항 신청에 있어 "회사"는 "회원"의 종류에 따라 전문기관을 통한 실명확인 및
              본인인증을 요청할 수 있으며, "회원"은 본인인증에 필요한 이름, 생년월일, 연락처 등을
              제공하여야 한다.
              <br />③ "회원"이 이용신청(회원가입 신청) 작성 후에 "회사"가 서비스상의 안내 및
              전자메일로 "회원"에게 통지함으로써 이용계약이 성립된다.
              <br />④ "중기365"은 제1항과 같이 회원으로 가입할 것을 신청한 자가 다음 각 호에
              해당하지 않는 한 신청한 자를 회원으로 등록합니다.
              <br />- 실명이 아니거나 타인의 명의를 이용하여 신청한 경우
              <br />- "회사"가 제시하는 내용을 기재하지 않은 경우 - "중기365"은 만20세 이상만
              가입가능
              <br />- 제6조 제2항에 해당하는 회원 자격 제한 및 정지 탈퇴 처리 경험이 있었던 경우
              <br />- 그밖에 회원으로 등록하는 것이 "중기365" 운영 및 기술상에 현저한 지장이 있다고
              있다고 판단되는 경우
              <br />
              <br />
              <strong>제 6 조 (회원 탈퇴 및 자격 상실 등)</strong>
              <br />① "회원"은 "회사"가 운영하는 "중기365" 서비스(웹사이트 및 모바일
              어플리케이션)에서 언제든지 회원 탈퇴를 신청할 수 있으며, 회원 탈퇴가 이루어진 경우
              회원의 게시물과 기타 활동한 기록은 삭제될 수 있습니다.
              <br />② "회원"이 다음 각 호의 사유에 해당하는 경우, "회사"는 "회원"의 회원자격을
              제한하거나 정지 또는 탈퇴시킬 수 있습니다.
              <br />- 가입 신청 시에 허위 내용을 등록한 경우
              <br />- 다른 사람의 서비스 이용을 방해하거나 그 정보를 도용하는 등 이용 질서를
              위협하는 경우
              <br />- 서비스를 이용하여 법령과 본 약관이 금지하거나 공공질서와 미풍양속에 반하는
              행위를 하는 경우
              <br />③ 이용자가 본 약관에 의해서 회원 가입 후 11개월간 로그인 이력이 없는 경우,
              "회사"는 12개월이 되기 30일전 회원에게 휴면회원 전환을 알리고, 이후 로그인 이력이
              없을시 휴면회원으로 전환한다.
              <br />
              <br />
              <strong>제 7 조 ("회원"에 대한 통지)</strong>
              <br />① "회사"는 특정 "회원"에게 서비스에 관한 통지를 하는 경우 회원정보에 등록된
              연락처 또는 이메일을 사용할 수 있습니다.
              <br />② "회사"는 불특정다수 "회원"에 대한 통지를 하는 경우 7일 이상 공지사항 게시판에
              게시함으로써 개별 통지에 갈음할 수 있습니다.
              <br />
              <br />
              <strong>제 8 조 ("회원"의 개인정보)</strong>
              <br />① "회사"는 "중기365" 서비스를 제공하기 위하여 관련 법령의 규정에 따라
              "회원"으로부터 필요한 개인정보를 수집합니다.
              <br />② "회사"는 "회원"이 등록한 게시물, 신청한 게시물 등 관련된 서비스를 원활히
              제공하기 위해 제3자(상호간)에 "회원"의 연락처 및 회원정보 제공을 할 수 있다.
              <br />③ "회사"는 "회원"의 구직활동에 보다 유익한 서비스를 제공하기 위하여 이를 개발,
              편집, 재구성한 통계 자료로 활용 할 수 있다.
              <br />
              <br />
              <strong>제 9 조 ("회원"의 개인정보보호)</strong>
              <br /> "회사"는 "회원"의 개인정보보호를 위하여 노력해야 한다. "회원"의 개인정보보호에
              관해서는 정보통신망이용촉진 및 정보보호 등에 관한 법률, 개인정보보호법에 따르고,
              "중기365" 서비스 (웹 사이트)에 "개인정보처리방침"을 고지한다.
              <br />
              <br />
              <strong>제 10 조 (공개게시물의 삭제 또는 이용제한)</strong>
              <br />① "회원"의 공개게시물의 내용이 다음 각 호에 해당하는 경우 "회사"는 해당
              공개게시물에 대한 접근을 임시적으로 차단하는 조치를 취할 수 있고, 각 호의 동일 사례가
              1회 이상 민원이 발생한 경우 해당 게시물을 삭제 또는 해당 회원의 회원 자격을 제한, 정지
              또는 탈퇴 처리할 수 있습니다.
              <br />- 다른 회원 또는 제3자를 비방하거나 중상모략으로 명예를 손상시키는 내용 -
              음란물, 욕설 등 공공질서와 미풍양속에 위반되는 내용의 정보, 문장, 도형 등을 유포하는
              내용 - 범죄행위와 관련이 있다고 판단되는 내용
              <br />- 다른 회원 또는 제3자의 저작권 등 기타 권리를 침해하는 내용
              <br />- 종교적, 정치적 분쟁을 야기하는 내용으로서, 이러한 분쟁으로 인하여 "회사"의
              업무가 방해되거나 방해되리라고 판단되는 경우 - 타인의 개인정보, 사생활을 침해하거나
              명예를 손상시키는 경우
              <br />- 동일한 내용을 중복하여 다수 게시하는 등 게시의 목적에 어긋나는 경우 -
              불필요하거나 승인되지 않은 광고, 판촉물을 게재하는 경우
              <br />② "회원"의 공개게시물로 인한 법률상 이익 침해를 근거로, 다른 "회원" 또는 제3자가
              "회원" 또는 "회사"를 대상으로 하여 민형사상의 법적 조치(예: 고소, 가처분신청,
              손해배상청구소송)를 취하는 동시에 법적 조치와 관련된 게시물의 삭제를 요청해오는 경우,
              "회사"는 동 법적 조치의 결과(예: 검찰의 기소, 법원의 가처분결정, 손해배상판결)가 있을
              때까지 관련 게시물에 대한 접근을 잠정적으로 제한할 수 있습니다.
              <br />
              <br />
              <strong>제 11 조 (저작권의 귀속 및 게시물의 이용)</strong>
              <br />① "회원"이 서비스 내에 게시한 내용물의 저작권은 해당 저작권자에게 귀속됩니다.
              또한 "회원"이 게시하는 게시물은 관계 법령의 적용을 받으며 게시물에 대한 저작권으로
              인해 법적 분쟁 등이 발생할 경우 그 책임은 "회원"에게 있습니다.
              <br />② "회원"은 "중기365"을 이용하여 얻은 정보를 가공, 판매하는 행위 등 서비스에
              게재된 자료를 상업적으로 사용할 수 없습니다.
              <br />③ "회원"은 자신이 등록한 게시물에 대하여 "회사"가 서비스의 운영, 홍보 등을
              목적으로 다음 각 호에 해당하는 행위를 할 수 있도록 비독점적 사용권을 "회사"에게
              부여하며 그에 대해 사용료 등을 요구하지 않을 것을 동의합니다.
              <br />④ "회사"는 "회원"이 탈퇴한 경우에도 탈퇴한 "회원"의 게시물에 대하여 본 조
              3항에서 명시한 사용권을 유지합니다.
              <br />
              <br />
              <strong>제 12 조 (서비스의 내용 및 변경)</strong>
              <br />① "회사"는 "중기365" 서비스를 제공할 수 있으며 그 내용은 다음 각 호와 같다.
              <br />- 구인 / 구직과 관련된 제반 서비스 - 이용자간의 교류와 소통에 관련한 서비스
              <br />② "회사"는 필요한 경우 서비스의 내용을 추가 또는 변경할 수 있다. 단, 이 경우
              "회사"는 추가 또는 변경내용을 "회원"에게 공지해야 한다.
              <br />
              <br />
              <strong>제 13 조 (서비스의 중단)</strong>
              <br />① "중기365" 서비스 서버 및 정보통신설비의 보수점검·교체 및 고장, 통신의 두절
              등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있으며, 장비 및
              서비스 업그레이드 또는 기타 적절하다고 판단하는 사유에 따라 현재 제공되는 서비스를
              완전히 중단할 수 있습니다.
              <br />② 제1항에 의한 서비스 중단의 경우에 "회원"에게 통지합니다. 다만, "회사"가 통제할
              수 없는 사유로 인한 서비스의 중단(시스템 관리자의 고의, 과실이 없는 디스크 장애,
              시스템 다운 등)으로 인하여 사전 통지가 불가능한 경우에는 그러하지 아니합니다.
              <br />
              <br />
              <strong>제 14 조 (서비스의 요금)</strong>
              <br />① "회원" 가입은 무료이다. 다만 서비스의 웹사이트 및 모바일 어플리케이션의 일부
              서비스를 이용할 경우 유료로 제공될 수 있다.
              <br />② "회사"는 유료서비스를 제공할 경우 사이트에 요금에 대해서 공지를 하여야 한다.
              <br />③ "회사"는 유료서비스 이용금액을 서비스의 종류 및 기간에 따라 "회사"가 예고 없이
              변경할 수 있다. 다만, 변경 이전에 적용 또는 계약한 금액은 소급하여 적용하지 아니한다.
              <br />
              <br />
              <strong>제 15 조 (서비스 요금의 환불)</strong>
              <br />① "회사"는 다음 각 호에 해당하는 경우 이용요금을 환불한다. 단, 각 당사자의
              귀책사유에 따라 환불 조건이 달라질 수 있다.
              <br />- 유료서비스 이용이 개시되지 않은 경우
              <br />- 네트워크 또는 시스템 장애로 서비스 이용이 불가능한 경우
              <br />② "회사"가 "회원"이 가입해지/서비스중지/자료삭제를 취한 경우, "회사"는
              "회원"에게 이용요금을 환불하지 않으며, 별도로 다음과 같은 사항이 해당되는 경우
              "회원"에게 손해배상을 청구할 수 있다.
              <br />
              1. 회원의 의무를 성실하게 이행하지 않았을 때
              <br />
              2. 본 서비스 목적에 맞지 않는 분야에 정보를 활용하여 사회적 물의가 발생한 때
              <br />
              3. 회원이 등록한 정보의 내용이 사실과 다르거나 조작되었을 때
              <br />
              4. 본 서비스와 관련하여 회사 또는 제3자의 명예를 훼손하였을 때
              <br />
              5. 기타 위 각호에 준하는 사유가 발생하였을 때 ③ 이용료를 환불받고자 하는 회원은
              고객센터로 환불을 요청해야 한다.
              <br />④ "회사"는 환불 요건에 부합하는 것으로 판단될 경우, 각 서비스 환불 안내에 따라
              유료이용 계약 당시 상품의 정가 기준으로 서비스 제공된 기간에 해당하는 요금을 차감한
              잔액을 환불하며, 백원단위의 금액은 절삭한다. 단, 결제일의 50%이상 서비스가 제공된 경우
              환불 되지 않는다.
              <br />- 환불 규정은 다음과 같은계산으로 적용된다.
              <br />
              예) 30일 이용권 결제(남은일수 27일)일 경우 50,000원 X 27일/30일 = 45,000원
              <br />
              예) 30일 이용권 결제(남은일수 14일)일 경우 50,000원 X 14일/30일 = 환불되지 않음
              <br />
              <br />
              <strong>제 16 조 (이용요금 오류의 조정)</strong>
              <br /> "회사"는 이용요금과 관련하여 오류가 있는 경우에 "회원"의 요청, 또는 "회사"의
              사전 통지에 의하여 다음에 해당하는 조치를 취한다.
              <br />① 과다납입한 요금에 대하여는 그 금액을 반환한다.
              <br />
              <br />
              <strong>제 17 조 (약관의 개정 및 해석)</strong>
              <br />① "회사"는 이 약관의 내용과 상호, 영업소 소재지, 대표자의 성명, 사업자등록번호,
              연락처 등을 "회원"이 알 수 있도록 초기 화면에 게시하거나 기타의 방법으로 "회원"에게
              공지해야 한다.
              <br />② "회사"는 약관의규제 등에 관한법률, 전자거래기본법, 전자서명법,
              정보통신망이용촉진 등에 관한법률 등 관련법을 위배하지 않는 범위에서 본 약관을 개정할
              수 있습니다.
              <br />③ 다만, 개정 내용이 "회원"에게 불리할 경우에는 적용일자 15일 이전부터 적용일자
              전일까지 공지합니다.
              <br />④ "회원"은 변경된 약관에 대해 거부할 권리가 있습니다. "회원"은 변경된 약관이
              공지된 후 15일 이내에 거부의사를 표명할 수 있습니다. 회원이 거부하는 경우 "회사"는
              당해 회원과의 계약을 해지할 수 있습니다. 만약 "회원"이 변경된 약관이 공지된 후 15일
              이내에 거부의사를 표시하지 않는 경우에는 동의하는 것으로 간주합니다.
              <br />⑤ 이 약관에서 규정하지 않은 사항에 관해서는 약관의 규제에 관한 법률,
              전기통신기본법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등의
              관계법령에 따른다.
              <br />
              <br />
              <strong>제 18 조 (재판관할)</strong>
              <br />
              "회사"와 "회원" 간에 발생한 서비스 이용에 관한 분쟁에 대하여는 대한민국 법을 적용하며,
              본 분쟁으로 인한 소는 민사소송법상의 관할을 가지는 대한민국의 법원에 제기합니다.
              <br />
              <br />
              공고일자 : 2022.11.01
              <br />
              시행일자 : 2022.11.01
              <br />
              <br />
              <br />
              <br />
            </Typography>
          </Stack>
        </div>
      </Box>
    </Page>
  );
}
