import { styled } from '@mui/material/styles';
import React, { useState, useParams } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Container, Link } from '@mui/material';
import AuthLayout from '../../layouts/AuthLayout';
// components
import {
  ConstructionDetailFirstCell,
  ConstructionDetailSecondCell,
  ConstructionDetailThirdCell
} from '.';
import Page from '../../components/Page';
// import SearchNotFound from '../../components/SearchNotFound';
// import { ConstructionSearch, ConstructionDatePicker, ConstructionListHead } from '.';
// import USERLIST from '../_mocks_/user';
// ----------------------------------------------------------------------
const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    justifyContent: 'space-around',
    display: 'flex'
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 600,
  // marginLeft: '35%',
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(10, 0)
}));

const ContentStyle2 = styled('div')(({ theme }) => ({
  // maxWidth: 600,
  // marginright: '35%',
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  padding: theme.spacing(20, 0)
}));

ConstructionDetail.propTypes = {
  cntrwkmNo: PropTypes.string,
  cntrwkmDailyNo: PropTypes.string,
  fairNo: PropTypes.string
};

// ----------------------------------------------------------------------

export default function ConstructionDetail({ cntrwkmNo, cntrwkmDailyNo, fairNo }) {
  console.log(cntrwkmNo);
  console.log(cntrwkmDailyNo);
  console.log(fairNo);
  return (
    <RootStyle title="공사관리 상세/수정">
      <AuthLayout>
        <Link underline="none" variant="subtitle2" component={RouterLink} to="/">
          홈으로
        </Link>
      </AuthLayout>
      <Container spacing={1}>
        <ContentStyle>
          <ConstructionDetailFirstCell />
        </ContentStyle>
      </Container>
      <Container>
        <ContentStyle2>
          <ConstructionDetailSecondCell />
          <ConstructionDetailThirdCell />
        </ContentStyle2>
      </Container>
    </RootStyle>
  );
}
