import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

// material
import { Stack, Button, Typography, Select, MenuItem } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
// components
import { DataGrid } from '@mui/x-data-grid';
import { LocalizationProvider, DatePicker } from '@mui/lab';
import { ko } from 'date-fns/locale';

import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import NumberFormat from 'react-number-format';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Box from '@mui/material/Box';
import RefreshIcon from '@mui/icons-material/Refresh'; // RoomIcon
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Page from '../components/Page';
import { AppInOutComeTotal } from '../sections/@dashboard/app';

// import { concat } from 'lodash';

const BASE_URL = process.env.REACT_APP_API_URL;

export default function SettlementOfExpenses() {
  // 글자수제한
  // const EQUIPMENT_LIMIT = 10;
  const PYMNT_MTHD_LIMIT = 10;
  const AMOUNT_LIMIT = 10;
  // const [state, setState] = useState<GridRowModel[]>(initialData);
  // 날짜 셋팅
  const todayMonth = new Date();
  const year = todayMonth.getFullYear();
  const month = todayMonth.getMonth() + 1;
  const day = todayMonth.getDate();
  const nowMonth = month < 10 ? `0${month}` : `${month}`;
  const nowday = day < 10 ? `0${day}` : `${day}`;

  // const [selectedRows, setSelectedRows] = useState([]);
  const [mySettlementList, setMySettlementList] = useState([]);
  const [accumulationIncome, setAccumulationIncome] = useState();
  const [accumulationOutcome, setAccumulationOutcome] = useState();
  const [accumulationVatIncome, setAccumulationVatIncome] = useState();
  const [accumulationVatOutcome, setAccumulationVatOutcome] = useState();
  const [total, setTotal] = useState();
  const [InComevatTotal, setInComeVatTotal] = useState();
  const [OutComevatTotal, setOutComeVatTotal] = useState();
  const [outComeTotal, setOutComeTotal] = useState();
  const [inComeTotal, setInComeTotal] = useState();
  const [excclcKndyInCome, setExcclcKndyInCome] = useState();
  const [excclcKndyOutCome, setExcclcKndyOutCome] = useState();
  const [excclcKndnInCome, setExcclcKndnInCome] = useState();
  const [excclcKndnOutCome, setExcclcKndnOutCome] = useState();
  // const [sendData, setSendData] = useState([]);
  // 검색 parameter
  const [searchEmployer, setSearchEmployer] = useState();
  const [searchInoutCome, setSearchInoutCome] = useState('ALL');
  const [searchInoutComeYn, setSearchInoutComeYn] = useState('ALL');
  // const [searchMonth1, setSearchMonth1] = useState(`${year}-${nowMonth}`);
  const [searchMonth1, setSearchMonth1] = useState(todayMonth);
  // Dialog parameter
  const [newRegDialogOpen, setNewRegDialogOpen] = useState(false);
  const [modiDialogOpen, setModiDialogOpen] = useState(false);

  //
  const [selectionModel, setSelectionModel] = useState([]);

  // 등록 Parameter
  const [workStartDate, setWorkStartDate] = useState(`${year}-${nowMonth}-${nowday}`); // 작업시작일
  const [workEndtDate, setWorkEndDate] = useState(); // 작업종료일
  const [workContents, setWorkContents] = useState(); // 작업내용
  const [workPrice, setWorkPrice] = useState(); // 작업금액
  const [workPriceShow, setWorkPriceShow] = useState(); // 화면에 보여지는 값 작업금액
  const [howtoPay, setHowtoPay] = useState(); // 지불방법
  const [memo, setMemo] = useState(); // 메모
  const [soeMemo, setSoeMemo] = useState(); // 정산메모
  const [employer, setEmployer] = useState(); // 거래처
  const [inoutCome, setInoutCome] = useState('매출'); // 정산구분
  const [inoutComeYn, setInoutComeYn] = useState('N'); // 정산여부
  const [taxAmount, setTaxAmount] = useState(''); // 부가세
  const [taxAmountShow, setTaxAmountShow] = useState(''); // 화면에 보여지는 값 부가세
  const [taxYn, setTaxYn] = useState('N'); // 세금계산서 발행
  const [taxShow, setTaxShow] = useState(false);
  // const [emergencyCheck, setEmergencyCheck] = useState(false);
  // const [mustReadCheck, setMustReadCheck] = useState(false);
  // const [normalCheck, setNormalCheck] = useState(true);

  // 상세내용 parameter
  const [modiRcvPayNo, setModiRcvPayNo] = useState();
  const [modiInoutCome, setModiInoutCome] = useState();
  const [modiInoutComeYn, setModiInoutComeYn] = useState();
  const [modiTaxYn, setModiTaxYn] = useState();
  const [modiTaxAmount, setModiTaxAmount] = useState();
  const [modiTaxAmountShow, setModiTaxAmountShow] = useState();

  const [modiWorkCn, setModiWorkCn] = useState();
  const [modiWorkAmount, setModiWorkAmount] = useState();
  const [modiWorkAmountShow, setModiWorkAmountShow] = useState();
  const [modiPymntMthd, setModiPymntMthd] = useState();
  const [modiWorkBegin, setModiWorkBegin] = useState();
  const [modiWorkEndde, setModiWorkEndde] = useState();

  const [modiWorkMemo, setModiWorkMemo] = useState();

  const [modiWorkOrnt, setModiWorkOrnt] = useState();
  const [modiSoeMemo, setModiSoeMemo] = useState();
  const [modiTaxShow, setModiTaxShow] = useState(false);

  const refTaxYn = useRef();
  const refModiTaxYn = useRef();
  // 재등록시 변경된 값과 비교
  const [modiTaxAmountChange, setModiTaxAmountChange] = useState();
  const [modiWorkAmountChange, setModiWorkAmountChange] = useState(); // 작업금액
  const [modiInoutComeChange, setModiInoutComeChange] = useState(); // 정산구분
  const [modiInoutComeYnChange, setModiInoutComeYnChange] = useState(); // 정산여부
  const [modiTaxYnChange, setModiTaxYnChange] = useState(); // 세금계산서 발행여부
  const [modiWorkOrntChange, setModiWorkOrntChange] = useState(); // 거래처
  const [modiWorkCnChange, setModiWorkCnChange] = useState(); // 작업내용
  const [modiPymntMthdChange, setModiPymntMthdChange] = useState(); // 지불방법
  const [modiWorkBeginChange, setModiWorkBeginChange] = useState(); // 작업시작일
  const [modiWorkEnddeChange, setModiWorkEnddeChange] = useState(); // 작업종료일
  const [modiWorkMemoChange, setModiWorkMemoChange] = useState(); // 작업메모
  const [modiSoeMemoChange, setModiSoeMemoChange] = useState(); // 정산메모

  // const [modiWorkTypeChanged, setModiWorkTypeChanged] = useState();
  // const [modiWorkBeginChanged, setModiWorkBeginChanged] = useState();
  // const [modiWorkEnddeChanged, setModiWorkEnddeChanged] = useState();
  // const [modiBeginTimeChanged, setModiBeginTimeChanged] = useState();
  // const [modiEndTimeChanged, setModiEndTimeChanged] = useState();
  // const [modiWorkMemoChanged, setModiWorkMemoChanged] = useState();
  const navigate = useNavigate();
  // -------------------------------------------DataGrid Header----------------------------------------------
  const MY_SETTLEMENT_TABLE_HEAD = [
    { field: 'workOrnt', headerName: '거래처', alignRight: false, width: 120 },
    {
      field: 'workBegin',
      headerName: '작업일',
      alignRight: false,
      width: 170,
      valueGetter: (params) => `${params.row.workBegin} ~ ${params.row.workEndde}`
    },
    {
      field: 'excclcKnd',
      headerName: '정산구분',
      alignRight: false,
      width: 80
    },
    {
      field: 'excclcYn',
      headerName: '정산여부',
      // type: 'boolean',
      alignRight: false,
      width: 80,
      // valueGetter: (params) => makeExcclcYn(params)
      renderCell: (params) =>
        params.row.excclcYn === 'Y' ? <CheckIcon color="success" /> : <CloseIcon color="error" />
    },
    {
      field: 'vatYn',
      headerName: '계산서발행',
      type: 'boolean',
      alignRight: false,
      width: 100,
      // valueGetter: (params) => makeVatYn(params)
      renderCell: (params) =>
        params.row.vatYn === 'Y' ? <CheckIcon color="success" /> : <CloseIcon color="error" />
    },
    {
      field: 'workAmount',
      headerName: '단가(금액)',
      type: 'number',
      alignRight: false,
      width: 100
    },
    {
      field: 'vatAmount',
      headerName: '부가세',
      type: 'number',
      alignRight: false,
      width: 120,
      valueGetter: (params) => makeVatData(params)
    },
    {
      field: 'amountTotal',
      headerName: '합계',
      type: 'number',
      alignRight: false,
      width: 100
    },
    { field: 'pymntMthd', headerName: '지불방식', alignRight: false, width: 120 },
    { field: 'eqpmnNm', headerName: '장비명', alignRight: false, width: 150, hide: true },
    {
      field: 'workEndde',
      headerName: '작업종료일',
      alignRight: false,
      width: 120,
      hide: true
      // valueGetter: (params) => makeDate(params.row)
    },
    // { field: 'workAdres', headerName: '작업지역', alignRight: false, width: 200 },
    // { field: 'workEndde', headerName: '작업종료일', alignRight: false },
    { field: 'workCn', headerName: '작업내용', alignRight: false, width: 200 },
    {
      field: 'rcvpayRm',
      headerName: '정산메모',
      alignRight: false,
      width: 220
    },
    { field: 'workMemo', headerName: '메모', alignRight: false, width: 220 },
    { field: 'regUser', headerName: '등록자', alignRight: false, width: 100, hide: true },
    { field: 'rcvpayNo', headerName: 'rcvpayNo', align: 'center', hide: true }
  ];
  // -----------------------------------------------API 호출-----------------------------------------------------
  // 목록 API 호출
  const myWorkHistoryGetData = async () => {
    const apiUrl = `${BASE_URL}/rcvpaymanage/getRcvpayManageList`;
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    };
    const sendParams = {
      workOrnt: searchEmployer,
      excclcKnd: searchInoutCome,
      excclcYn: searchInoutComeYn,
      workBegin: searchMonth1
      // workBegin: searchMonth2
    };
    axios
      // .post(apiUrl, sendParams, headers)
      .post(apiUrl, sendParams, headers)
      .then((response) => {
        setMySettlementList(response.data.rcvpayManageList);
        setAccumulationIncome(inputPriceFormat(response.data.incomeAmountTotal));
        setAccumulationOutcome(inputPriceFormat(response.data.outcomeAmountTotal));
        setAccumulationVatIncome(inputPriceFormat(response.data.incomeVatTotal));
        setAccumulationVatOutcome(inputPriceFormat(response.data.outcomeVatTotal));
        CalculateAmount(response.data.rcvpayManageList); // 총 합
        // CalculateTaxAmount(response.data.rcvpayManageList); // 부가세 합
        CalculateInComeAmount(response.data.rcvpayManageList); // 매출 합
        CalculateOutComeAmount(response.data.rcvpayManageList); // 매입 합
        CalculateExcclcKndNAmountInCome(response.data.rcvpayManageList); // 미정산 (매출)
        CalculateExcclcKndNAmountOutCome(response.data.rcvpayManageList); // 미정산 (매입)
        CalculateExcclcKndYAmountInCome(response.data.rcvpayManageList); // 정산 (매입)
        CalculateExcclcKndYAmountOutCome(response.data.rcvpayManageList); // 정산 (매입)
        CalculateMonthVatAmountInCome(response.data.rcvpayManageList); // 부가세 (매출)
        CalculateMonthVatAmountOutCome(response.data.rcvpayManageList); // 부가세 (매입)
        // CalculateExcclcKndYAmount(response.data.rcvpayManageList); // 정산금액
        // CalculateExcclcKndNAmount(response.data.rcvpayManageList); // 미정산금액
        // CalculateMonthVatAmount(response.data.rcvpayManageList); // 부가세
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const saveData = async () => {
    const apiUrl = `${BASE_URL}/rcvpaymanage/regRcvpayManage`;
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    };
    const sendParams = {
      excclcKnd: inoutCome, // 정산구분
      excclcYn: inoutComeYn, // 정산여부
      vatYn: taxYn, // 세금계산서
      vatAmount: taxAmount, // 부가세
      workOrnt: employer, // 거래처
      workCn: workContents, // 작업내용
      workAmount: workPrice, // 작업금액
      pymntMthd: howtoPay, // 지불방식
      workBegin: workStartDate, // 작업시작일
      workEndde: workEndtDate, // 작업종료일
      workMemo: memo, // 메모
      rcvpayRm: soeMemo, // 정산메모
      regUser: sessionStorage.getItem('id')
    };
    axios
      // .post(apiUrl, sendParams, headers)
      .post(apiUrl, sendParams, headers)
      .then((response) => {
        // console.log(response.data);
        if (response.data.code === '200') {
          window.alert('저장되었습니다');
          myWorkHistoryGetData();
          handleRegDialogClose();
        } else if (response.data.cose === '500') {
          window.alert('등록에 실패하였습니다');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // update
  const updateData = async () => {
    const apiUrl = `${BASE_URL}/rcvpaymanage/updRcvpayManage`;
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    };
    const sendParams = {
      rcvpayNo: modiRcvPayNo,
      excclcKnd: modiInoutCome,
      excclcYn: modiInoutComeYn,
      vatYn: modiTaxYn,
      vatAmount: modiTaxAmount,
      workOrnt: modiWorkOrnt,
      workCn: modiWorkCn,
      workAmount: modiWorkAmount,
      pymntMthd: modiPymntMthd,
      workBegin: modiWorkBegin,
      workEndde: modiWorkEndde,
      workMemo: modiWorkMemo,
      rcvpayRm: modiSoeMemo,
      delYn: 'N'
    };
    axios
      // .post(apiUrl, sendParams, headers)
      .post(apiUrl, sendParams, headers)
      .then((response) => {
        // console.log(response.data);
        if (response.data.code === '200') {
          window.alert('수정되었습니다');
          setModiRcvPayNo();
          delSelection();
          myWorkHistoryGetData();
          handleModiDialogClose();
        } else if (response.data.cose === '500') {
          window.alert('수정에 실패하였습니다');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // update
  const deleteData = async () => {
    const apiUrl = `${BASE_URL}/rcvpaymanage/updRcvpayManage`;
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    };
    const sendParams = {
      rcvpayNo: modiRcvPayNo,
      delYn: 'Y'
    };
    axios
      // .post(apiUrl, sendParams, headers)
      .post(apiUrl, sendParams, headers)
      .then((response) => {
        console.log(response.data);
        if (response.data.code === '200') {
          window.alert('삭제되었습니다');
          myWorkHistoryGetData();
          handleModiDialogClose();
        } else if (response.data.cose === '500') {
          window.alert('삭제에 실패하였습니다');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // ----------------------------------------------------------------------------------------------------------
  // selection 해제
  const delSelection = () => {
    setSelectionModel([]);
  };

  // ------------------------------------------------------------------------------------------------------------
  // 검색 조건 이벤트 ( tab1 )
  const onClickSearch = () => {
    myWorkHistoryGetData();
  };
  const onChangeSearchEmployer = (e) => {
    setSearchEmployer(e.target.value);
  };
  const onChangeSearchInoutCome = (e) => {
    setSearchInoutCome(e.target.value);
  };
  const onChangeSearchInoutComeYn = (e) => {
    setSearchInoutComeYn(e.target.value);
    // WorkListGetData();
  };
  // ------------------------------------------------------------------------------------------------------------
  // const handleAppendData = (params) => {
  //   // const sendData = push(params);
  //   // const result = sendData.concat(params);
  //   console.log(params);
  //   // console.log(result);
  // };
  // ------------------------------------------------------------------------------------------------------------
  const handleRegDialogOpen = () => {
    setNewRegDialogOpen(true);
  };
  const handleRegDialogClose = () => {
    setNewRegDialogOpen(false);
    setInoutCome(''); // 정산구분
    setInoutComeYn('N'); // 정산여부
    setTaxAmount(0); // 부가세
    setTaxAmountShow(); // 부가세
    setTaxYn('N'); // 세금계산서 발행
    setWorkStartDate(`${year}-${nowMonth}-${nowday}`); // 작업시작일
    setWorkEndDate(); // 작업종료일
    setWorkContents(); // 작업내용
    setWorkPrice(); // 작업금액
    setWorkPriceShow(); // 작업금액
    setHowtoPay(); // 지불방법
    setMemo(); // 메모
    setSoeMemo(); // 정산메모
    setEmployer(); // 거래처
    setTaxShow(false); // 부가세
  };
  const handleModiDialogOpen = () => {
    if (!modiRcvPayNo) {
      window.alert('수정할 내용을 선택해주세요!');
    } else {
      setModiDialogOpen(true);
      if (modiTaxYn === 'Y') {
        setModiTaxAmount(modiTaxAmountChange);
        setModiTaxShow(true);
      } else {
        setModiTaxAmount(0);
        setModiTaxShow(false);
      }
    }
  };
  const handleModiDialogClose = () => {
    const inoutComeChange = modiInoutComeChange;
    const inoutComeYnChange = modiInoutComeYnChange;
    const taxYnChange = modiTaxYnChange;
    const workAmountChange = modiWorkAmountChange;
    const workOrntChange = modiWorkOrntChange;
    const workCnChange = modiWorkCnChange;
    const workStartDateChange = modiWorkBeginChange;
    const workEndDateChange = modiWorkEnddeChange;
    const workMemoChange = modiWorkMemoChange;
    const soeMemoChange = modiSoeMemoChange;
    const howtoPayChange = modiPymntMthdChange;
    const vatAmountChange = modiTaxAmountChange;

    if (modiInoutCome !== inoutComeChange) {
      setModiInoutCome(inoutComeChange);
    }
    if (modiInoutComeYn !== inoutComeYnChange) {
      setModiInoutComeYn(inoutComeYnChange);
    }
    if (modiTaxYn !== taxYnChange) {
      setModiTaxYn(taxYnChange);
    }
    if (modiWorkAmountShow !== workAmountChange) {
      setModiWorkAmountShow(workAmountChange);
    }
    if (modiWorkOrnt !== workOrntChange) {
      setModiWorkOrnt(workOrntChange);
    }
    if (modiWorkCn !== workCnChange) {
      setModiWorkCn(workCnChange);
    }
    if (modiWorkBegin !== workStartDateChange) {
      setModiWorkBegin(workStartDateChange);
    }
    if (modiWorkEndde !== workEndDateChange) {
      setModiWorkEndde(workEndDateChange);
    }
    if (modiWorkMemo !== workMemoChange) {
      setModiWorkMemo(workMemoChange);
    }
    if (modiSoeMemo !== soeMemoChange) {
      setModiSoeMemo(soeMemoChange);
    }
    if (modiPymntMthd !== howtoPayChange) {
      setModiPymntMthd(howtoPayChange);
    }
    if (modiTaxAmount) {
      if (modiTaxAmount !== vatAmountChange) {
        setModiTaxAmount(vatAmountChange);
        setModiTaxAmountShow(vatAmountChange);
      }
    }
    setModiDialogOpen(false);
  };
  // -------------------------------------------------등록-----------------------------------------------------------
  // 거래처
  const onChangeEmployer = useCallback((e) => {
    setEmployer(e.target.value);
  }, []);
  // 정산구분
  const onChangeInoutCome = useCallback((e) => {
    setInoutCome(e.target.value);
  }, []);
  // 정산여부
  const onChangeInoutComeYn = useCallback((e) => {
    setInoutComeYn(e.target.value);
    // WorkListGetData();
    // console.log(inoutComeYn);
  }, []);
  // 세금계산서 발행
  const onChangeTaxYn = (e) => {
    // setTaxAmountShow();
    setTaxYn(e.target.value);
    // console.log(`taxYn1 = ${taxYn}`);
    // console.log(e.target.value);
    // const test = e.target.value;
    // setTaxYn(test);
    // console.log(`taxYn2 = ${taxYn}`);
    // taxYn = e.target.value;
    // if (e.target.value === 'Y') {
    //   setTaxShow(true);
    //   if (workPrice) {
    //     const newValue = workPrice;
    //     const result = CalculateTax(workPrice);
    //     setWorkPriceShow(inputPriceFormat(newValue));
    //     setTaxAmount(result.replaceAll(',', ''));
    //   }
    // } else {
    //   setTaxShow(false);
    //   setTaxAmount(0);
    //   setTaxAmountShow(0);
    // }
    // console.log(e.target.value);
    // console.log(taxYn);
    console.log(e.target.value);
    if (e.target.value === 'Y') {
      setTaxShow(true);
    } else {
      setTaxShow(false);
    }
    setTaxYn(e.target.value);
  };
  // 작업금액
  const onChangeWorkPrice = (e) => {
    // const newValue = e.target.value;
    // const result = CalculateTax(e.target.value);
    // setWorkPrice(newValue.replaceAll(',', ''));
    // setWorkPriceShow(inputPriceFormat(newValue));
    // setTaxAmountShow(inputPriceFormat(result));
    // if (refTaxYn.current.innerText === 'N') {
    //   setTaxAmount(0);
    // } else {
    //   setTaxAmount(result.replaceAll(',', ''));
    // }
    const newValue = e.target.value.replaceAll(',', '');
    if (refTaxYn.current.innerText === 'N') {
      setTaxAmount(0);
      setTaxAmountShow(CalculateTax(newValue));
    } else {
      setTaxAmount(CalculateTax(newValue));
      setTaxAmountShow(CalculateTax(newValue));
    }
    setWorkPrice(newValue);
    setWorkPriceShow(e.target.value);
  };
  // const onChangeTaxAmount = useCallback((e) => {
  //   setTaxAmount(inputPriceFormat(e.target.value));
  // }, []);
  // 지불방법
  const onChangeHowtoPay = useCallback((e) => {
    setHowtoPay(e.target.value);
  }, []);
  // 작업시작일
  const onChangeWorkStartDate = useCallback((e) => {
    setWorkStartDate(e.target.value);
  }, []);
  // 작업종료일
  const onChangeWorkEndDate = useCallback((e) => {
    setWorkEndDate(e.target.value);
  }, []);
  // 작업내용
  const onChangeWorkContents = useCallback((e) => {
    setWorkContents(e.target.value);
  }, []);

  // 메모
  const onChangeMemo = (e) => {
    setMemo(e.target.value);
  };
  // 정산메모
  const onChangeSoeMemo = (e) => {
    setSoeMemo(e.target.value);
  };
  // const handleNormalCheck = (e) => {
  //   setNormalCheck(e.target.checked);
  //   setEmergencyCheck(false);
  //   setMustReadCheck(false);
  // };
  // const handleEmergencyCheck = (e) => {
  //   setNormalCheck(false);
  //   setEmergencyCheck(e.target.checked);
  //   setMustReadCheck(false);
  // };
  // const handleMustReadCheck = (e) => {
  //   setNormalCheck(false);
  //   setEmergencyCheck(false);
  //   setMustReadCheck(e.target.checked);
  // };
  // 금액에 ',' 추가
  const inputPriceFormat = (str) => {
    // console.log('s', str);
    const comma = (str) => {
      str = String(str);
      return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
    };
    const uncomma = (str) => {
      str = String(str);
      return str.replace(/[^\d]+/g, '');
    };
    return comma(uncomma(str));
  };
  // --------------------------------------------계산식----------------------------------------------
  // 부가세 계산
  const CalculateTax = (newValue) => {
    // console.log(`newValue : ${newValue}`);
    const noComa = newValue;
    // console.log(`noComa : ${noComa}`);
    const result = Math.floor(parseInt(noComa, 10) / 10);
    console.log(`result : ${result}`);
    return result;
  };
  // 내용수정 부가세 계산
  const ModiCalculateTax = useCallback((newValue) => {
    const result = (parseInt(newValue, 10) / 10).toFixed(2);
    return Math.floor(result);
  }, []);
  // 합계 금액
  const CalculateAmount = useCallback((listData) => {
    let sum = 0;
    if (Array.isArray(listData)) {
      listData.forEach((mySettlementList) => {
        sum += mySettlementList.amountTotal;
      });
    }
    return setTotal(inputPriceFormat(sum));
    // 프로퍼티가 객체인 경우 - 중첩객체
  }, []);
  // 부가세 합계 금액
  // const CalculateTaxAmount = useCallback((listData) => {
  //   if (Array.isArray(listData)) {
  //     let sum = 0;
  //     listData.forEach((mySettlementList) => {
  //       sum += mySettlementList.vatAmount;
  //     });
  //     return setVatTotal(inputPriceFormat(sum));
  //   }
  //   // 프로퍼티가 객체인 경우 - 중첩객체
  // }, []);
  // 매출 금액 합계
  const CalculateInComeAmount = useCallback((listData) => {
    let sum = 0;
    if (Array.isArray(listData)) {
      listData.forEach((mySettlementList) => {
        if (mySettlementList.excclcKnd === '매출') {
          sum += mySettlementList.workAmount;
        }
      });
    }
    return setInComeTotal(inputPriceFormat(sum));
    // 프로퍼티가 객체인 경우 - 중첩객체
  }, []);
  // 매입 금액 합계
  const CalculateOutComeAmount = useCallback((listData) => {
    let sum = 0;
    if (Array.isArray(listData)) {
      listData.forEach((mySettlementList) => {
        if (mySettlementList.excclcKnd === '매입') {
          sum += mySettlementList.workAmount;
        }
      });
    }
    return setOutComeTotal(inputPriceFormat(sum));
    // 프로퍼티가 객체인 경우 - 중첩객체
  }, []);
  // 정산금액 ( 매출 )
  const CalculateExcclcKndYAmountInCome = useCallback((listData) => {
    let sum = 0;
    if (Array.isArray(listData)) {
      listData.forEach((mySettlementList) => {
        if (mySettlementList.excclcYn === 'Y' && mySettlementList.excclcKnd === '매출') {
          sum += mySettlementList.workAmount;
        }
      });
    }
    return setExcclcKndyInCome(inputPriceFormat(sum));
    // 프로퍼티가 객체인 경우 - 중첩객체
  }, []);
  // 정산금액 ( 매입 )
  const CalculateExcclcKndYAmountOutCome = useCallback((listData) => {
    let sum = 0;
    if (Array.isArray(listData)) {
      listData.forEach((mySettlementList) => {
        if (mySettlementList.excclcYn === 'Y' && mySettlementList.excclcKnd === '매입') {
          sum += mySettlementList.workAmount;
        }
      });
    }
    return setExcclcKndyOutCome(inputPriceFormat(sum));
    // 프로퍼티가 객체인 경우 - 중첩객체
  }, []);
  // 미정산금액 ( 매출 )
  const CalculateExcclcKndNAmountInCome = useCallback((listData) => {
    let sum = 0;
    if (Array.isArray(listData)) {
      listData.forEach((mySettlementList) => {
        if (mySettlementList.excclcYn === 'N' && mySettlementList.excclcKnd === '매출') {
          sum += mySettlementList.workAmount;
        }
      });
    }
    return setExcclcKndnInCome(inputPriceFormat(sum));
    // 프로퍼티가 객체인 경우 - 중첩객체
  }, []);
  // 미정산금액 ( 매입 )
  const CalculateExcclcKndNAmountOutCome = useCallback((listData) => {
    let sum = 0;
    if (Array.isArray(listData)) {
      listData.forEach((mySettlementList) => {
        if (mySettlementList.excclcYn === 'N' && mySettlementList.excclcKnd === '매입') {
          sum += mySettlementList.workAmount;
        }
      });
    }
    return setExcclcKndnOutCome(inputPriceFormat(sum));
    // 프로퍼티가 객체인 경우 - 중첩객체
  }, []);
  // 부가세 합계 ( 매출 )
  const CalculateMonthVatAmountInCome = useCallback((listData) => {
    let sum = 0;
    if (Array.isArray(listData)) {
      listData.forEach((mySettlementList) => {
        if (mySettlementList.vatYn === 'Y' && mySettlementList.excclcKnd === '매출') {
          sum += mySettlementList.vatAmount;
        }
      });
    }
    return setInComeVatTotal(inputPriceFormat(sum));
    // 프로퍼티가 객체인 경우 - 중첩객체
  }, []);
  // 부가세 합계 ( 매입 )
  const CalculateMonthVatAmountOutCome = useCallback((listData) => {
    let sum = 0;
    if (Array.isArray(listData)) {
      listData.forEach((mySettlementList) => {
        if (mySettlementList.vatYn === 'Y' && mySettlementList.excclcKnd === '매입') {
          sum += mySettlementList.vatAmount;
        }
      });
    }
    return setOutComeVatTotal(inputPriceFormat(sum));
    // 프로퍼티가 객체인 경우 - 중첩객체
  }, []);
  // 부가세 null 값일 경우 0 처리
  const makeVatData = useCallback((params) => {
    // console.log(params.value);
    if (params.value === null) {
      return 0;
    }
    return params.value;
  }, []);
  // ------------------------------------------------수정--------------------------------------------------------
  // 정산구분
  const onChangeModiInoutCome = (e) => {
    setModiInoutCome(e.target.value);
  };
  // 정산여부
  const onChangeModiInoutComeYn = (e) => {
    setModiInoutComeYn(e.target.value);
  };
  // 세금계산서 발행여부
  const onChangeModiTaxYn = (e) => {
    console.log(e.target.value);
    if (e.target.value === 'Y') {
      setModiTaxAmount(modiTaxAmountChange);
      setModiTaxShow(true);
    } else {
      setModiTaxAmount(0);
      setModiTaxShow(false);
    }
    setModiTaxYn(e.target.value);
  };
  // 정산여부
  // const onChangeModiTaxAmountShow = (e) => {
  //   // console.log(e.target.value);
  //   setModiTaxAmount(e.target.value);
  //   setModiTaxAmountShow(e.target.value);
  // };
  // 정산 메모
  const onChangeModiSoeMemo = (e) => {
    setModiSoeMemo(e.target.value);
  };
  // 작업내용
  const onChangeModiWorkCn = (e) => {
    setModiWorkCn(e.target.value);
  };
  // 지불방법
  const onChangeModiPymntMthd = (e) => {
    setModiPymntMthd(e.target.value);
  };
  // 작업시작일
  const onChangeModiWorkBegin = (e) => {
    setModiWorkBegin(e.target.value);
  };
  // 작업종료일
  const onChangeModiWorkEndde = (e) => {
    setModiWorkEndde(e.target.value);
  };
  // 작업금액
  const onChangeModiWorkAmount = (e) => {
    const newValue = e.target.value.replaceAll(',', '');
    if (refModiTaxYn.current.innerText === 'N') {
      setModiTaxAmount(0);
      setModiTaxAmountShow(CalculateTax(newValue));
    } else {
      setModiTaxAmount(CalculateTax(newValue));
      setModiTaxAmountShow(CalculateTax(newValue));
    }
    setModiWorkAmount(newValue);
    setModiWorkAmountShow(e.target.value);
  };
  const onChangeModiWorkMemo = (e) => {
    setModiWorkMemo(e.target.value);
  };
  // 거래처
  const onChangeModiWorkOrnt = (e) => {
    setModiWorkOrnt(e.target.value);
  };
  // ------------------------------------------------------------------------------------------------------------
  const onRowClick = (params) => {
    console.log(params);
    const price = params.workAmount;
    const amount = ModiCalculateTax(params.workAmount);
    setModiTaxAmount(amount);
    setModiTaxAmountShow(amount); // 부가세
    setModiTaxAmountChange(amount); // 부가세(비교값)
    setModiRcvPayNo(params.rcvpayNo);
    if (params.excclcKnd !== undefined && params.excclcKnd !== null) {
      setModiInoutCome(params.excclcKnd); // 정산구분
      setModiInoutComeChange(params.excclcKnd); // 정산구분
    } else {
      setModiInoutCome(''); // 정산구분
      setModiInoutComeChange(''); // 정산구분
    }
    setModiInoutComeYn(params.excclcYn); // 정산여부
    setModiTaxYn(params.vatYn); // 세금계산서 발행
    setModiWorkCn(params.workCn); // 작업내용
    setModiWorkAmount(price); // 작업금액
    // setModiWorkAmountShow(price.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')); // 작업금액
    setModiWorkAmountShow(price);
    setModiPymntMthd(params.pymntMthd); // 지불방식
    setModiWorkBegin(params.workBegin); // 작업시작일
    setModiWorkEndde(params.workEndde); // 작업종료일
    setModiWorkMemo(params.workMemo); // 작업메모
    setModiSoeMemo(params.rcvpayRm); // 정산메모
    setModiWorkOrnt(params.workOrnt);
    // 닫기 버튼 클릭시 비교값 설정
    setModiWorkAmountChange(price); // 작업금액
    setModiInoutComeYnChange(params.excclcYn); // 정산여부
    setModiTaxYnChange(params.vatYn); // 세금계산서 발행
    setModiWorkOrntChange(params.workOrnt); // 거래처
    setModiWorkCnChange(params.workCn); // 작업내용
    setModiPymntMthdChange(params.pymntMthd); // 지불방법
    setModiWorkBeginChange(params.workBegin); // 작업시작일
    setModiWorkEnddeChange(params.workEndde); // 작업종료일
    setModiWorkMemoChange(params.workMemo); // 작업메모
    setModiSoeMemoChange(params.rcvpayRm); // 정산메모
  };
  // ------------------------------------------------------------------------------------------------------------
  const regValidationCheck = () => {
    // const today = `${year}-${nowMonth}-${nowday}`;
    // if (!inoutCome || inoutCome === '' || inoutCome === '선택') {
    //   window.alert('정산구분을 선택해주세요!');
    //   return false;
    // }
    // if (!inoutComeYn || inoutComeYn === '') {
    //   window.alert('정산여부을 선택해주세요!');
    //   return false;
    // }
    // if (!taxYn || taxYn === '') {
    //   window.alert('정산여부을 선택해주세요!');
    //   return false;
    // }
    // if (taxYn === 'Y') {
    //   if (!taxAmount) {
    //     window.alert('부가세를 입력해주세요!');
    //     return false;
    //   }
    // }
    if (!employer) {
      window.alert('거래처를 입력해주세요');
      return false;
    }
    if (!workStartDate) {
      window.alert('작업시작일을 입력해주세요');
      return false;
    }
    if (!workEndtDate) {
      window.alert('작업종료일을 입력해주세요');
      return false;
    }
    if (!workContents) {
      window.alert('작업내용을 입력해주세요');
      return false;
    }
    if (!workPrice) {
      window.alert('작업금액을 입력해주세요');
      return false;
    }
    if (!howtoPay) {
      window.alert('지불방법을 입력해주세요');
      return false;
    }
    if (workStartDate <= workEndtDate === false) {
      window.alert('작업종료일과 시작일을 확인해주세요');
      return false;
    }
    // setWorkPrice(Number(workPrice));
    // setTaxAmount(Number(taxAmount.replace(',', '')));
    return true;
  };
  // ------------------------------------------------------------------------------------------------------------
  const updValidationCheck = () => {
    // if (modiTaxYn === 'Y') {
    //   if (!modiTaxAmount) {
    //     window.alert('부가세를 입력해주세요!');
    //     return false;
    //   }
    // }
    if (!modiWorkOrnt) {
      window.alert('거래처를 입력해주세요');
      return false;
    }
    if (!modiWorkBegin) {
      window.alert('작업시작일을 입력해주세요');
      return false;
    }
    if (!modiWorkEndde) {
      window.alert('작업종료일을 입력해주세요');
      return false;
    }
    if (!modiWorkCn) {
      window.alert('작업내용을 입력해주세요');
      return false;
    }
    if (!modiWorkAmount) {
      window.alert('작업금액을 입력해주세요');
      return false;
    }
    if (!modiPymntMthd) {
      window.alert('지불방법을 입력해주세요');
      return false;
    }
    if (modiWorkBegin <= modiWorkEndde === false) {
      window.alert('작업종료일과 시작일을 확인해주세요');
      return false;
    }
    return true;
  };
  // ------------------------------------------------------------------------------------------------------------
  const handleSaveQuestion = () => {
    if (!regValidationCheck()) return;
    console.log(`taxAmount : ${taxAmount}`);
    if (window.confirm('저장하시겠습니까?')) {
      if (taxYn === 'N') {
        setTaxAmount(0);
      }
      console.log(`taxAmount : ${taxAmount}`);
      saveData();
      // handleRegDialogClose();
    }
  };
  const handleUpdQuestion = () => {
    if (!updValidationCheck()) return;
    if (window.confirm('저장하시겠습니까?')) {
      if (taxYn === 'N') {
        setTaxAmount(0);
      }
      updateData();
      // handleRegDialogClose();
    }
  };
  const handleDelQuestion = () => {
    if (window.confirm('내용을 삭제하시겠습니까?')) {
      deleteData();
      // handleRegDialogClose();
    }
  };
  // -----------------------------------------------useEffect----------------------------------------------------
  useEffect(() => {
    // 토큰 값이 없을 경우 Login Page로 이동
    if (sessionStorage.getItem('token') === undefined || sessionStorage.getItem('token') === null) {
      navigate('/login');
    }
    myWorkHistoryGetData();
  }, [searchEmployer, searchInoutCome, searchInoutComeYn, searchMonth1]);
  // useEffect(() => {
  // }, []);
  // ------------------------------------------------------------------------------------------------------------
  return (
    <Page title="정산시스템">
      <Box spacing={1}>
        <Stack justifyContent="flex-start">
          <Typography variant="h3" gutterBottom>
            작업정산
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="flex-start" spacing={2}>
          {/* <AppAccumulation /> */}
          {/* <AppAccumulation
            accumulationIncome={accumulationIncome}
            accumulationOutcome={accumulationOutcome}
            accumulationVatIncome={accumulationVatIncome}
            accumulationVatOutcome={accumulationVatOutcome}
          /> */}
          <AppInOutComeTotal
            accumulationIncome={accumulationIncome}
            accumulationOutcome={accumulationOutcome}
            accumulationVatIncome={accumulationVatIncome}
            accumulationVatOutcome={accumulationVatOutcome}
            inComeTotal={inComeTotal}
            outComeTotal={outComeTotal}
            excclcKndyInCome={excclcKndyInCome}
            excclcKndyOutCome={excclcKndyOutCome}
            excclcKndnInCome={excclcKndnInCome}
            excclcKndnOutCome={excclcKndnOutCome}
            InComevatTotal={InComevatTotal}
            OutComevatTotal={OutComevatTotal}
          />
          {/* <AppInOutCome
            total={total}
            inComeTotal={inComeTotal}
            outComeTotal={outComeTotal}
            month={nowMonth}
          />
          <AppExcclcKnd excclcKndy={excclcKndy} excclcKndn={excclcKndn} month={nowMonth} />
          <AppVatTotal vatTotal={vatTotal} /> */}
          {/* <Typography variant="subtitle2" gutterBottom>
            총 합계 : <strong>{total}</strong> 원
          </Typography>
          <Typography variant="subtitle2" gutterBottom>
            부가세 합계 : <strong>{vatTotal}</strong> 원
          </Typography>
          <Typography variant="subtitle2" gutterBottom>
            매출 합계 : <strong>{inComeTotal}</strong> 원
          </Typography>
          <Typography variant="subtitle2" gutterBottom>
            매입 합계 : <strong>{outComeTotal}</strong> 원
          </Typography> */}
        </Stack>
        <br />
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={0.5}>
          <Stack direction="row" spacing={0.5}>
            <TextField
              fullWidth
              label="거래처"
              type="search"
              size="small"
              value={searchEmployer}
              onChange={onChangeSearchEmployer}
              sx={{ width: '200px' }}
            />
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="demo-simple-select-label">정산구분</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                size="small"
                value={searchInoutCome}
                onChange={(e) => onChangeSearchInoutCome(e)}
              >
                <MenuItem value="ALL">전체</MenuItem>
                <MenuItem value="매출">매출</MenuItem>
                <MenuItem value="매입">매입</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="demo-simple-select-label">정산여부</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                size="small"
                value={searchInoutComeYn}
                onChange={(e) => onChangeSearchInoutComeYn(e)}
              >
                <MenuItem value="ALL">전체</MenuItem>
                <MenuItem value="Y">Y</MenuItem>
                <MenuItem value="N">N</MenuItem>
              </Select>
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ko}>
              <DatePicker
                size="small"
                views={['year', 'month']}
                label="작업시작일"
                value={searchMonth1}
                inputFormat="yyyy-MM"
                closeOnSelect="true"
                onChange={(newValue) => {
                  if (newValue) {
                    const year = newValue.getFullYear();
                    const month = newValue.getMonth() + 1;
                    const nowMonth = month < 10 ? `0${month}` : `${month}`;
                    setSearchMonth1(`${year}-${nowMonth}-01`);
                    // setTab2SearchStartMonth(`${year}-${month}`);
                    // setTab2SearchStartMonth(newValue);
                  } else {
                    setSearchMonth1(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    helperText={null}
                    sx={{
                      svg: { width: '15px' }
                    }}
                  />
                )}
              />
            </LocalizationProvider>
            {/* <LocalizationProvider dateAdapter={AdapterDateFns} locale={ko}>
              <DatePicker
                size="small"
                // sx={{ width: 50, height: 10 }}
                views={['year', 'month']}
                label="작업시작일"
                // mask="____-__"
                value={searchMonth2}
                inputFormat="yyyy-MM-dd"
                closeOnSelect="true"
                onChange={(newValue) => {
                  setSearchMonth2(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} helperText={null} sx={{ width: '40%' }} />
                )}
              />
            </LocalizationProvider> */}
          </Stack>
          <Stack direction="row" spacing={1} mb={1}>
            <Button size="small" onClick={() => myWorkHistoryGetData()}>
              <RefreshIcon />
              새로고침
            </Button>
            <Button variant="outlined" size="small" onClick={handleRegDialogOpen}>
              신규정산등록
            </Button>
            <Dialog
              open={newRegDialogOpen}
              onClose={handleRegDialogClose}
              PaperProps={{ sx: { width: '50%', height: '55%' } }}
            >
              <DialogTitle align="center">신규정산등록</DialogTitle>
              <DialogContent>
                <Stack direction="column" justifyContent="space-between" spacing={2}>
                  <Stack direction="row" justifyContent="flex-start" spacing={1}>
                    <InputLabel id="inoutCome">정산구분</InputLabel>
                    <Select
                      id="inoutCome"
                      label="정산구분"
                      size="small"
                      variant="standard"
                      value={inoutCome}
                      onChange={(e) => onChangeInoutCome(e)}
                    >
                      {/* <MenuItem value="선택">선택</MenuItem> */}
                      <MenuItem value="매입">매입</MenuItem>
                      <MenuItem value="매출">매출</MenuItem>
                    </Select>

                    <InputLabel id="inoutComeYn">정산여부</InputLabel>
                    <Select
                      id="inoutComeYn"
                      label="정산여부"
                      size="small"
                      variant="standard"
                      value={inoutComeYn}
                      onChange={(e) => onChangeInoutComeYn(e)}
                    >
                      {/* <MenuItem value="">선택</MenuItem> */}
                      <MenuItem value="Y">Y</MenuItem>
                      <MenuItem value="N">N</MenuItem>
                    </Select>

                    <InputLabel id="taxYn">세금계산서 발행</InputLabel>
                    <Select
                      id="taxYn"
                      label="세금계산서 발행"
                      size="small"
                      variant="standard"
                      value={taxYn}
                      onChange={(e) => onChangeTaxYn(e)}
                      ref={refTaxYn}
                    >
                      {/* <MenuItem value="">선택</MenuItem> */}
                      <MenuItem value="Y">Y</MenuItem>
                      <MenuItem value="N">N</MenuItem>
                    </Select>
                  </Stack>
                  <Stack direction="row" justifyContent="flex-start" spacing={1}>
                    {/* <TextField
                      label="작업금액"
                      type="text"
                      variant="standard"
                      // digitGroupSeparator=","
                      value={workPriceShow}
                      // onChange={(e) => setWorkPrice(inputPriceFormat(e.target.value))}
                      onChange={(e) => onChangeWorkPrice(e)}
                      inputProps={{
                        maxLength: AMOUNT_LIMIT
                      }}
                    /> */}
                    <FormControl variant="standard">
                      <NumberFormat
                        customInput={TextField}
                        fullWidth
                        label="작업금액"
                        variant="standard"
                        thousandSeparator
                        value={workPriceShow}
                        onChange={(e) => onChangeWorkPrice(e)}
                        autoComplete="off"
                        sx={{ width: '100%' }}
                        inputProps={{
                          maxLength: AMOUNT_LIMIT
                        }}
                      />
                    </FormControl>
                    {taxShow && (
                      // <TextField
                      //   label="부가세"
                      //   type="text"
                      //   variant="standard"
                      //   value={taxAmountShow || ''}
                      //   onChange={(e) => onChangeTaxAmount(e)}
                      //   inputProps={{
                      //     maxLength: AMOUNT_LIMIT
                      //   }}
                      //   disabled
                      // />
                      <FormControl variant="standard">
                        <NumberFormat
                          customInput={TextField}
                          fullWidth
                          label="부가세"
                          variant="standard"
                          thousandSeparator
                          value={taxAmountShow}
                          // onChange={onChangeModiTaxAmountShow}
                          autoComplete="off"
                          disabled
                          sx={{ width: '100%' }}
                          inputProps={{
                            maxLength: AMOUNT_LIMIT
                          }}
                        />
                      </FormControl>
                    )}
                    {/* <Stack direction="row" justifyContent="space-between" spacing={1}>
                    <TextField
                      fullWidth
                      label="장비명"
                      type="text"
                      variant="standard"
                      value={equitment || ''}
                      // onChange={onChangeEquitment}
                    />
                    <TextField
                      fullWidth
                      label="작업지역"
                      type="text"
                      variant="standard"
                      value={address || ''}
                      // onChange={onChangeAddress}
                    />
                  </Stack> */}
                    <TextField
                      label="거래처"
                      type="text"
                      variant="standard"
                      value={employer || ''}
                      onChange={onChangeEmployer}
                    />
                  </Stack>
                  <Stack direction="row" justifyContent="space-between" spacing={1}>
                    <TextField
                      fullWidth
                      label="작업내용"
                      type="text"
                      variant="standard"
                      value={workContents || ''}
                      onChange={onChangeWorkContents}
                    />
                    <TextField
                      label="지불방법"
                      type="text"
                      variant="standard"
                      value={howtoPay || ''}
                      onChange={onChangeHowtoPay}
                      inputProps={{
                        maxLength: PYMNT_MTHD_LIMIT
                      }}
                    />
                  </Stack>
                  <br />

                  <Stack direction="row" justifyContent="space-between" spacing={1}>
                    <TextField
                      fullWidth
                      type="date"
                      variant="standard"
                      value={workStartDate}
                      helperText="작업시작일"
                      onChange={onChangeWorkStartDate}
                      // ref={refWorkStartDate}
                    />
                    <TextField
                      fullWidth
                      type="date"
                      variant="standard"
                      value={workEndtDate || ''}
                      helperText="작업종료일"
                      onChange={onChangeWorkEndDate}
                      // ref={refWorkEndDate}
                    />
                  </Stack>
                  {/* <FormControl>
                    <FormLabel>작업시간</FormLabel>
                    <RadioGroup row>
                      <FormControlLabel
                        checked={workType === '1'}
                        onChange={onChangeWorkType}
                        value="1"
                        control={<Radio />}
                        label="오전(08:00~12:00)"
                      />
                      <FormControlLabel
                        checked={workType === '2'}
                        onChange={onChangeWorkType}
                        value="2"
                        control={<Radio />}
                        label="오후(13:00~17:00)"
                      />
                      <FormControlLabel
                        checked={workType === '3'}
                        onChange={onChangeWorkType}
                        value="3"
                        control={<Radio />}
                        label="종일(08:00~17:00)"
                      />
                      <FormControlLabel
                        checked={workType === '4'}
                        onChange={onChangeWorkType}
                        value="4"
                        control={<Radio />}
                        label="사용자지정"
                      />
                      {workTimeShow && (
                        <Stack direction="row" justifyContent="space-between" spacing={1}>
                          <TextField
                            fullWidth
                            type="time"
                            variant="standard"
                            value={workBeginTime}
                            helperText="작업시작시간"
                            onChange={onChangeWorkBeginTime}
                            // ref={refWorkStartDate}
                          />
                          <TextField
                            fullWidth
                            type="time"
                            variant="standard"
                            value={workEndTime || ''}
                            helperText="작업종료시간"
                            onChange={onChangeWorkEndTime}
                            // ref={refWorkEndDate}
                          />
                        </Stack>
                      )}
                    </RadioGroup>
                  </FormControl> */}
                  <TextField
                    label="메모"
                    type="text"
                    variant="standard"
                    value={memo}
                    onChange={onChangeMemo}
                  />
                  <TextField
                    label="정산메모"
                    type="text"
                    variant="standard"
                    value={soeMemo}
                    onChange={onChangeSoeMemo}
                  />
                  <br />
                </Stack>
                <Stack direction="row" justifyContent="center" spacing={1}>
                  <Button variant="contained" onClick={handleSaveQuestion}>
                    저장
                  </Button>
                  <Button variant="contained" onClick={handleRegDialogClose}>
                    닫기
                  </Button>
                </Stack>
              </DialogContent>
            </Dialog>
            {/* <Button variant="contained" onClick={updateData}>
              저장
            </Button> */}
            <Button variant="outlined" onClick={handleModiDialogOpen}>
              내용수정
            </Button>
            <Dialog
              open={modiDialogOpen}
              onClose={handleModiDialogClose}
              PaperProps={{ sx: { width: '50%', height: '55%' } }}
            >
              <DialogTitle align="center">정산작업 수정</DialogTitle>
              <DialogContent>
                <Stack direction="column" justifyContent="space-between" spacing={2}>
                  {/* <Typography variant="body1" gutterBottom>
                    <strong>거래처 : {modiWorkOrnt}</strong>
                  </Typography> */}
                  <Stack direction="row" justifyContent="flex-end" spacing={0.5}>
                    <Button variant="contained" onClick={handleUpdQuestion}>
                      수정
                    </Button>
                    <Button variant="contained" onClick={handleDelQuestion}>
                      삭제
                    </Button>
                    <Button variant="outlined" onClick={handleModiDialogClose}>
                      닫기
                    </Button>
                  </Stack>
                  <Stack direction="row" justifyContent="flex-start" spacing={1}>
                    <InputLabel id="inoutCome">정산구분</InputLabel>
                    <Select
                      id="inoutCome"
                      label="정산구분"
                      size="small"
                      variant="standard"
                      value={modiInoutCome}
                      onChange={(e) => onChangeModiInoutCome(e)}
                    >
                      <MenuItem value="매입">매입</MenuItem>
                      <MenuItem value="매출">매출</MenuItem>
                    </Select>

                    <InputLabel id="inoutComeYn">정산여부</InputLabel>
                    <Select
                      id="inoutComeYn"
                      label="정산여부"
                      size="small"
                      variant="standard"
                      value={modiInoutComeYn}
                      onChange={(e) => onChangeModiInoutComeYn(e)}
                    >
                      <MenuItem value="">선택</MenuItem>
                      <MenuItem value="Y">Y</MenuItem>
                      <MenuItem value="N">N</MenuItem>
                    </Select>

                    <InputLabel id="taxYn">세금계산서 발행</InputLabel>
                    <Select
                      id="taxYn"
                      label="세금계산서 발행"
                      size="small"
                      variant="standard"
                      value={modiTaxYn}
                      onChange={(e) => onChangeModiTaxYn(e)}
                      ref={refModiTaxYn}
                    >
                      <MenuItem value="">선택</MenuItem>
                      <MenuItem value="Y">Y</MenuItem>
                      <MenuItem value="N">N</MenuItem>
                    </Select>
                  </Stack>
                  <Stack direction="row" justifyContent="flex-start" spacing={1}>
                    {/* <FormControl focused className="col " variant="outlined">
                      <InputLabel className="mText">your label</InputLabel>
                      <NumberFormat
                        customInput={TextField}
                        variant="outlined"
                        thousandSeparator
                        onChange={onChangeModiWorkAmount}
                        autoComplete="off"
                      />
                    </FormControl> */}
                    <FormControl variant="standard">
                      <NumberFormat
                        customInput={TextField}
                        fullWidth
                        label="작업금액"
                        variant="standard"
                        thousandSeparator
                        value={modiWorkAmountShow || ''}
                        onChange={onChangeModiWorkAmount}
                        autoComplete="off"
                        sx={{ width: '100%' }}
                      />
                    </FormControl>
                    {/* <TextField
                      fullWidth
                      label="작업금액"
                      type="number"
                      variant="standard"
                      thousandSeparator
                      value={modiWorkAmountShow || ''}
                      // onChange={(e) => setModiWorkAmount(inputPriceFormat(e.target.value))}
                      onChange={onChangeModiWorkAmount}
                    /> */}
                    {modiTaxShow && (
                      <FormControl variant="standard">
                        <NumberFormat
                          customInput={TextField}
                          fullWidth
                          label="부가세"
                          variant="standard"
                          thousandSeparator
                          value={modiTaxAmountShow}
                          // onChange={onChangeModiTaxAmountShow}
                          autoComplete="off"
                          disabled
                          sx={{ width: '100%' }}
                        />
                      </FormControl>
                    )}
                    <TextField
                      fullWidth
                      label="거래처"
                      type="text"
                      variant="standard"
                      value={modiWorkOrnt || ''}
                      onChange={onChangeModiWorkOrnt}
                      sx={{ width: '30%' }}
                    />
                  </Stack>
                  {/* <Stack direction="row" justifyContent="space-between" spacing={1}>
                    <TextField
                      fullWidth
                      label="장비명"
                      type="text"
                      variant="standard"
                      value={equitment || ''}
                      // onChange={onChangeEquitment}
                    />
                    <TextField
                      fullWidth
                      label="작업지역"
                      type="text"
                      variant="standard"
                      value={address || ''}
                      // onChange={onChangeAddress}
                    />
                  </Stack> */}
                  <Stack direction="row" justifyContent="space-between" spacing={1}>
                    <TextField
                      fullWidth
                      label="작업내용"
                      type="text"
                      variant="standard"
                      value={modiWorkCn || ''}
                      onChange={onChangeModiWorkCn}
                    />

                    <TextField
                      label="지불방법"
                      type="text"
                      variant="standard"
                      value={modiPymntMthd || ''}
                      onChange={onChangeModiPymntMthd}
                    />
                  </Stack>

                  <br />

                  <Stack direction="row" justifyContent="space-between" spacing={1}>
                    <TextField
                      fullWidth
                      type="date"
                      variant="standard"
                      value={modiWorkBegin}
                      helperText="작업시작일"
                      onChange={onChangeModiWorkBegin}
                      // ref={refWorkStartDate}
                    />
                    <TextField
                      fullWidth
                      type="date"
                      variant="standard"
                      value={modiWorkEndde || ''}
                      helperText="작업종료일"
                      onChange={onChangeModiWorkEndde}
                      // ref={refWorkEndDate}
                    />
                  </Stack>
                  {/* <FormControl>
                    <FormLabel>작업시간</FormLabel>
                    <RadioGroup row>
                      <FormControlLabel
                        checked={modiWorkType === '1'}
                        onChange={onChangeWorkType}
                        value="1"
                        control={<Radio />}
                        label="오전(08:00~12:00)"
                      />
                      <FormControlLabel
                        checked={modiWorkType === '2'}
                        onChange={onChangeWorkType}
                        value="2"
                        control={<Radio />}
                        label="오후(13:00~17:00)"
                      />
                      <FormControlLabel
                        checked={modiWorkType === '3'}
                        onChange={onChangeWorkType}
                        value="3"
                        control={<Radio />}
                        label="종일(08:00~17:00)"
                      />
                      <FormControlLabel
                        checked={modiWorkType === '4'}
                        onChange={onChangeWorkType}
                        value="4"
                        control={<Radio />}
                        label="사용자지정"
                      />
                      {modiWorkTimeShow && (
                        <Stack direction="row" justifyContent="space-between" spacing={1}>
                          <TextField
                            fullWidth
                            type="time"
                            variant="standard"
                            value={modiBeginTime}
                            helperText="작업시작시간"
                            onChange={onChangeWorkBeginTime}
                            // ref={refWorkStartDate}
                          />
                          <TextField
                            fullWidth
                            type="time"
                            variant="standard"
                            value={modiEndTime || ''}
                            helperText="작업종료시간"
                            onChange={onChangeWorkEndTime}
                            // ref={refWorkEndDate}
                          />
                        </Stack>
                      )}
                    </RadioGroup>
                  </FormControl> */}
                  <TextField
                    label="메모"
                    type="text"
                    variant="standard"
                    value={modiWorkMemo}
                    onChange={onChangeModiWorkMemo}
                  />
                  <TextField
                    label="정산 메모"
                    type="text"
                    variant="standard"
                    value={modiSoeMemo}
                    onChange={onChangeModiSoeMemo}
                  />
                  <br />
                </Stack>
              </DialogContent>
            </Dialog>
            <Button variant="contained" onClick={() => onClickSearch}>
              검색
            </Button>
          </Stack>
        </Stack>
        <div style={{ height: 600, width: '100%' }}>
          <DataGrid
            rows={mySettlementList}
            columns={MY_SETTLEMENT_TABLE_HEAD}
            getRowId={(row) => row.rcvpayNo}
            // editMode="row"
            // checkboxSelection
            rowHeight={40}
            onRowClick={(params) => onRowClick(params.row)}
            onSelectionModelChange={(newSelection) => {
              setSelectionModel(newSelection);
            }}
            selectionModel={selectionModel}
            // onSelectionModelChange={(ids) => {
            //   const selectedIDs = new Set(ids);
            //   const selectedRows = mySettlementList.filter((ids) => selectedIDs.has(ids.rcvpayNo));
            //   if (!selectedRows.includes(ids.rcvpayNo)) {
            //     setSelectedRows(selectedRows);
            //   }
            //   console.log(selectedRows);
            // }}
            // onCellValueChange={(params) => onCellValueChange(params)}
            disableColumnMenu
            // componentsProps={{ footer: CustomFooter }}
          />
        </div>
      </Box>
    </Page>
  );
}
