import * as React from 'react';
// import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
// component
// import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  '& .MuiAutocomplete-root': {
    width: 200,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter
    }),
    '&.Mui-focused': {
      width: 240,
      '& .MuiAutocomplete-inputRoot': {
        boxShadow: theme.customShadows.z12
      }
    }
  },
  '& .MuiAutocomplete-inputRoot': {
    '& fieldset': {
      borderWidth: `1px !important`,
      borderColor: `${theme.palette.grey[500_32]} !important`
    }
  },
  '& .MuiAutocomplete-option': {
    '&:not(:last-child)': {
      borderBottom: `solid 1px ${theme.palette.divider}`
    }
  }
}));

// ----------------------------------------------------------------------

// ConstructionDatePicker.propTypes = {
// posts: PropTypes.array.isRequired
// };

export default function ConstructionDatePicker() {
  const [value, setValue] = React.useState(new Date());
  console.log(value);
  return (
    <RootStyle>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          // sx={{ ml: 1, width: 20, height: 20 }}
          size="small"
          mask="____-__-__"
          value={value}
          inputFormat="yyyy-MM-dd"
          onChange={(newValue) => setValue(newValue)}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
    </RootStyle>
  );
}
