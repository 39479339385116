const CHART_DATA = {
  chartlist: [
    {
      month: 1,
      income: 2563000,
      vat: 256300,
      sales: 631540
    },
    {
      month: 2,
      income: 35612200,
      vat: 351620,
      sales: 18523000
    },
    {
      month: 3,
      income: 4011500,
      vat: 364000,
      sales: 1608200
    },
    {
      month: 4,
      income: 4500600,
      vat: 605200,
      sales: 1450060
    },
    {
      month: 5,
      income: 6054200,
      vat: 230000,
      sales: 1802500
    },
    {
      month: 6,
      income: 8096550,
      vat: 310000,
      sales: 2506000
    },
    {
      month: 7,
      income: 10009010,
      vat: 1056920,
      sales: 6900560
    },
    {
      month: 8,
      income: 21050200,
      vat: 6052000,
      sales: 9800060
    },
    {
      month: 9,
      income: 100000,
      vat: 10000,
      sales: 10000
    },
    {
      month: 10,
      income: 100000,
      vat: 10000,
      sales: 10000
    },
    {
      month: 11,
      income: 100000,
      vat: 10000,
      sales: 10000
    },
    {
      month: 12,
      income: 100000,
      vat: 10000,
      sales: 10000
    }
  ]
};
export default CHART_DATA;
