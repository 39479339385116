import React, { useEffect, useState } from 'react';
// import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Stack,
  Button,
  Typography,
  Container,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  getTableBodyProps,
  getTableProps
} from '@mui/material';
// component
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { ConstructionListHead, ConstructionDetail } from '.';

// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: 'id', label: '번호', alignRight: false, width: 10 },
  { id: 'cntrwkmNm', label: '현장명', alignRight: false },
  { id: 'cntrwkmRegDate', label: '공사기간', alignRight: false },
  { id: 'cntrwkmSttus', label: '상태', alignRight: false, width: 20 }
];
// ----------------------------------------------------------------------------------------------------

export default function ConstructionFirstCellTable() {
  const idcount = 0;
  const [constructionList, setConstructionList] = useState([]);
  const [saveData, setSaveData] = useState({
    cntrwkmNm: '',
    cntrwkmSDate: '',
    cntrwkmEDate: '',
    cntrwkmSttus: '',
    cntrwkRegDate: ''
  });
  const [sceneName, setSceneName] = useState();
  const [startDate, setStarDate] = useState();
  const [endDate, setEndDate] = useState();
  const [status, setStatus] = useState('working');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [sendDatatoParent, setSendDatatoParent] = useState([]);
  // table props
  const { getTableBodyProps, getTableProps } = useState([]);
  // const [regUser, setRegUser] = useState();
  // API 호출
  const getData = async () => {
    // const apiUrl = '/cntrwkm/getCntrwkmList';
    const apiUrl = 'http://localhost:8080/cntrwkm/getCntrwkmList';
    const sendParams = {
      cntrwkmNm: '',
      cntrwkmSDate: '2000-01-01',
      cntrwkmEDate: '9999-12-31'
    };
    axios
      .post(apiUrl)
      .then((response) => {
        setConstructionList(response.data);
        setSendDatatoParent(response.data);
        console.log(response.data);
        // console.log(createData);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // ---------------------------------------------------------------------
  // Dialog Open
  const handleClickOpen = () => {
    setDialogOpen(true);
    setSaveData({
      // id: '',
      cntrwkmNm: sceneName,
      cntrwkmSDate: startDate,
      cntrwkmEDate: endDate,
      cntrwkmSttus: status,
      cntrwkRegDate: 'regUser'
    });
  };
  // Dialog Close
  const handleClickClose = () => {
    setSceneName('');
    setStarDate('');
    setEndDate('');
    setStatus('');
    setDialogOpen(false);
  };

  // ----------------------------------------------------------------------
  // useEffect
  useEffect(() => {
    getData();
    console.log(constructionList);
  }, []);
  // ----------------------------------------------------------------------
  // event
  const handleClickSave = () => {
    // popup 내용 저장
    // console.log(createData);
    console.log(constructionList);
    console.log(constructionList.length);
    setConstructionList({
      id: constructionList.length + 1,
      cntrwkmNm: sceneName,
      cntrwkmSDate: startDate,
      cntrwkmEDate: endDate,
      cntrwkmSttus: status
    });
    handleClickClose();
  };
  const handleApiSave = () => {
    console.log('SAVE API CALL');
    // getData();
  };
  const onChangeScenename = (e) => {
    setSceneName(e.target.value || '');
  };
  const onChangeStartDate = (e) => {
    setStarDate(e.target.value || '');
  };
  const onChangeEndDate = (e) => {
    setEndDate(e.target.value || '');
  };
  const onChangeStatus = (e) => {
    setStatus(e.target.value || '');
  };
  const onClick = (e) => {
    if (!constructionList === null) {
      console.log('onClick');
    }
  };
  const handleClick = (row, column, event) => {
    console.log(row.PaperProps);
    console.log(column);
    console.log(event);
  };
  return (
    <Container spacing={1}>
      <Stack direction="row" justifyContent="space-between">
        <Stack>
          <Typography variant="h6" gutterBottom align="left">
            공사 현장명
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
          <Button variant="outlined" onClick={handleClickOpen}>
            추가
          </Button>
          <Button variant="outlined" onClick={handleApiSave}>
            저장
          </Button>
          <Dialog
            open={dialogOpen}
            onClose={handleClickClose}
            PaperProps={{ sx: { width: '30%', height: '50%' } }}
          >
            <DialogTitle align="center">공사현장 추가</DialogTitle>
            <DialogContent spacing={2}>
              <Stack direction="column" justifyContent="space-between" spacing={3}>
                <TextField
                  label="현장명"
                  type="text"
                  variant="standard"
                  name="sceneName"
                  value={sceneName || ''}
                  onChange={onChangeScenename}
                />
                <TextField
                  type="date"
                  variant="standard"
                  name="workStartDate"
                  value={startDate || ''}
                  onChange={onChangeStartDate}
                />
                <TextField
                  type="date"
                  variant="standard"
                  name="workEndDate"
                  value={endDate || ''}
                  onChange={onChangeEndDate}
                />
                <FormControl>
                  <FormLabel>상태</FormLabel>
                  <RadioGroup>
                    <FormControlLabel
                      checked={status === 'working'}
                      onChange={onChangeStatus}
                      value="working"
                      control={<Radio />}
                      label="공사중"
                    />
                    <FormControlLabel
                      checked={status === 'worked'}
                      onChange={onChangeStatus}
                      value="worked"
                      control={<Radio />}
                      label="마감"
                    />
                  </RadioGroup>
                </FormControl>
                {/* <TextField Label="비고" type="text" name="etc"value={sceneName} /> */}
              </Stack>
              <Stack direction="row" justifyContent="center" spacing={1}>
                <Button variant="contained" onClick={handleClickSave}>
                  저장
                </Button>
                <Button variant="contained" onClick={handleClickClose}>
                  취소
                </Button>
              </Stack>
            </DialogContent>
          </Dialog>
          <Button variant="contained">삭제</Button>
        </Stack>
      </Stack>
      <div>
        <TableContainer sx={{ height: 200, width: '100%' }}>
          <Table className="firstWorkTable">
            <TableHead>
              <TableRow>
                <TableCell>번호</TableCell>
                <TableCell>현장명</TableCell>
                <TableCell>공사기간</TableCell>
                <TableCell>상태</TableCell>
              </TableRow>
            </TableHead>
            <TableBody {...getTableBodyProps}>
              {constructionList.map((row) => {
                const { id, cntrwkmNo, cntrwkmNm, cntrwkmSDate, cntrwkmEDate, cntrwkmSttus } = row;
                return (
                  <TableRow selected key={cntrwkmNo} onClick={handleClick} {...getTableProps}>
                    <TableCell component="th" scope="row" align="center" onClick={handleClick}>
                      <Stack direction="row">
                        <Typography variant="subtitle2" noWrap>
                          {idcount}
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell align="left" onClick={() => handleClick()}>
                      {cntrwkmNm}
                    </TableCell>
                    <TableCell align="left">
                      {cntrwkmSDate} ~ {cntrwkmEDate}
                    </TableCell>
                    <TableCell align="left">{cntrwkmSttus === 'I' ? '공사중' : '마감'}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Container>
  );
}
