// import { filter } from 'lodash';
import { useState, useEffect, useCallback, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
// material
import { Box, Stack, Typography, Button } from '@mui/material';
// component
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
// ----------------------------------------------------------------------
const BASE_URL = process.env.REACT_APP_API_URL;

export default function UpdateRegisterCertificateForm() {
  const todayMonth = new Date();
  const year = todayMonth.getFullYear();
  const month = todayMonth.getMonth() + 1;
  const day = todayMonth.getDate();
  const nowMonth = month < 10 ? `0${month}` : `${month}`;
  const nowday = day < 10 ? `0${day}` : `${day}`;
  // TEST [] 추가
  const [certificateList, setCertificateList] = useState([]);
  const [certificationNm, setCertificationNm] = useState(); // 자격증명
  const [crqfcIdno, setCrqfcIdno] = useState(); // 자격증 ID NO
  const [certificationNo, setCertificationNo] = useState(); // 자격증번호
  const [certificationDate, setCertificationDate] = useState(`${year}-${nowMonth}-${nowday}`); // 발급일
  const [certificationRegDate, setCertificationRegDate] = useState(`${year}-${nowMonth}-${nowday}`); // 등록일
  const [certificationPassDate, setCertificationPassDate] = useState(
    `${year}-${nowMonth}-${nowday}`
  ); // 취득일
  const [certificationAgency, setCertificationAgency] = useState(); // 발급기관
  const [certificationBeginDate, setCertificationBeginDate] = useState(
    `${year}-${nowMonth}-${nowday}`
  ); // 적성검사유효시작일
  const [certificationEndDate, setCertificationEndDate] = useState(); // 적성검사유효종료일
  const [certificationPassNo, setCertificationPassNo] = useState(); // 자격증대지번호(발급번호)
  // 수정 Parameter
  const [modiCertificationNm, setModiCertificationNm] = useState(); // 자격증명
  const [modiCrqfcIdno, setModiCrqfcIdno] = useState(); // 자격증 ID NO
  const [modiCertificationNo, setModiCertificationNo] = useState(); // 자격증번호
  const [modiCertificationDate, setModiCertificationDate] = useState(); // 발급일
  const [modiCertificationRegDate, setModiCertificationRegDate] = useState(); // 등록일
  const [modiCertificationPassDate, setModiCertificationPassDate] = useState(); // 취득일
  const [modiCertificationAgency, setModiCertificationAgency] = useState(); // 발급기관
  const [modiCertificationBeginDate, setModiCertificationBeginDate] = useState(); // 적성검사유효시작일
  const [modiCertificationEndDate, setModiCertificationEndDate] = useState(); // 적성검사유효종료일
  const [modiCertificationPassNo, setModiCertificationPassNo] = useState(); // 자격증대지번호(발급번호)

  const [certificationRegOpen, setCertificationRegOpen] = useState(false);
  const [certificationModiOpen, setCertificationModiOpen] = useState(false);

  const refCertificationBeginDate = useRef();
  const refCertificationEndDate = useRef();

  const MY_CERTICATION_HEAD = [
    {
      field: 'id',
      headerName: '번호',
      align: 'center',
      width: 10,
      hide: true,
      valueGetter: (params) => params.row.crqfcNo
    },
    { field: 'crqfcNm', headerName: '자격증명', alignRight: false, width: 150 },
    { field: 'crllqcNo', headerName: '자격증 번호', alignRight: false, width: 180 },
    { field: 'crqfcIssude', headerName: '발급일', type: 'date', alignRight: false, width: 180 },
    { field: 'crqfcAcqstr', headerName: '취득일', type: 'date', alignRight: false, width: 180 },
    {
      field: 'aptdchkppdBgnde',
      headerName: '적성검사시작일',
      type: 'date',
      alignRight: false,
      width: 180
    },
    {
      field: 'aptdchkppdEnnde',
      headerName: '적성검사종료일',
      type: 'date',
      alignRight: false,
      width: 180
    },
    // { field: 'crqfcIssunt', headerName: '발급기관', alignRight: false, width: 250 },
    // { field: 'crqfcIssuno', headerName: '발급번호', alignRight: false, width: 250 },
    { field: 'crqfcNo', headerName: 'crqfcNo', alignRight: false, hide: true }
  ];
  // ---------------------------------------API 호출--------------------------------------
  // ---------------------------------------자격증----------------------------------------
  const getCrqfcList = async () => {
    const sendParams = { id: sessionStorage.getItem('id') };
    const apiUrl = `${BASE_URL}/member/getCrqfcList`;
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    };
    axios
      // .post(apiUrl, sendParams, headers)
      .post(apiUrl, sendParams, headers)
      .then((response) => {
        console.log(response.data);
        setCertificateList(response.data);
        // sessionStorage.setItem('token', response.data.token);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // ---------------------------------등록/수정 API------------------------------------
  const regCertificationApi = async () => {
    const apiUrl = `${BASE_URL}/member/regCrqfc`;
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    };
    const sendParams = {
      id: sessionStorage.getItem('id'),
      crqfcNm: certificationNm,
      crllqcNo: certificationNo,
      crqfcRgsde: certificationRegDate,
      crqfcAcqstr: certificationPassDate,
      crqfcIssude: certificationDate,
      crqfcIssunt: certificationAgency,
      crqfcIssuno: certificationPassNo,
      aptdchkppdBgnde: certificationBeginDate,
      aptdchkppdEnnde: certificationEndDate
    };
    axios
      // .post(apiUrl, sendParams, headers)
      .post(apiUrl, sendParams, headers)
      .then((response) => {
        // console.log(response.data);
        if (response.data.code === '200') {
          getCrqfcList();
          window.alert('저장되었습니다.');
          handleRegDialogClose();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // 자격증 수정
  const updateCertificationApi = async () => {
    const apiUrl = `${BASE_URL}/member/updCrqfc`;
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    };
    const sendParams = {
      id: sessionStorage.getItem('id'),
      crqfcNo: modiCrqfcIdno,
      crqfcNm: modiCertificationNm,
      crllqcNo: modiCertificationNo,
      crqfcRgsde: modiCertificationRegDate,
      crqfcAcqstr: modiCertificationPassDate,
      crqfcIssude: modiCertificationDate,
      crqfcIssunt: modiCertificationAgency,
      crqfcIssuno: modiCertificationPassNo,
      aptdchkppdBgnde: modiCertificationBeginDate,
      aptdchkppdEnnde: modiCertificationEndDate
    };
    axios
      // .post(apiUrl, sendParams, headers)
      .post(apiUrl, sendParams, headers)
      .then((response) => {
        // console.log(response.data);
        if (response.data.code === '200') {
          getCrqfcList();
          window.alert('저장되었습니다.');
          handleModiDialogClose();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const deleteCertificationApi = async () => {
    const apiUrl = `${BASE_URL}/member/delCrqfc`;
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    };
    const sendParams = {
      id: sessionStorage.getItem('id'),
      crqfcNo: modiCrqfcIdno,
      // crqfcNm: certificationNm,
      // crllqcNo: certificationNo,
      // crqfcRgsde: certificationRegDate,
      // crqfcAcqstr: certificationPassDate,
      // crqfcIssude: certificationDate,
      // crqfcIssunt: certificationAgency,
      // crqfcIssuno: certificationPassNo,
      // aptdchkppdBgnde: certificationBeginDate,
      // aptdchkppdEnnde: certificationEndDate,
      delYn: 'Y'
    };
    axios
      // .post(apiUrl, sendParams, headers)
      .post(apiUrl, sendParams, headers)
      .then((response) => {
        console.log(response.data);
        if (response.data.code === '200') {
          getCrqfcList();
          window.alert('삭제되었습니다.');
          // handleRegDialogClose();
          setModiCrqfcIdno('');
          // setCertificationNm(); // 자격증명
          // setCrqfcIdno(); // 자격증 ID NO
          // setCertificationNo(); // 자격증번호
          // setCertificationDate(); // 발급일
          // setCertificationRegDate(); // 등록일
          // setCertificationPassDate(); // 취득일
          // setCertificationAgency(); // 발급기관
          // setCertificationBeginDate(); // 적성검사유효시작일
          // setCertificationEndDate(); // 적성검사유효종료일
          // setCertificationPassNo(); // 자격증대지번호(발급번호)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // ----------------------------------Validation 및------------------------------------
  const regValidationCheck = () => {
    const today = `${year}-${nowMonth}-${nowday}`;
    // certificationNm // 자격증명
    // crqfcIdno // 자격증 ID NO
    // certificationNo // 자격증번호
    // certificationDate // 발급일
    // certificationRegDate // 등록일
    // certificationPassDate // 취득일
    // certificationAgency // 발급기관
    // certificationBeginDate // 적성검사유효시작일
    // certificationEndDate // 적성검사유효종료일
    // certificationPassNo // 자격증대지번호(발급번호)
    if (!certificationNm) {
      window.alert('자격증명을 입력해주세요!');
      return false;
    }
    if (!certificationNo) {
      window.alert('자격증번호를 입력해주세요');
      return false;
    }
    if (!certificationDate) {
      window.alert('발급일을 입력해주세요');
      return false;
    }
    if (!certificationPassDate) {
      window.alert('취득일을 입력해주세요');
      return false;
    }
    if (!certificationRegDate) {
      window.alert('등록일을 입력해주세요');
      return false;
    }
    if (!certificationBeginDate) {
      window.alert('적성검사유효 시작일을 입력해주세요');
      return false;
    }
    if (!certificationEndDate) {
      window.alert('적성검사유효 종료일을 입력해주세요');
      return false;
    }
    // if (!certificationAgency) {
    //   window.alert('발급기관을 입력해주세요');
    //   return false;
    // }
    // if (!certificationPassNo) {
    //   window.alert('자격증 대지번호을 입력해주세요');
    //   return false;
    // }
    // 발급일이 오늘 보다 미래일때
    if (certificationDate > today) {
      window.alert('발급일을 확인해주세요');
      return false;
    }
    // 취득일이 오늘 보다 미래일때
    if (certificationPassDate > today) {
      window.alert('취득일을 확인해주세요');
      return false;
    }
    // 등록일이 오늘 보다 미래일때
    if (certificationRegDate > today) {
      window.alert('등록일을 확인해주세요');
      return false;
    }
    if (certificationBeginDate <= certificationEndDate === false) {
      window.alert('적성검사일을 확인해주세요');
      return false;
    }
    return true;
  };
  // 추가 후 저장시에 comfirm
  const updSaveQuestion = () => {
    if (!updValidationCheck()) return;
    if (window.confirm('수정 하시겠습니까?')) {
      updateCertificationApi();
      handleRegDialogClose();
    }
  };
  const updValidationCheck = () => {
    const today = `${year}-${nowMonth}-${nowday}`;
    if (!modiCertificationNm) {
      window.alert('자격증명을 입력해주세요!');
      return false;
    }
    if (!modiCertificationNo) {
      window.alert('자격증번호를 입력해주세요');
      return false;
    }
    if (!modiCertificationDate) {
      window.alert('발급일을 입력해주세요');
      return false;
    }
    if (!modiCertificationPassDate) {
      window.alert('취득일을 입력해주세요');
      return false;
    }
    if (!modiCertificationRegDate) {
      window.alert('등록일을 입력해주세요');
      return false;
    }
    if (!modiCertificationBeginDate) {
      window.alert('적성검사유효 시작일을 입력해주세요');
      return false;
    }
    if (!modiCertificationEndDate) {
      window.alert('적성검사유효 종료일을 입력해주세요');
      return false;
    }
    // if (!modiCertificationAgency) {
    //   window.alert('발급기관을 입력해주세요');
    //   return false;
    // }
    // if (!modiCertificationPassNo) {
    //   window.alert('자격증 대지번호을 입력해주세요');
    //   return false;
    // }
    // 발급일이 오늘 보다 미래일때
    if (modiCertificationDate < today) {
      window.alert('발급일을 확인해주세요');
      return false;
    }
    // 취득일이 오늘 보다 미래일때
    console.log(modiCertificationPassDate);
    console.log(today);
    if (modiCertificationPassDate < today) {
      window.alert('취득일을 확인해주세요');
      return false;
    }
    // 등록일이 오늘 보다 미래일때
    if (modiCertificationRegDate < today) {
      window.alert('등록일을 확인해주세요');
      return false;
    }
    if (modiCertificationBeginDate <= modiCertificationEndDate === false) {
      window.alert('적성검사일을 확인해주세요');
      return false;
    }
    return true;
  };
  // 추가 후 저장시에 comfirm
  const regSaveQuestion = () => {
    if (!regValidationCheck()) return;
    if (window.confirm('저장 하시겠습니까?')) {
      regCertificationApi();
      handleRegDialogClose();
    }
  };
  // ----------------------------------Dialog Open Event--------------------------------
  // 등록 Dialog Open
  const certificationDialogOpen = () => {
    setCertificationNm();
    setCertificationNo();
    setCertificationPassDate(`${year}-${nowMonth}-${nowday}`);
    setCertificationDate(`${year}-${nowMonth}-${nowday}`);
    setCertificationRegDate(`${year}-${nowMonth}-${nowday}`);
    setCertificationAgency();
    setCertificationPassNo();
    setCertificationBeginDate(`${year}-${nowMonth}-${nowday}`);
    setCertificationEndDate();
    setCertificationRegOpen(true);
  };
  // 등록 Dialog Close
  const handleRegDialogClose = () => {
    setCertificationRegOpen(false);
    setCertificationNm();
    setCertificationNo();
    setCertificationPassDate(`${year}-${nowMonth}-${nowday}`);
    setCertificationDate(`${year}-${nowMonth}-${nowday}`);
    setCertificationRegDate(`${year}-${nowMonth}-${nowday}`);
    setCertificationAgency();
    setCertificationPassNo();
    setCertificationBeginDate(`${year}-${nowMonth}-${nowday}`);
    setCertificationEndDate();
  };
  // 수정 Dialog Open
  const certificationModiDialogOpen = () => {
    // console.log(modiCrqfcIdno);
    if (!modiCrqfcIdno) {
      window.alert('수정할 목록을 선택해주세요');
    } else {
      setCertificationModiOpen(true);
    }
  };
  // 수정 Dialog Close
  const handleModiDialogClose = () => {
    setCertificationModiOpen(false);
  };
  // -----------------------------------onChang Event------------------------------------
  // 자격증명
  const onChangeCertifiNm = (e) => {
    setCertificationNm(e.target.value);
  };
  // 자격증 번호
  const onChangeCertifiNo = (e) => {
    setCertificationNo(e.target.value);
  };
  // 발급일
  const onChangeCertifiDate = useCallback((e) => {
    setCertificationDate(e.target.value);
  }, []);
  // 취득일
  const onChangeCertifiPassDate = useCallback((e) => {
    setCertificationPassDate(e.target.value);
  }, []);
  // 발급기관
  const onChangeCertifiAgency = (e) => {
    setCertificationAgency(e.target.value);
  };
  // 적성검사유효시작일
  const onChangeCertifiBeginDate = (e) => {
    setCertificationBeginDate(e.target.value);
  };
  // 적성검사유효종료일
  const onChangeCertifiEndDate = useCallback(
    (e) => {
      setCertificationEndDate(e.target.value);
    },
    [certificationBeginDate]
  );
  // 자격증대지번호(발급번호)
  const onChangeCertifiPassNo = (e) => {
    setCertificationPassNo(e.target.value);
  };
  // 등록일
  const onChangeCertifiRegDate = useCallback((e) => {
    setCertificationRegDate(e.target.value);
  }, []);
  // ------------------------------------------------------------------------------------------
  // 자격증명
  const onChangeModiCertifiNm = (e) => {
    setModiCertificationNm(e.target.value);
  };
  // 자격증 번호
  const onChangeModiCertifiNo = (e) => {
    setModiCertificationNo(e.target.value);
  };
  // 발급일
  const onChangeModiCertifiDate = useCallback((e) => {
    setModiCertificationDate(e.target.value);
  }, []);
  // 취득일
  const onChangeModiCertifiPassDate = useCallback((e) => {
    setModiCertificationPassDate(e.target.value);
  }, []);
  // 발급기관
  const onChangeModiCertifiAgency = (e) => {
    setModiCertificationAgency(e.target.value);
  };
  // 적성검사유효시작일
  const onChangeModiCertifiBeginDate = (e) => {
    setModiCertificationBeginDate(e.target.value);
  };
  // 적성검사유효종료일
  const onChangeModiCertifiEndDate = useCallback((e) => {
    setModiCertificationEndDate(e.target.value);
  }, []);
  // 자격증대지번호(발급번호)
  const onChangeModiCertifiPassNo = (e) => {
    setModiCertificationPassNo(e.target.value);
  };
  // 등록일
  const onChangeModiCertifiRegDate = useCallback((e) => {
    setModiCertificationRegDate(e.target.value);
  }, []);
  // ------------------------------------------------------------------------------------------
  const handleUpdateCertification = () => {
    console.log(modiCrqfcIdno);
    if (!modiCrqfcIdno) {
      window.alert('수정할 자격증을 선택해주세요');
    } else if (window.confirm('내용을 수정하시겠습니까?')) {
      updateCertificationApi();
    }
  };
  const handleDeleteCertification = (e) => {
    if (!modiCertificationNo) {
      window.alert('삭제할 자격증을 선택해주세요');
    } else if (window.confirm('선택한 자격증을 삭제하시겠습니까?')) {
      deleteCertificationApi();
    }
  };
  const onRowClick = (params) => {
    setCertificationNm(params.crqfcNm); // 자격증명
    // setCrqfcIdno(params.crqfcIdno); // 자격증 ID NO
    setCertificationNo(params.crllqcNo); // 자격증번호
    setCertificationDate(params.crqfcIssude); // 발급일
    setCertificationRegDate(params.crqfcRgsde); // 등록일
    setCertificationPassDate(params.crqfcAcqstr); // 취득일
    setCertificationAgency(params.crqfcIssunt); // 발급기관
    setCertificationBeginDate(params.aptdchkppdBgnde); // 적성검사유효시작일
    setCertificationEndDate(params.aptdchkppdEnnde); // 적성검사유효종료일
    setCertificationPassNo(params.crqfcIssuno); // 자격증대지번호(발급번호)
    // 수정 Paramter
    setModiCrqfcIdno(params.crqfcNo);
    setModiCertificationNm(params.crqfcNm); // 자격증명
    setModiCertificationNo(params.crllqcNo); // 자격증번호
    setModiCertificationDate(params.crqfcIssude); // 발급일
    setModiCertificationRegDate(params.crqfcRgsde); // 등록일
    setModiCertificationPassDate(params.crqfcAcqstr); // 취득일
    setModiCertificationAgency(params.crqfcIssunt); // 발급기관
    setModiCertificationBeginDate(params.aptdchkppdBgnde); // 적성검사유효시작일
    setModiCertificationEndDate(params.aptdchkppdEnnde); // 적성검사유효종료일
    setModiCertificationPassNo(params.crqfcIssuno); // 자격증대지번호(발급번호)
  };
  // ----------------------------------------------------------------------------------
  useEffect(() => {
    getCrqfcList();
  }, []);
  // ----------------------------------------------------------------------------------
  return (
    // 추가 삭제 Button
    <Box spacing={1}>
      <Stack direction="row" justifyContent="space-between">
        <Stack>
          <Typography variant="h6" gutterBottom align="left">
            보유 자격증
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
          <Button variant="outlined" onClick={certificationDialogOpen}>
            추가
          </Button>
          <Dialog
            open={certificationRegOpen}
            onClose={handleRegDialogClose}
            PaperProps={{ sx: { width: '50%', height: '40%' } }}
          >
            <DialogTitle align="center">자격증 추가 등록</DialogTitle>
            <DialogContent>
              <Stack direction="column" justifyContent="space-between" spacing={3}>
                <Stack direction="row" justifyContent="space-between" spacing={1}>
                  <TextField
                    fullWidth
                    label="자격증명"
                    type="text"
                    variant="standard"
                    value={certificationNm || ''}
                    onChange={onChangeCertifiNm}
                  />
                  <TextField
                    fullWidth
                    label="자격증번호(제호)"
                    type="text"
                    variant="standard"
                    value={certificationNo || ''}
                    onChange={onChangeCertifiNo}
                  />
                </Stack>
                <br />
                <Stack direction="row" justifyContent="space-between" spacing={1}>
                  <TextField
                    fullWidth
                    type="date"
                    variant="standard"
                    value={certificationDate || ''}
                    helperText="발급일"
                    onChange={onChangeCertifiDate}
                  />
                  <TextField
                    fullWidth
                    type="date"
                    variant="standard"
                    value={certificationPassDate || ''}
                    helperText="취득일"
                    onChange={onChangeCertifiPassDate}
                  />
                  <TextField
                    fullWidth
                    type="date"
                    variant="standard"
                    value={certificationRegDate || ''}
                    helperText="등록일"
                    onChange={onChangeCertifiRegDate}
                  />
                </Stack>
                <Stack direction="row" justifyContent="space-between" spacing={1}>
                  <TextField
                    fullWidth
                    type="date"
                    variant="standard"
                    value={certificationBeginDate || ''}
                    helperText="적성검사유효시작일"
                    onChange={onChangeCertifiBeginDate}
                  />
                  <TextField
                    fullWidth
                    type="date"
                    variant="standard"
                    value={certificationEndDate || ''}
                    helperText="적성검사유효종료일"
                    onChange={onChangeCertifiEndDate}
                  />
                </Stack>
                {/* <Stack direction="row" justifyContent="space-between" spacing={1}>
                  <TextField
                    fullWidth
                    label="발급기관"
                    type="text"
                    variant="standard"
                    value={certificationAgency || ''}
                    onChange={onChangeCertifiAgency}
                  />
                  <TextField
                    fullWidth
                    label="자격증대지번호(발급번호)"
                    type="text"
                    variant="standard"
                    value={certificationPassNo || ''}
                    onChange={onChangeCertifiPassNo}
                  />
                </Stack> */}
              </Stack>
              <br />
              <Stack direction="row" justifyContent="center" spacing={1}>
                <Button variant="contained" onClick={regSaveQuestion}>
                  저장
                </Button>
                <Button variant="contained" onClick={handleRegDialogClose}>
                  취소
                </Button>
              </Stack>
            </DialogContent>
          </Dialog>
          <Button variant="outlined" onClick={certificationModiDialogOpen}>
            수정
          </Button>
          <Dialog
            open={certificationModiOpen}
            onClose={handleModiDialogClose}
            PaperProps={{ sx: { width: '50%', height: '40%' } }}
          >
            <DialogTitle align="center">자격증 내용수정</DialogTitle>
            <DialogContent>
              <Stack direction="column" justifyContent="space-between" spacing={3}>
                <Stack direction="row" justifyContent="space-between" spacing={1}>
                  <TextField
                    fullWidth
                    label="자격증명"
                    type="text"
                    variant="standard"
                    value={modiCertificationNm || ''}
                    onChange={onChangeModiCertifiNm}
                  />
                  <TextField
                    fullWidth
                    label="자격증번호(제호)"
                    type="text"
                    variant="standard"
                    value={modiCertificationNo || ''}
                    onChange={onChangeModiCertifiNo}
                  />
                </Stack>
                <br />
                <Stack direction="row" justifyContent="space-between" spacing={1}>
                  <TextField
                    fullWidth
                    type="date"
                    variant="standard"
                    value={modiCertificationDate || ''}
                    helperText="발급일"
                    onChange={onChangeModiCertifiDate}
                  />
                  <TextField
                    fullWidth
                    type="date"
                    variant="standard"
                    value={modiCertificationPassDate || ''}
                    helperText="취득일"
                    onChange={onChangeModiCertifiPassDate}
                  />
                  <TextField
                    fullWidth
                    type="date"
                    variant="standard"
                    value={modiCertificationRegDate || ''}
                    helperText="등록일"
                    onChange={onChangeModiCertifiRegDate}
                  />
                </Stack>
                <Stack direction="row" justifyContent="space-between" spacing={1}>
                  <TextField
                    fullWidth
                    type="date"
                    variant="standard"
                    value={modiCertificationBeginDate || ''}
                    helperText="적성검사유효시작일"
                    onChange={onChangeModiCertifiBeginDate}
                  />
                  <TextField
                    fullWidth
                    type="date"
                    variant="standard"
                    value={modiCertificationEndDate || ''}
                    helperText="적성검사유효종료일"
                    onChange={onChangeModiCertifiEndDate}
                  />
                </Stack>
                {/* <Stack direction="row" justifyContent="space-between" spacing={1}>
                  <TextField
                    fullWidth
                    label="발급기관"
                    type="text"
                    variant="standard"
                    value={modiCertificationAgency || ''}
                    onChange={onChangeModiCertifiAgency}
                  />
                  <TextField
                    fullWidth
                    label="자격증대지번호(발급번호)"
                    type="text"
                    variant="standard"
                    value={modiCertificationPassNo || ''}
                    onChange={onChangeModiCertifiPassNo}
                  />
                </Stack> */}
              </Stack>
              <br />
              <Stack direction="row" justifyContent="center" spacing={1}>
                <Button variant="contained" onClick={updSaveQuestion}>
                  저장
                </Button>
                <Button variant="contained" onClick={handleModiDialogClose}>
                  닫기
                </Button>
              </Stack>
            </DialogContent>
          </Dialog>
          <Button variant="outlined" onClick={handleDeleteCertification}>
            삭제
          </Button>
        </Stack>
      </Stack>
      <div style={{ height: 300, width: '100%' }}>
        <DataGrid
          rows={certificateList}
          rowHeight={30}
          columns={MY_CERTICATION_HEAD}
          getRowId={(row) => row.crqfcNo}
          onRowClick={(params) => onRowClick(params.row)}
          hideFooterPagination
          disableColumnMenu
        />
      </div>
    </Box>
  );
}
