// material
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
// utils
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

// ----------------------------------------------------------------------

const RootStyle = styled(Box)(() => ({
  boxShadow: 'none',
  textAlign: 'center',
  // padding: theme.spacing(2.5, 0),
  // minWidth: 1000,
  minWidth: '100%',
  height: 100
  // color: theme.palette.primary.darker,
  // backgroundColor: theme.palette.primary.lighter
  // backgroundColor: '#0CB5F3'
}));
// ----------------------------------------------------------------------
// const TOTAL = 714000;
AppInOutComeTotal.defalutProps = {
  accumulationIncome: 0,
  accumulationOutcome: 0,
  accumulationVatIncome: 0,
  accumulationVatOutcome: 0,
  inComeTotal: 0,
  outComeTotal: 0,
  excclcKndyInCome: 0,
  excclcKndyOutCome: 0,
  excclcKndnInCome: 0,
  excclcKndnOutCome: 0,
  InComevatTotal: 0,
  OutComevatTotal: 0
};

AppInOutComeTotal.PropsTypes = {
  accumulationIncome: PropTypes.node,
  accumulationOutcome: PropTypes.node,
  accumulationVatIncome: PropTypes.node,
  accumulationVatOutcome: PropTypes.node,
  inComeTotal: PropTypes.node,
  outComeTotal: PropTypes.node,
  excclcKndyInCome: PropTypes.node,
  excclcKndyOutCome: PropTypes.node,
  excclcKndnInCome: PropTypes.node,
  excclcKndnOutCome: PropTypes.node,
  InComevatTotal: PropTypes.node,
  OutComevatTotal: PropTypes.node
};

export default function AppInOutComeTotal({
  accumulationIncome,
  accumulationOutcome,
  accumulationVatIncome,
  accumulationVatOutcome,
  inComeTotal,
  outComeTotal,
  excclcKndyInCome,
  excclcKndyOutCome,
  excclcKndnInCome,
  excclcKndnOutCome,
  InComevatTotal,
  OutComevatTotal
}) {
  // const classes = styles();
  return (
    <RootStyle>
      <TableContainer>
        <Table
          sx={{
            minWidth: 650
            // backgroundColor: 'primary.light'
          }}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow sx={{ backgroundColor: 'secondary.light' }}>
              <TableCell />
              <TableCell align="center">
                <Typography variant="subtitle2">
                  <strong>누적 합계</strong>
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="subtitle2">
                  <strong>누적 부가세</strong>
                </Typography>
              </TableCell>{' '}
              <TableCell align="center">
                <Typography variant="subtitle2">
                  <strong>월 총 합계</strong>
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="subtitle2">
                  <strong>월 정산</strong>
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="subtitle2">
                  <strong>월 미정산</strong>
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="subtitle2">
                  <strong>월 부가세</strong>
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow sx={{ backgroundColor: 'primary.lighter' }}>
              <TableCell align="center">
                <Typography variant="subtitle2">
                  <strong>매출</strong>
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="subtitle2">{accumulationIncome} 원</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="subtitle2">{accumulationVatIncome} 원</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="subtitle2">{inComeTotal} 원</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="subtitle2">{excclcKndyInCome} 원</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="subtitle2">{excclcKndnInCome} 원</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="subtitle2">{InComevatTotal} 원</Typography>
              </TableCell>
            </TableRow>
            <TableRow sx={{ backgroundColor: 'error.lighter' }}>
              <TableCell align="center">
                <Typography variant="subtitle2">
                  <strong>매입</strong>
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="subtitle2">{accumulationOutcome} 원</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="subtitle2">-{accumulationVatOutcome} 원</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="subtitle2">{outComeTotal} 원</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="subtitle2">{excclcKndyOutCome} 원</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="subtitle2">{excclcKndnOutCome} 원</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="subtitle2">-{OutComevatTotal} 원</Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </RootStyle>
  );
}
