import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// import { useNavigate } from 'react-router-dom';
// material
import axios from 'axios';
import { Stack, TextField, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
// import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------
const BASE_URL = process.env.REACT_APP_API_URL;

export default function FindPasswordForm() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [sendUserId, setSendUserId] = useState();
  const [sendEmail, setSendEmail] = useState();
  const [sendMberNm, setSendMberNm] = useState();
  const [certificationNo, setCertificationNo] = useState();

  const CheckSchema = Yup.object().shape({
    id: Yup.string().required('아이디를 입력해주세요'),
    username: Yup.string().required('이름을 입력해주세요'),
    email: Yup.string().email('Email 주소가 아닙니다').required('Email을 입력해주세요')
  });

  const formik = useFormik({
    initialValues: {
      id: '',
      username: '',
      companyName: '',
      email: ''
    },
    validationSchema: CheckSchema,
    onSubmit: (value) => {
      // navigate('/', { replace: true });
      setSendUserId(value.id);
      setSendMberNm(value.username);
      setSendEmail(value.email);
      requestCertificationNo(value);
    }
  });

  const requestCertificationNo = async (value) => {
    const apiUrl = `${BASE_URL}/member/requestCrtfckey`;
    const sendParams = {
      // username: value.username,
      email: value.email
    };
    axios
      // .post(apiUrl, sendParams, headers)
      .post(apiUrl, sendParams)
      .then((response) => {
        console.log(response.data);
        if (response.data.code === '200') {
          window.alert('Email로 인증번호를 전송하였습니다');
          setShow(true);
        } else if (response.data.code === '500') {
          // setError(response.data.reason);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const findPasswordApi = async () => {
    const apiUrl = `${BASE_URL}/member/findByPasswd`;
    const sendParams = {
      crtfckey: certificationNo,
      mberNm: sendMberNm,
      id: sendUserId
    };
    axios
      // .post(apiUrl, sendParams, headers)
      .post(apiUrl, sendParams)
      .then((response) => {
        console.log(response.data);
        if (response.data.code === '200') {
          window.alert('새로운 비밀번호를 Email로 전송하였습니다.');
          setCertificationNo();
          setSendEmail();
          navigate('/login');
        } else if (response.data.code === '500') {
          window.alert(response.data.desc);
          navigate('/login');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleSendCrtfcKey = () => {
    findPasswordApi();
  };

  const onChangeCertificationNo = (e) => {
    setCertificationNo(e.target.value);
  };
  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={1}>
          <TextField
            size="small"
            label="ID"
            {...getFieldProps('id')}
            error={Boolean(touched.id && errors.id)}
            helperText={touched.id && errors.id}
          />

          <TextField
            size="small"
            autoComplete="username"
            type="name"
            label="이름"
            {...getFieldProps('username')}
            error={Boolean(touched.username && errors.username)}
            helperText={touched.username && errors.username}
          />

          <TextField
            size="small"
            autoComplete="email"
            type="email"
            label="이메일"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
          {show && (
            <Stack direction="column" justifyContent="space-between" spacing={1}>
              <TextField
                size="small"
                // autoComplete="email"
                type="certicationNo"
                label="인증번호"
                {...getFieldProps('certificationNo')}
                onChange={onChangeCertificationNo}
                // error={Boolean(touched.email && errors.email)}
                helperText="Timer"
              />
              <Stack direction="row" justifyContent="space-between" spacing={0.5}>
                <Button size="small" variant="contained" onClick={handleSendCrtfcKey}>
                  전송
                </Button>
                <Button size="small" variant="contained" onClick={requestCertificationNo}>
                  인증번호다시받기
                </Button>
              </Stack>
            </Stack>
          )}
          <LoadingButton
            fullWidth
            size="medium"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            비밀번호 찾기
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
