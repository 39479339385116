/* eslint-disable prettier/prettier */
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import Register from './pages/Register';
import CompanyRegister from './pages/CompanyRegister';
import DashboardApp from './pages/DashboardApp';
import Construction from './pages/Construction';
import UpdateMyInformation from './pages/UpdateMyInformation';
// import ConstructionTable from './pages/ConstructionTable';
import RecruitmentNotice from './pages/RecruitmentNotice';
import SettlementOfExpenses from './pages/SettlementOfExpenses';
import ConstructorRegistration from './pages/ConstructorRegistration';
import ConstructorRegistrationTable from './pages/ConstructorRegistrationTable';
import TermsOfService from './pages/TermsOfService';
import TermsOfLocationInfo from './pages/TermsOfLocationInfo';
import TermsOfPersonalInfo from './pages/TermsOfPersonalInfo';
import ALTermsOfService from './pages/ALTermsOfService';
import ALTermsOfLocationInfo from './pages/ALTermsOfLocationInfo';
import ALTermsOfPersonalInfo from './pages/ALTermsOfPersonalInfo';
// import DataGrid from './pages/DataGrid';
// import Blog from './pages/Blog';
import User from './pages/User';
import NotFound from './pages/Page404';
import FindIdPassword from './pages/FindIdPassword';
// import Withdrawal from './pages/Withdrawal';

// ----------------------------------------------------------------------

export default function Router() {  
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: 'app', element: <DashboardApp /> },
        { path: 'user', element: <User /> },
        { path: 'Construction', element: <Construction /> },
        { path: 'RecruitmentNotice', element: <RecruitmentNotice /> },
        { path: 'SettlementOfExpenses', element: <SettlementOfExpenses /> },
        { path: 'ConstructorRegistration', element: <ConstructorRegistration /> },
        { path: 'UpdateMyInformation', element: <UpdateMyInformation /> },
        { path: 'ALTermsOfService', element: <ALTermsOfService /> },
        { path: 'ALTermsOfLocationInfo', element: <ALTermsOfLocationInfo /> },
        { path: 'ALTermsOfPersonalInfo', element: <ALTermsOfPersonalInfo /> },
        // { path: 'Withdrawal', element: <Withdrawal /> }
        // { path: 'blog', element: <Blog /> }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/dashboard/app" /> },
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: 'companyRegister', element: <CompanyRegister /> },
        { path: 'findIdPassword', element: <FindIdPassword /> },        
        { path: 'ConstructorRegistrationTable', element: <ConstructorRegistrationTable /> },
        { path: 'ConstructorRegistration', element: <ConstructorRegistration /> },
        { path: 'TermsOfService', element: <TermsOfService /> },
        { path: 'TermsOfLocationInfo', element: <TermsOfLocationInfo /> },
        { path: 'TermsOfPersonalInfo', element: <TermsOfPersonalInfo /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replaceto='/dashboard'/> }
  ]);
}
