import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
// import { Box, Card, Link, Container, Typography } from '@mui/material';
import { Box, Link, Container, Typography } from '@mui/material';
// layouts
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { CompanyRegisterForm } from '../sections/authentication/register';
// import AuthSocial from '../sections/authentication/AuthSocial';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

// const SectionStyle = styled(Card)(({ theme }) => ({
//   width: '100%',
//   maxWidth: 464,
//   display: 'flex',
//   flexDirection: 'column',
//   justifyContent: 'center',
//   margin: theme.spacing(2, 0, 2, 2)
// }));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function CompanyRegister() {
  return (
    <RootStyle title="기업회원가입">
      <AuthLayout>
        <Link underline="none" variant="subtitle2" component={RouterLink} to="/">
          홈으로
        </Link>
        이미 가입을 하셨다면? &nbsp;
        <Link underline="none" variant="subtitle2" component={RouterLink} to="/login">
          로그인
        </Link>
      </AuthLayout>

      <Container>
        <ContentStyle>
          <Box sx={{ mb: 5 }}>
            <Typography align="center" variant="h3" gutterBottom>
              기업 회원가입
            </Typography>
          </Box>

          {/* <AuthSocial /> */}

          <CompanyRegisterForm />
          <Typography
            variant="subtitle2"
            sx={{
              mt: 3,
              textAlign: 'center',
              display: { sm: 'none' }
            }}
          >
            이미 가입이 되어있으시다면?&nbsp;
            <Link underline="hover" to="/login" component={RouterLink}>
              로그인
            </Link>
          </Typography>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
