import { useCallback, useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import {
  Stack,
  Link,
  Typography,
  TextField,
  Button,
  Box,
  Divider,
  Grid,
  Paper
} from '@mui/material';
// import { Card, Stack, Link, Container, Typography } from '@mui/material';
// layouts
import axios from 'axios';
import HomeIcon from '@mui/icons-material/Home';
import SvgIcon from '@mui/material/SvgIcon';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { AppRecrumentNotice } from '../sections/@dashboard/app';
// import { LoginForm } from '../sections/authentication/login';
import Image from '../imgs/architecture-1541086_1920.jpg';
// import { ReactComponent as LogoIcon } from '../imgs/logo_txt.png';
import LogoIcon from '../imgs/logo_txt_negative.png';
import NoTitleTermsOfService from '../termsOfService/NoTitleTermsOfService';
import NoTitleTermsOfPersonalInfo from '../termsOfService/NoTitleTermsOfPersonalInfo';
import NoTitleTermsOfLocationInfo from '../termsOfService/NoTitleTermsOfLocationInfo';
import Footer from '../sections/footer/footer';
import LogoIcon2 from '../imgs/logo_img.png';

const BASE_URL = process.env.REACT_APP_API_URL;

const styles = {
  paperContainer: {
    height: '100%',
    // width: 'flex',
    backgroundImage: `url(${Image})`,
    opacity: 0.9
  }
};

// --------------------------------------------------------------------------------------------------------------
export default function Login() {
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('mberNm');
  sessionStorage.removeItem('mberEmail');
  const navigate = useNavigate();
  const [bottomValue, setBottomValue] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [userId, setUserId] = useState();
  const [userPassword, setUserPassword] = useState();
  const [error, setError] = useState('');
  const [loginStateCode, setLoginStateCode] = useState();
  const [serviceInfoOpen, setServiceInfoOpen] = useState(false);
  const [personalInfoOpen, setPersonalInfoOpen] = useState(false);
  const [locationInfoOpen, setLocationInfoOpen] = useState(false);
  // -----------------------------------------------API 호출-----------------------------------------------------
  const loginApi = async () => {
    const apiUrl = `${BASE_URL}/login`;
    const sendParams = {
      id: userId,
      pw: userPassword
    };
    axios
      // .post(apiUrl, sendParams, headers)
      .post(apiUrl, sendParams)
      .then((response) => {
        // console.log(response.data);
        sessionStorage.setItem('token', response.data.token);
        sessionStorage.setItem('Bearer', response.data.token);
        sessionStorage.setItem('id', response.data.id);
        sessionStorage.setItem('ncm', response.data.ncm);
        sessionStorage.setItem('email', response.data.email);
        sessionStorage.setItem('passwordInitlYn', response.data.passwordInitlYn);
        if (response.data.connect === 'SUCCESS') {
          // navigate('/dashboard/app');
          navigate('/dashboard/RecruitmentNotice');
        } else if (response.data.connect === 'FAIL') {
          // setError(response.data.reason);
          setError('아이디와 비밀번호를 확인해주세요!');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // -----------------------------------------------API 호출-----------------------------------------------------
  // const handleShowPassword = () => {
  //   setShowPassword((show) => !show);
  // };

  const onChangeUserid = (e) => {
    setUserId(e.target.value);
    setError('');
  };
  const onChangePassword = (e) => {
    setUserPassword(e.target.value);
    setError('');
  };
  const onClickLogin = () => {
    if (
      userId === '' ||
      userPassword === '' ||
      userId === undefined ||
      userPassword === undefined
    ) {
      setError('아이디 또는 비밀번호가 필요합니다.');
      return;
    }
    loginApi();
  };
  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      onClickLogin();
    }
  };
  // -----------------------------------------------------------------------------------
  const handleServiceInfoOpen = () => {
    setServiceInfoOpen(true);
  };
  const handleServiceInfoClose = () => {
    setServiceInfoOpen(false);
  };
  const handlePersonalInfoOpen = () => {
    setPersonalInfoOpen(true);
  };
  const handlePersonalInfoClose = () => {
    setPersonalInfoOpen(false);
  };
  const handleLocationInfoOpen = () => {
    setLocationInfoOpen(true);
  };
  const handleLocationInfoClose = () => {
    setLocationInfoOpen(false);
  };
  // -----------------------------------------------------------------------------------
  useEffect(() => {
    if (sessionStorage.getItem('token') === undefined || sessionStorage.getItem('token') === null) {
      navigate('/login');
    } else if (
      sessionStorage.getItem('token') !== undefined ||
      sessionStorage.getItem('token') !== null
    ) {
      loginApi();
    }
  }, []);
  return (
    <Page style={styles.paperContainer}>
      <AuthLayout>
        <Stack direction="row" justifyContent="space-between" spacing={1} sx={{ my: 1 }}>
          <Link underline="none" variant="subtitle2" component={RouterLink} to="/">
            <HomeIcon label="홈" size="large" style={{ color: 'white', fontSize: '32px' }} />
          </Link>
          {/* <Link
            underline="none"
            variant="subtitle2"
            label="App Download"
            // color="inherit"
            href="https://play.google.com/store/apps/details?id=com.yeonisoft.geondal"
          >
            <img
              className="thumbnail"
              alignItems="center"
              width="25%"
              height="55%"
              src={LogoIcon2}
              alt="cat"
            />
            <strong>App DownLoad</strong>
          </Link> */}
        </Stack>
      </AuthLayout>
      <Box
        style={{
          borderRadius: 10,
          position: 'absolute',
          left: '50%',
          top: '45%',
          transform: 'translate(-50%, -50%)'
        }}
        // minHeight="100vh"
        display="flex"
        justifyContent="center"
        sx={{
          // opacity: 0.5,
          width: 800,
          height: 340,
          backgroundColor: 'white'
          // '&:hover': {
          //   backgroundColor: 'white',
          // opacity: [0.9, 0.9, 1]
          // }
        }}
      >
        <Grid container>
          <Grid item xs={12} md={6} lg={7}>
            <Box m={1} pt={1}>
              <AppRecrumentNotice />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={5}>
            <Box m={1} pt={3}>
              {/* <Typography align="center" variant="h4">
              로그인
            </Typography> */}
              <Stack alignItems="center">
                <img className="thumbnail" width="40%" height="10%" src={LogoIcon} alt="cat" />
              </Stack>
              <Stack padding={3} spacing={1}>
                <form>
                  <Stack spacing={1}>
                    <TextField
                      size="small"
                      autoComplete="id"
                      label="아이디"
                      onChange={onChangeUserid}
                    />
                    <TextField
                      size="small"
                      autoComplete="current-password"
                      type={showPassword ? 'text' : 'password'}
                      label="비밀번호"
                      onChange={onChangePassword}
                      onKeyPress={onKeyPress}
                    />
                  </Stack>
                </form>

                <Button
                  type="submit"
                  variant="contained"
                  // loading={isSubmitting}
                  onClick={onClickLogin}
                >
                  로그인
                </Button>
                <Stack>
                  <Typography align="center" variant="body2">
                    {error}
                  </Typography>
                </Stack>

                <Stack justifyContent="space-between" direction="row">
                  <Link underline="none" variant="subtitle2" component={RouterLink} to="/register">
                    회원가입
                  </Link>
                  <Link
                    underline="none"
                    variant="subtitle2"
                    component={RouterLink}
                    to="/findIdPassword"
                  >
                    ID/PW 찾기
                  </Link>
                </Stack>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="center" spacing={5}>
              <Button size="small" onClick={handleServiceInfoOpen}>
                서비스 이용약관
              </Button>
              <Dialog
                open={serviceInfoOpen}
                onClose={handleServiceInfoClose}
                PaperProps={{ sx: { width: '85%', height: '80%' } }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle align="center">서비스 이용약관</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <NoTitleTermsOfService />
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleServiceInfoClose}>닫기</Button>
                </DialogActions>
              </Dialog>
              <Button size="small" onClick={handlePersonalInfoOpen}>
                개인정보 처리방침
              </Button>
              <Dialog
                open={personalInfoOpen}
                onClose={handlePersonalInfoClose}
                PaperProps={{ sx: { width: '85%', height: '80%' } }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle align="center">개인정보 처리방침</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <NoTitleTermsOfPersonalInfo />
                    <Divider />
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handlePersonalInfoClose}>닫기</Button>
                </DialogActions>
              </Dialog>
              <Button size="small" onClick={handleLocationInfoOpen}>
                위치기반 서비스 이용약관
              </Button>
              <Dialog
                open={locationInfoOpen}
                onClose={handleLocationInfoClose}
                PaperProps={{ sx: { width: '85%', height: '80%' } }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle align="center">위치기반 서비스 이용약관</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <NoTitleTermsOfLocationInfo />
                    <Divider />
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleLocationInfoClose}>닫기</Button>
                </DialogActions>
              </Dialog>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Paper
        sx={{ position: 'fixed', bottom: 0, left: 1, right: 0 }}
        elevation={10}
        // backgroundcolor="black"
      >
        {/* <BottomNavigation
          showLabels
          value={bottomValue}
          onChange={(event, newValue) => {
            setBottomValue(newValue);
          }}
        >
          <BottomNavigationAction label="경기도 의왕시 이미로 40 인덕원아이티밸리 C동 706-2호" />
          <BottomNavigationAction label="TEL : 031-8084-3476" />
          <BottomNavigationAction label="(주)yeonisoft Co.Ltd" />
          {/* <BottomNavigationAction label="Contact Us" icon={<CallRoundedIcon />} /> */}
        {/* </BottomNavigation> */}
        <Grid container spacing={2}>
          <Grid item xs={16} lg={4} />
          <Grid item xs={16} lg={4}>
            <Footer />
          </Grid>
          <Grid item xs={16} lg={4} />
        </Grid>
      </Paper>
    </Page>
  );
}
