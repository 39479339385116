import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import * as Yup from 'yup';
import { Stack, Button, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { LoadingButton } from '@mui/lab';
// components
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

const BASE_URL = process.env.REACT_APP_API_URL;

function ChangePassWordPopup({ changePasswordOpen, handleChangePasswordDialogClose }) {
  const navigate = useNavigate();
  // const [changePasswordOpen, setChangePasswordOpen] = useState(false);
  const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,24}$/;
  // --------------------------------------------------------------------------------
  const newPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required('비밀번호를 입력해주세요')
      .min(7, '8자 이상 입력해주세요')
      .max(24, '24자 이하로 입력해주세요')
      .matches(passwordRegex, {
        message: '숫자+영문자+특수문자 조합으로 8자리 이상 입력해주세요!'
      }),
    passwordCheck: Yup.string()
      .required('비밀번호를 한번 더 입력해주세요')
      .oneOf([Yup.ref('password'), null], '비밀번호가 일치하지 않습니다.')
  });
  const formik = useFormik({
    initialValues: {
      id: '',
      password: '',
      passwordCheck: ''
    },
    validationSchema: newPasswordSchema,
    onSubmit: (values) => {
      ChangePasswordApi(values);
    }
  });
  // 비밀번호 변경 API
  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  const ChangePasswordApi = async (values) => {
    const apiUrl = `${BASE_URL}/member/updMemberPasswd`;
    const headers = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    };
    const sendParams = {
      id: sessionStorage.getItem('id'),
      pw: values.password
    };
    axios
      .post(apiUrl, sendParams, headers)
      .then((response) => {
        // console.log(response.data);
        if (response.data.code === '200') {
          window.alert('변경되었습니다.');
          handleChangePasswordDialogClose();
        } else {
          window.alert('변경에 실패하였습니다.');
        }
        // setMyWorkList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // ---------------------------------------------------------------------------------
  return (
    <FormikProvider value={formik}>
      <Dialog
        open={changePasswordOpen}
        onClose={handleChangePasswordDialogClose}
        PaperProps={{ sx: { width: '20%', height: '40%' } }}
      >
        <DialogTitle align="center">비밀번호 재설정</DialogTitle>
        <br />
        <DialogContent>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack direction="column" justifyContent="space-between" spacing={2}>
              <TextField
                fullWidth
                size="small"
                autoComplete="new-password"
                // type={showPassword ? 'text' : 'password'}
                type="password"
                label="비밀번호"
                {...getFieldProps('password')}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
                // onChange={validate}
                value={formik.values.password}
              />
              <TextField
                fullWidth
                size="small"
                autoComplete="new-password"
                // type={showPassword ? 'text' : 'password'}
                type="password"
                label="비밀번호 확인"
                {...getFieldProps('passwordCheck')}
                error={Boolean(touched.passwordCheck && errors.passwordCheck)}
                helperText={touched.passwordCheck && errors.passwordCheck}
                // onChange={onChangeCheckPw}
                value={formik.values.passwordCheck}
              />
            </Stack>
            <br />
            <Typography variant="body2">
              ※ 비밀번호는 최소 8자 이상 최대 24자 이하
              <br />
              ※ 숫자/영어 대소문자/특수문자 모두 포함
              <br />
              ※ 사용가능 특수문자 : ! @ # $ % ^ & + =
              <br />
            </Typography>
            <br />

            <Stack direction="row" justifyContent="center" spacing={1}>
              <LoadingButton
                size="medium"
                type="submit"
                variant="contained"
                // disabled={!isValid || handleSubmit}
                loading={isSubmitting}
              >
                저장
              </LoadingButton>
              <Button variant="contained" onClick={handleChangePasswordDialogClose}>
                닫기
              </Button>
            </Stack>
          </Form>
        </DialogContent>
      </Dialog>
    </FormikProvider>
  );
}

export default ChangePassWordPopup;
