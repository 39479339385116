// import { useState } from 'react';
import React, { useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
// import { Box, Card, Link, Container, Typography } from '@mui/material';
import { Box, Link, Container, Typography, Stack, TextField, Button, Grid } from '@mui/material';
// layouts
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
// import {
//   UpdateRegisterForm,
//   UpdateRegisterCertificateForm,
//   UpdateRegisterEquipmentForm
// } from '../sections/authentication/register';

import {
  UpdateRegisterForm,
  UpdateRegisterCertificateForm,
  UpdateRegisterEquipmentForm
} from '../sections/authentication/register';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  // marginLeft: '35%',
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(1, 0)
}));

const ContentStyle2 = styled('div')(({ theme }) => ({
  // maxWidth: 600,
  // marginright: '35%',
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(1, 0)
}));
// --------------------------------------HEAD-----------------------------------------
const MY_CERTICATION_HEAD = [
  {
    field: 'id',
    headerName: '번호',
    align: 'center',
    width: 10,
    // TEST
    valueGetter: (params) => params.row.crqfcNo
  },
  { field: 'crqfcNm', headerName: '자격증명', alignRight: false, width: 150 },
  { field: 'crllqcNo', headerName: '자격증 번호', alignRight: false, width: 200 },
  { field: 'crqfcIssude', headerName: '발급일', type: 'date', alignRight: false },
  { field: 'crqgcAcqstr', headerName: '취득일', type: 'date', alignRight: false },
  { field: 'crqfcIssunt', headerName: '발급기관', alignRight: false, width: 250 },
  { field: 'crqfcNo', headerName: 'workNo', alignRight: false, hide: true }
];
// -------------------------------------------------------------------------------

export default function UpdateMyInformation() {
  // -----------------------------------------------------------------------------
  // const { errors, touched, handleSubmit, isSubmitting } = formik;
  const navigate = useNavigate();
  useEffect(() => {
    // 토큰 값이 없을 경우 Login Page로 이동
    if (sessionStorage.getItem('token') === undefined || sessionStorage.getItem('token') === null) {
      navigate('/login');
    }
  }, []);

  return (
    <RootStyle title="개인정보">
      <Grid container spacing={0.5}>
        <Grid item xs={6} md={4}>
          <Box padding={1}>
            <Box sx={{ mb: 1 }}>
              <Typography align="center" variant="h3" gutterBottom>
                회원정보
              </Typography>
            </Box>
            <UpdateRegisterForm />
          </Box>
        </Grid>
        <Grid item xs={6} md={8}>
          <Container>
            <Box>
              <Box sx={{ mb: 1 }}>
                <Typography align="center" variant="h4" gutterBottom>
                  자격증 및 보유장비 현황
                </Typography>
              </Box>

              <UpdateRegisterCertificateForm />
              <br />
              <UpdateRegisterEquipmentForm />
            </Box>
          </Container>
        </Grid>
      </Grid>
    </RootStyle>
  );
}
