import { useRef, useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Typography } from '@mui/material';
import axios from 'axios';
// components
import Iconify from '../../components/Iconify';
//
// import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
// import LanguagePopover from './LanguagePopover';
import NotificationsPopover from './NotificationsPopover';

const BASE_URL = process.env.REACT_APP_API_URL;
// ----------------------------------------------------------------------
// API 정보
// const BASE_URL = 'https://site.yeonisoft.com/';
// const BASE_URL = 'https://apidev.yeonisoft.com/';
// const BASE_URL = 'http://localhost:8080/';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 200;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72), // 상단검색바 투명색 option
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5) // profile 아이콘 위치 여백
  }
}));
// ------------------------------------------------------------------------------------------------------
// ----------------------------------------------------------------------
DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const navigate = useNavigate();
  useEffect(() => {
    if (sessionStorage.getItem('token') === undefined || sessionStorage.getItem('token') === null) {
      sessionStorage.clear();
      // navigate('/dashboard/app');
      navigate('/login');
    } else if (
      sessionStorage.getItem('token') !== undefined ||
      sessionStorage.getItem('token') !== null
    ) {
      // getMemberInfo();
    }
  }, [sessionStorage.getItem('token')]);
  // ----------------------------------------------------------------------
  return (
    <RootStyle>
      <ToolbarStyle>
        <IconButton
          onClick={onOpenSidebar}
          sx={{ mr: 1, color: 'text.primary', display: { lg: 'none' } }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

        {/* <Searchbar /> */}
        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="column" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          {/* <LanguagePopover /> */}
          {/* <NotificationsPopover /> */}
          <AccountPopover />
          <Typography variant="body1" sx={{ color: 'text.secondary' }} noWrap>
            {sessionStorage.getItem('ncm')}
          </Typography>
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
