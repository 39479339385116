import * as Yup from 'yup';
import { useState, useRef, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import axios from 'axios';
import { Stack, TextField, Typography, Checkbox, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import NoTitleTermsOfService from '../../../termsOfService/NoTitleTermsOfService';
import NoTitleTermsOfPersonalInfo from '../../../termsOfService/NoTitleTermsOfPersonalInfo';
import NoTitleTermsOfLocationInfo from '../../../termsOfService/NoTitleTermsOfLocationInfo';
// component
// ----------------------------------------------------------------------
// API 정보
const BASE_URL = process.env.REACT_APP_API_URL;

export default function RegisterForm() {
  const navigate = useNavigate();
  // const [showPassword, setShowPassword] = useState(false);
  const [certifiData, setCertifiData] = useState();
  const [checkid, setCheckId] = useState(false);
  const [checkNickname, setCheckNickname] = useState(false);
  const [checkAllInfo, setCheckAllInfo] = useState(false); // 전체동의
  const [checkServiceInfo, setCheckServiceInfo] = useState(false);
  const [checkPersonalInfo, setCheckPersonalInfo] = useState(false);
  const [checkLocationInfo, setCheckLocationInfo] = useState(false);
  const [serviceInfoOpen, setServiceInfoOpen] = useState(false);
  const [personalInfoOpen, setPersonalInfoOpen] = useState(false);
  const [locationInfoOpen, setLocationInfoOpen] = useState(false);
  const [checkEmail, setCheckEmail] = useState(false);
  // const [scroll, setScroll] = useState < DialogProps.scroll > 'paper';
  const idInput = useRef();
  const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,24}$/;
  const idRegex = /^(?=.*[a-z])(?=.*[^A-Z0-9]).{4,12}$/;
  const phoneRegex = /^(?=.*[0-9]).{9,11}$/;
  const nameRegex = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
  const companyRegex = /([0-9]{3})-?([0-9]{2})-?([0-9]{5})/;
  // ---------------------------------------------------------------------------------
  // ID 중복확인 API
  const checkIdApi = async (checkid) => {
    const apiUrl = `${BASE_URL}/member/checkId`;
    const sendParams = {
      id: checkid
    };
    axios
      .post(apiUrl, sendParams)
      .then((response) => {
        console.log(response.data);
        if (response.data.code === '200') {
          setCheckId(true);
        } else if (response.data.code === '500') {
          setCheckId(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return checkid;
  };
  // 닉네임 중복확인 API
  const checkNicknameApi = async (checkNickname) => {
    const sendParams = {
      // ncm: checkNickname
      param: checkNickname
    };
    const apiUrl = `${BASE_URL}/member/checkNcm`;
    axios
      .post(apiUrl, sendParams)
      .then((response) => {
        console.log(response.data);
        if (response.data.code === '200') {
          setCheckNickname(true);
        } else if (response.data.code === '500') {
          setCheckNickname(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return checkNickname;
  };
  const RegisterSchema = Yup.object().shape({
    id: Yup.string()
      .required('아이디를 입력해주세요')
      .min(4, '4자 이상 입력해주세요')
      .max(12, '12자 이하로 입력해주세요')
      .matches(idRegex, { message: '영어 대/소문자, 숫자만 가능합니다' })
      .test('checkId', '이미사용중인 아이디 입니다.', (value) => {
        checkIdApi(value);
        if (checkid === false) {
          return false;
        }
        if (checkid === true) {
          return true;
        }
      }),
    username: Yup.string()
      .required('이름을 입력해주세요')
      .matches(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/, { message: '한글만 입력가능합니다' }),
    ncm: Yup.string()
      .required('닉네임을 입력해주세요')
      .min(2, '2자이상 입력해주세요')
      .test('checkNickname', '이미사용중인 닉네임 입니다.', (value) => {
        checkNicknameApi(value);
        if (checkNickname === false) {
          return false;
        }
        if (checkNickname === true) {
          return true;
        }
      }),
    email: Yup.string().email('Email 주소가 아닙니다').required('Email을 입력해주세요'),
    phoneNum: Yup.string()
      .required('전화번호를 입력해주세요')
      .min(10, '형식이 맞지 않습니다')
      .max(13, '형식이 맞지 않습니다')
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        { message: '형식이 맞지 않습니다' }
      ),
    password: Yup.string()
      .required('비밀번호를 입력해주세요')
      .min(7, '8자 이상 입력해주세요')
      .max(24, '24자 이하로 입력해주세요')
      .matches(passwordRegex, {
        message: '숫자+영문자+특수문자 조합으로 8자리 이상 입력해주세요!'
      }),
    passwordCheck: Yup.string()
      .required('비밀번호를 한번 더 입력해주세요')
      .oneOf([Yup.ref('password'), null], '비밀번호가 일치하지 않습니다.')
  });
  const formik = useFormik({
    initialValues: {
      id: '',
      username: '',
      ncm: '',
      phoneNum: '',
      email: '',
      companyNo: '',
      password: '',
      passwordCheck: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: (values) => {
      saveApiCall(values);
    }
  });
  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  // -------------------------------------------------------------------------------------
  const saveApiCall = async (value) => {
    const sendParamCrqfclist = {
      crqfclist: [
        {
          aptdchkppdBgnde: '',
          aptdchkppdEnnde: '',
          crllqcNo: '',
          crqfcAcqstr: '',
          crqfcIssude: '',
          crqfcIssuno: '',
          crqfcIssunt: '',
          crqfcNm: '',
          crqfcRgsde: '',
          delYn: '',
          id: '',
          rgsdt: '',
          upddt: ''
        }
      ]
    };
    const sendParamEqpmnlist = {
      eqpmnlist: [
        {
          delYn: '',
          eqpmnKnd: '',
          eqpmnNm: '',
          eqpmnNoplt: '',
          eqpmnQy: '',
          id: '',
          rgsdt: '',
          upddt: ''
        }
      ]
    };
    // 회원가입 API
    const apiUrl = `${BASE_URL}/member/regMember`;
    const sendParams = {
      mberNm: value.username,
      id: value.id,
      ncm: value.ncm,
      password: value.password,
      mbtlnum: value.phoneNum,
      mberEmailAdres: value.email,
      bizrno: value.companyNo,
      sendParamCrqfclist,
      sendParamEqpmnlist
    };
    axios
      // .post(apiUrl, sendParams, headers)
      .post(apiUrl, sendParams)
      .then((response) => {
        console.log(response.data);
        if (response.data.code === '200') {
          window.alert('회원가입 되셨습니다\n회원정보에서 보유자격증과 보유장비를 등록해주세요!');
          navigate('/login');
        }
        if (response.data.code === '500') {
          window.alert('회원가입에 실패하였습니다.');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // email 중복확인 API
  const checkEmailApi = async () => {
    // const email = ;
    const apiUrl = `${BASE_URL}/member/chkEmail`;
    const sendParams = {
      param: getFieldProps('email').value
    };
    axios
      .post(apiUrl, sendParams)
      .then((response) => {
        console.log(response.data);
        if (response.data.code === '200') {
          window.alert('가능한 Email 입니다.');
          setCheckEmail(true);
        } else if (response.data.code === '500') {
          window.alert('이미 가입된 Email 입니다.');
          setCheckEmail(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    // return checkid;
  };
  // --------------------------------------------------------------------------------
  const handleServiceInfoOpen = () => {
    setServiceInfoOpen(true);
  };
  const handleServiceInfoClose = () => {
    setServiceInfoOpen(false);
  };
  const handlePersonalInfoOpen = () => {
    setPersonalInfoOpen(true);
  };
  const handlePersonalInfoClose = () => {
    setPersonalInfoOpen(false);
  };
  const handleLocationInfoOpen = () => {
    setLocationInfoOpen(true);
  };
  const handleLocationInfoClose = () => {
    setLocationInfoOpen(false);
  };
  const handleCheckAllInfo = (e) => {
    console.log(e.target.checked);
    setCheckAllInfo(e.target.checked);
    if (e.target.checked === true) {
      setCheckServiceInfo(true);
      setCheckPersonalInfo(true);
      setCheckLocationInfo(true);
    } else {
      setCheckServiceInfo(false);
      setCheckPersonalInfo(false);
      setCheckLocationInfo(false);
    }
  };
  const handleCheckServiceInfo = (e) => {
    // console.log(e.target.checked);
    setCheckServiceInfo(e.target.checked);
  };
  const handleCheckPersonalInfo = (e) => {
    setCheckPersonalInfo(e.target.checked);
  };
  const handleCheckLocationInfo = (e) => {
    setCheckLocationInfo(e.target.checked);
  };
  // 서비스 이용약관
  const handleClickAgreeService = () => {
    setCheckServiceInfo(true);
    handleServiceInfoClose();
  };
  // 개인정보수집 동의
  const handleClickAgreePersonal = () => {
    setCheckPersonalInfo(true);
    handlePersonalInfoClose();
  };
  // 위치정보수집 동의
  const handleClickAgreeLocation = () => {
    setCheckLocationInfo(true);
    handleLocationInfoClose();
  };
  const moveToLogin = () => {
    navigate('/login');
  };
  const handleCheckEmail = () => {
    if (checkEmail === true) {
      setCheckEmail(false);
    }
  };
  // --------------------------------------------------------------------------------
  const regValidationCheck = () => {
    // if (checkEmail === false) {
    //   window.alert('이메일 중복확인은 필수입니다.');
    //   return false;
    // }
    if (checkServiceInfo === false) {
      window.alert('서비스 이용약관 동의는 필수입니다.');
      return false;
    }
    if (checkPersonalInfo === false) {
      window.alert('개인정보수집 동의는 필수입니다.');
      return false;
    }
    if (checkLocationInfo === false) {
      window.alert('위치정보수집 동의는 필수입니다.');
      return false;
    }
    return true;
  };
  // --------------------------------------------------------------------------------
  // useEffect
  useEffect(() => {}, []);
  // Debounce 함수 적용 - 나중에 적용
  const debounceFunction = (callback, delay) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => callback(...args), delay);
    };
  };
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={2}>
          <TextField
            fullWidth
            size="small"
            label="ID - 중복체크는 자동입니다."
            {...getFieldProps('id')}
            error={Boolean(touched.id && errors.id)}
            helperText={touched.id && errors.id}
            // value={formik.values.id}
            // ref={idInput}
          />
          <TextField
            fullWidth
            size="small"
            label="닉네임 - 중복체크는 자동 입니다."
            {...getFieldProps('ncm')}
            error={Boolean(touched.ncm && errors.ncm)}
            helperText={touched.ncm && errors.ncm}
            // onChange={onChangeNickname}
            // value={formik.values.ncm}
          />
          <TextField
            fullWidth
            size="small"
            autoComplete="username"
            type="username"
            label="이름"
            {...getFieldProps('username')}
            error={Boolean(touched.username && errors.username)}
            helperText={touched.username && errors.username}
            // onChange={onChangeName}
            // value={formik.values.username}
          />

          <TextField
            fullWidth
            size="small"
            autoComplete="phoneNum"
            label="전화번호"
            {...getFieldProps('phoneNum')}
            error={Boolean(touched.phoneNum && errors.phoneNum)}
            helperText={touched.phoneNum && errors.phoneNum}
            // onChange={onChangeTel}
            value={formik.values.phoneNum}
          />
          <Stack direction="row" justifyContent="space-between" spacing={0.5}>
            <TextField
              fullWidth
              size="small"
              autoComplete="email"
              type="email"
              label="이메일"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
              value={formik.values.email}
              // onChange={handleCheckEmail}
              sx={{ width: '80%' }}
            />
            <Button
              size="small"
              variant="contained"
              sx={{ width: '100px' }}
              onClick={checkEmailApi}
            >
              중복확인
            </Button>
          </Stack>
          <TextField
            size="small"
            autoComplete="companyNo"
            type="companyNo"
            label="사업자번호"
            {...getFieldProps('companyNo')}
            error={Boolean(touched.companyNo && errors.companyNo)}
            helperText={touched.companyNo && errors.companyNo}
            // onChange={onChangeEmail}
            value={formik.values.companyNo}
          />

          <TextField
            fullWidth
            size="small"
            autoComplete="new-password"
            // type={showPassword ? 'text' : 'password'}
            type="password"
            label="비밀번호"
            {...getFieldProps('password')}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
            // onChange={validate}
            value={formik.values.password}
          />
          <TextField
            fullWidth
            size="small"
            autoComplete="new-password"
            // type={showPassword ? 'text' : 'password'}
            type="password"
            label="비밀번호 확인"
            {...getFieldProps('passwordCheck')}
            error={Boolean(touched.passwordCheck && errors.passwordCheck)}
            helperText={touched.passwordCheck && errors.passwordCheck}
            // onChange={onChangeCheckPw}
            value={formik.values.passwordCheck}
          />
          <Typography variant="body1">
            ※ 비밀번호는 최소 8자 이상 최대 24자 이하
            <br />
            ※ 숫자/영어 대소문자/특수문자 모두 포함
            <br />
            ※ 사용가능 특수문자 : ! @ # $ % ^ & + =
            <br />
          </Typography>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={checkAllInfo} onChange={handleCheckAllInfo} />}
              label="전체동의"
            />
            <Divider />
            <Stack direction="row" justifyContent="space-between">
              <FormControlLabel
                control={<Checkbox checked={checkServiceInfo} onChange={handleCheckServiceInfo} />}
                label="[필수] 서비스 이용약관"
              />
              <Button onClick={handleServiceInfoOpen}>보기</Button>
              <Dialog
                open={serviceInfoOpen}
                onClose={handleServiceInfoClose}
                PaperProps={{ sx: { width: '85%', height: '80%' } }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle align="center">서비스 이용약관</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <NoTitleTermsOfService />
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClickAgreeService}>동의</Button>
                  <Button onClick={handleServiceInfoClose}>취소</Button>
                </DialogActions>
              </Dialog>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <FormControlLabel
                control={
                  <Checkbox checked={checkPersonalInfo} onChange={handleCheckPersonalInfo} />
                }
                label="[필수] 개인정보수집 동의"
              />
              <Button onClick={handlePersonalInfoOpen}>보기</Button>
              <Dialog
                open={personalInfoOpen}
                onClose={handlePersonalInfoClose}
                PaperProps={{ sx: { width: '85%', height: '80%' } }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle align="center">개인정보 처리방침</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <NoTitleTermsOfPersonalInfo />
                    <Divider />
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClickAgreePersonal}>동의</Button>
                  <Button onClick={handlePersonalInfoClose}>취소</Button>
                </DialogActions>
              </Dialog>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <FormControlLabel
                control={
                  <Checkbox checked={checkLocationInfo} onChange={handleCheckLocationInfo} />
                }
                label="[필수] 위치정보수집 동의"
              />
              <Button onClick={handleLocationInfoOpen}>보기</Button>
              <Dialog
                open={locationInfoOpen}
                onClose={handleLocationInfoClose}
                PaperProps={{ sx: { width: '85%', height: '80%' } }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle align="center">위치기반 서비스 이용약관</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <NoTitleTermsOfLocationInfo />
                    <Divider />
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClickAgreeLocation}>동의</Button>
                  <Button onClick={handleLocationInfoClose}>취소</Button>
                </DialogActions>
              </Dialog>
            </Stack>
          </FormGroup>
          <Stack direction="row" justifyContent="space-between" spacing={0.5}>
            <LoadingButton
              fullWidth
              size="medium"
              type="submit"
              variant="contained"
              disabled={
                checkServiceInfo === false ||
                checkPersonalInfo === false ||
                checkLocationInfo === false
                // checkEmail === false
              }
              loading={isSubmitting}
              sx={{ width: '80%' }}
            >
              회원가입
            </LoadingButton>
            <Button variant="outlined" sx={{ width: '100px' }} onClick={moveToLogin}>
              취소
            </Button>
          </Stack>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
