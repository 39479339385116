import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import {
  Link,
  Stack,
  // Checkbox,
  TextField,
  IconButton,
  InputAdornment
  // FormControlLabel
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
// component
import Iconify from '../../../components/Iconify';

const BASE_URL = process.env.REACT_APP_API_URL;
// const BASE_URL = 'https://site.yeonisoft.com/';
// const BASE_URL = 'https://apidev.yeonisoft.com/';
// const BASE_URL = 'http://localhost:8080';
console.log(process.env.REACT_APP_API_URL);
// console.log(process.env);
// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [userId, setUserId] = useState();
  const [userPassword, setUserPassword] = useState();
  const [loginStateCode, setLoginStateCode] = useState();

  const LoginSchema = Yup.object().shape({
    id: Yup.string().required('ID가 필요합니다'),
    password: Yup.string().required('비밀번호가 필요합니다')
  });

  const formik = useFormik({
    initialValues: {
      id: userId,
      password: userPassword
      // remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      navigate('/dashboard', { replace: true });
    }
  });

  // -----------------------------------------------API 호출-----------------------------------------------------
  const getData = async () => {
    const apiUrl = `${BASE_URL}/login`;
    const sendParams = {
      ID: userId,
      PW: userPassword
    };
    axios
      .post(apiUrl, sendParams)
      .then((response) => {
        setLoginStateCode(response.data);
        // console.log(response.data);
        // console.log(loginStateCode);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // -----------------------------------------------API 호출-----------------------------------------------------
  // const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;
  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const onChangeUserid = (e) => {
    setUserId(e.target.value);
  };
  const onChangePassword = (e) => {
    setUserPassword(e.target.value);
  };
  const onClickLogin = () => {
    getData();
  };
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="id"
            label="아이디"
            // {...getFieldProps('id')}
            error={Boolean(touched.id && errors.id)}
            helperText={touched.id && errors.id}
            onChange={onChangeUserid}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="비밀번호"
            // {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
            onChange={onChangePassword}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <Link component={RouterLink} variant="subtitle2" to="/findIdPassword" underline="hover">
            아이디 또는 비밀번호를 잃어버리셨다면?
          </Link>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          onClick={onClickLogin}
        >
          로그인
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
