import * as Yup from 'yup';
import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import { Stack, TextField, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

export default function CompanyRegisterForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const RegisterSchema = Yup.object().shape({
    id: Yup.string()
      .min(2, '아이디를 2자이상으로 입력해주세요')
      .max(50, '아이디를 50자이하로 입력해주세요')
      .required('아이디를 입력해주세요'),
    ceoname: Yup.string().required('이름을 입력해주세요'),
    email: Yup.string().email('Email 주소가 아닙니다').required('Email을 입력해주세요'),
    password: Yup.string().required('비밀번호를 입력해주세요'),
    passwordCheck: Yup.string().required('비밀번호를 한번 더 입력해주세요')
  });
  const formik = useFormik({
    initialValues: {
      id: '',
      ceoname: '',
      companyNum: '',
      companyName: '',
      email: '',
      postNum: '',
      address: '',
      detailAddress: '',
      phoneNum: '',
      TelNum: '',
      password: '',
      passwordCheck: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: (values) => {
      console.log(values);
      // navigate('/dashboard', { replace: true });
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={0.5}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={0.5}>
            <TextField
              fullWidth
              label="ID"
              {...getFieldProps('id')}
              error={Boolean(touched.id && errors.id)}
              helperText={touched.id && errors.id}
            />

            <TextField
              fullWidth
              label="회사명"
              {...getFieldProps('companyName')}
              error={Boolean(touched.companyName && errors.companyName)}
              helperText={touched.companyName && errors.companyName}
            />
          </Stack>

          <TextField
            fullWidth
            autoComplete="ceoname"
            type="name"
            label="대표자 이름"
            {...getFieldProps('ceoname')}
            error={Boolean(touched.ceoname && errors.ceoname)}
            helperText={touched.ceoname && errors.ceoname}
          />

          <TextField
            fullWidth
            autoComplete="companyNum"
            type="companyNum"
            label="사업자 등록번호"
            {...getFieldProps('companyNum')}
            error={Boolean(touched.companyNum && errors.companyNum)}
            helperText={touched.companyNum && errors.companyNum}
          />

          <TextField
            fullWidth
            autoComplete="address"
            type="address"
            label="주소"
            {...getFieldProps('address')}
            error={Boolean(touched.address && errors.address)}
            helperText={touched.address && errors.address}
          />

          <TextField
            fullWidth
            autoComplete="detailAddress"
            type="detailAddress"
            label="상세주소"
            {...getFieldProps('detailAddress')}
            error={Boolean(touched.detailAddress && errors.detailAddress)}
            helperText={touched.detailAddress && errors.detailAddress}
          />

          <TextField
            fullWidth
            autoComplete="phoneNum"
            type="phoneNum"
            label="핸드폰"
            {...getFieldProps('phoneNum')}
            error={Boolean(touched.phoneNum && errors.phoneNum)}
            helperText={touched.phoneNum && errors.phoneNum}
          />
          <TextField
            fullWidth
            autoComplete="TelNum"
            type="TelNum"
            label="회사 전화번호"
            {...getFieldProps('TelNum')}
            error={Boolean(touched.TelNum && errors.TelNum)}
            helperText={touched.TelNum && errors.TelNum}
          />
          <TextField
            fullWidth
            autoComplete="email"
            type="email"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="비밀번호"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="비밀번호 확인"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            회원가입
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
