import { filter } from 'lodash';
// import { sentenceCase } from 'change-case';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Table,
  Stack,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@mui/material';

import { DataGrid } from '@mui/x-data-grid';
// import { DataGrid } from '@material-ui/data-grid';
// components
import Page from '../components/Page';
// import Label from '../components/Label';
// import Scrollbar from '../components/Scrollbar';
// import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserMoreMenu, UserSearch } from '../sections/@dashboard/user';
//
// import USERLIST from '../_mocks_/user';

// ----------------------------------------------------------------------
const columns = [
  {
    field: 'no',
    headerName: '번호',
    width: 150,
    editable: true
  },
  {
    field: 'name',
    headerName: '이름',
    width: 150,
    editable: true
  },
  {
    field: 'phone',
    headerName: '연락처',
    width: 150,
    editable: true
  },
  {
    field: 'equipment',
    headerName: '보유장비',
    width: 150,
    editable: true
  },
  {
    field: 'certification',
    headerName: '보유자격증',
    width: 150,
    editable: true
  },
  {
    field: 'etc',
    headerName: '비고',
    width: 150,
    editable: true
  }
];

const TABLE_HEAD = [
  { id: 'no', label: '번호', alignRight: false },
  { id: 'name', label: '이름', alignRight: false },
  { id: 'phone', label: '연락처', alignRight: false },
  { id: 'equipment', label: '보유장비', alignRight: false },
  { id: 'certification', label: '보유자격증', alignRight: false },
  { id: 'etc', label: '비고', alignRight: false },
  { id: '' }
];

function createData(no, name, phone, equipment, certification, etc) {
  return {
    no,
    name,
    phone,
    equipment,
    certification,
    etc
  };
}

const USERLIST = [
  createData('1', '홍길동', '010-1234-5678', '굴삭기', '굴삭기운전기능사', '용인'),
  createData('2', '이순신', '010-1234-5678', '지게차', '지게차운전기능사', '의왕'),
  createData('3', '이황', '010-1234-5678', '굴삭기, 지게차', '굴삭기운전기능사', '인덕원'),
  createData('4', '이성계', '010-1234-5678', '10t 트럭', '운전면허증(대형)', '의왕'),
  createData('5', '정몽주', '010-1234-5678', '25t 트럭', '운전면허증(대형)', '수원'),
  createData('6', '이방원', '010-1234-5678', '지게차', '지게차운전기능사', '용인')
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function User() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const handleFilterByName = (event) => {
  //   setFilterName(event.target.value);
  // };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Page title="인력관리">
      <Container spacing={2}>
        <Stack>
          <Typography variant="h3" gutterBottom>
            인력관리
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={0.5}>
          <Stack>
            {/* parameter 넘겨주는  방법 */}
            {/* <UserSearch posts={POSTS} /> */}
            <UserSearch />
          </Stack>
          <Stack direction="row" spacing={1} mb={1}>
            <Button variant="outlined" component={RouterLink} to="#">
              신규등록
            </Button>
            <Button variant="outlined" component={RouterLink} to="#">
              삭제
            </Button>
          </Stack>
        </Stack>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <UserListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={USERLIST.length}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
            />
            <TableBody>
              {filteredUsers
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  const { no, name, phone, equipment, certification, etc } = row;
                  const isItemSelected = selected.indexOf(name) !== -1;

                  return (
                    <TableRow
                      hover
                      key={no}
                      tabIndex={-1}
                      role="checkbox"
                      selected={isItemSelected}
                      aria-checked={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          onChange={(event) => handleClick(event, no)}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Typography variant="subtitle2" noWrap>
                            {no}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell align="left">{name}</TableCell>
                      <TableCell align="left">{phone}</TableCell>
                      <TableCell align="left">{equipment}</TableCell>
                      <TableCell align="left">{certification}</TableCell>
                      <TableCell align="left">{etc}</TableCell>
                      <TableCell align="right">
                        <UserMoreMenu />
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            {isUserNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <SearchNotFound searchQuery={filterName} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={USERLIST.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Container>
    </Page>
  );
}
