import { useState, useEffect } from 'react';
import axios from 'axios';
import { Stack, TextField, Button, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

function Timer() {
  const [open, setOpen] = useState(false);
  const [minutes, setMinutes] = useState(3);
  const [seconds, setSeconds] = useState(0);

  // useEffect(() => {
  //   const countdown = setInterval(() => {
  //     if (parseInt(seconds) > 0) {
  //       setSeconds(parseInt(seconds) - 1);
  //     }
  //     if (parseInt(seconds) === 0) {
  //       if (parseInt(minutes) === 0) {
  //         clearInterval(countdown);
  //       } else {
  //         setMinutes(parseInt(minutes) - 1);
  //         setSeconds(59);
  //       }
  //     }
  //   }, 1000);
  //   return () => clearInterval(countdown);
  // }, [minutes, seconds]);

  return (
    <div>
      <div>
        <h4 color="red">
          {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
        </h4>
      </div>
    </div>
  );
}

export default Timer;
