import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import { useEffect, useState } from 'react';
// material
import { Container, Link, Grid } from '@mui/material';
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import {
  ConstructionFirstCellTable,
  ConstructionSecondCellTable,
  ConstructionThirdCellTable
} from '../sections/construction';

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));
const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 600,
  // marginLeft: '35%',
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(1, 0)
}));
const ContentStyle2 = styled('div')(({ theme }) => ({
  // maxWidth: 600,
  // marginright: '35%',
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(17, 0)
}));

export default function ConstructorRegistrationTable() {
  const [firstCellData, setFirstCellData] = useState([]);
  // -----------------------------------------------------------------------
  const callbackFunction = () => {
    setFirstCellData();
  };
  // -----------------------------------------------------------------------
  return (
    <RootStyle title="공사관리등록">
      <AuthLayout>
        <Link underline="none" variant="subtitle2" component={RouterLink} to="/">
          홈으로
        </Link>
      </AuthLayout>
      <Grid container spacing={0.5}>
        <Grid item xs={6} md={4}>
          <Container spacing={1}>
            <ContentStyle>
              <ConstructionFirstCellTable />
            </ContentStyle>
          </Container>
        </Grid>
        <Grid item xs={6} md={8}>
          <Container>
            <ContentStyle2>
              <ConstructionSecondCellTable />
              <ConstructionThirdCellTable />
            </ContentStyle2>
          </Container>
        </Grid>
      </Grid>
    </RootStyle>
  );
}
