import * as React from 'react';
// import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import { TextField, Stack } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
// component
// import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  '& .MuiAutocomplete-root': {
    width: 200,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter
    }),
    '&.Mui-focused': {
      width: 240,
      '& .MuiAutocomplete-inputRoot': {
        boxShadow: theme.customShadows.z12
      }
    }
  },
  '& .MuiAutocomplete-inputRoot': {
    '& fieldset': {
      borderWidth: `1px !important`,
      borderColor: `${theme.palette.grey[500_32]} !important`
    }
  },
  '& .MuiAutocomplete-option': {
    '&:not(:last-child)': {
      borderBottom: `solid 1px ${theme.palette.divider}`
    }
  }
}));

// ----------------------------------------------------------------------

// ConstructionDatePicker.propTypes = {
// posts: PropTypes.array.isRequired
// };

export default function ConstructionDatePicker() {
  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(startDate);
  console.log(startDate);
  console.log(endDate);
  return (
    <RootStyle>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Stack direction="row" spacing={1}>
          <DatePicker
            // sx={{ ml: 1, width: 20, height: 20 }}
            // size="small"
            mask="____-__-__"
            value={startDate}
            inputFormat="yyyy-MM-dd"
            selectStart
            onChange={(newValue) => setStartDate(newValue)}
            renderInput={(params) => <TextField {...params} />}
          />
          <DatePicker
            // sx={{ ml: 1, width: 20, height: 20 }}
            // size="small"
            mask="____-__-__"
            value={endDate}
            inputFormat="yyyy-MM-dd"
            selectEnd
            onChange={(newValue) => setEndDate(newValue)}
            renderInput={(params) => <TextField {...params} />}
          />
        </Stack>
      </LocalizationProvider>
    </RootStyle>
  );
}
