/* eslint-disable prettier/prettier */
// import { filter } from 'lodash';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Stack,
  Button,  
  Container,
  Typography,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@mui/material';
// component
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
// import DialogActions from '@mui/material/DialogActions';

// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: 'cntrwkmNo', label: '번호', align: 'center', width: 10 },
  { id: 'cntrwkmKnd', label: '공종', alignRight: false },
  { id: 'cntrwkmGnr', label: '위치', alignRight: false },
  { id: 'cntrwkmDailDate', label: '날짜', alignRight: false },
  { id: 'cntrwkmDailEtc', label: '특이사항', alignRight: false },
  { id: 'cntrwkmDailyUser', label: '작성자', alignRight: false }
];
function createData(
    id, 
    cntwkmDailyNo, // 공사일보 고유번호
    cntrwkmNo, // 공사 고유번호
    cntrwkmKnd, // 공종
    cntrwkmGnr, // 세대, 위치
    cntrwkmDailDate, // 날짜
    cntrwkmDailyUser, // 등록자
    cntrwkmDailEtc // 특이사항
  ) {
  return { 
    id, 
    cntwkmDailyNo, // 공사일보 고유번호
    cntrwkmNo, // 공사 고유번호
    cntrwkmKnd, // 공종
    cntrwkmGnr, // 세대, 위치
    cntrwkmDailDate, // 날짜
    cntrwkmDailyUser, // 등록자
    cntrwkmDailEtc // 특이사항
  };
}

export default function ConstructionSecondCell() {
  const [constructionList, setConstructionList] = useState({
    id: '',
    cntwkmDailyNo: '',
    cntrwkmNo: '',
    cntrwkmKnd: '',
    cntrwkmGnr: '',
    cntrwkmDailDate: '',
    cntrwkmDailyUser: '',
    cntrwkmDailEtc: '',
  }); 
  const [fair, setFair] = useState();
  const [area, setArea] = useState();
  const [workDate, setWorkDate] = useState();
  const [etc, setEtc] = useState();
  const [writer, setWriter] = useState();
  const [dialogOpen, setDialogOpen] = useState(false);
  // Dialog Open
  const handleClickOpen = () => {
    setDialogOpen(true);
  };
  const handleClickClose = () => {
    setFair('');
    setArea('');
    setWorkDate('');
    setEtc('');
    setWriter('');
    setDialogOpen(false);
  };
  const handleClickSave = () => {
    setConstructionList({
      id: '',
      // cntwkmDailyNo: '', //자동생성
      cntrwkmNo: 1,
      cntrwkmKnd: fair,
      cntrwkmGnr: area,
      cntrwkmDailDate: workDate,
      cntrwkmDailyUser: 'regUser',
      cntrwkmDailEtc: etc,
    });
    handleClickClose();
  };
  const onChangeFair = (e) => {
    setFair(e.target.value || '');
  };
  const onChangeArea = (e) => {
    setArea(e.target.value || '');
  };
  const onChangeWorkDate = (e) => {
    setWorkDate(e.target.value || '');
  };
  const onChangeEtc = (e) => {
    setEtc(e.target.value || '');
  };
  const onClick = (e) => {
    if (!constructionList === null) {
      console.log('onClick');
    }
  };
  return (
    // 추가 삭제 Button
    <Container spacing={1}>
      <Stack direction="row" justifyContent="space-between">
        <Stack>
          <Typography variant="h5" gutterBottom align="center">
            공사일보
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
          <Button variant="outlined" onClick={handleClickOpen}>
            추가
          </Button>
          <Button variant="outlined" onClick={handleClickSave}>
            저장
          </Button>
          <Dialog open={dialogOpen} onClose={handleClickClose} PaperProps={{ sx: { width: '30%', height: '60%' } }}>
            <DialogTitle align="center">공사일보 등록</DialogTitle>
            <DialogContent>
              <Stack direction="column" justifyContent="space-between" spacing={2}>
                <TextField
                  label="공종"
                  type="text"
                  variant="standard"
                  name="fair"
                  value={fair}
                  onChange={onChangeFair}
                />
                <TextField
                label="위치"
                type="text"
                variant="standard"
                name="area"
                value={area}
                onChange={onChangeArea}
                />
                <br/>
                <TextField type="date" variant="standard" name="workDate" value={workDate} onChange={onChangeWorkDate}/>                
                <TextField
                label="특이사항"
                type="text"
                variant="standard"
                name="etc"
                value={etc}
                onChange={onChangeEtc}
                />
                <br/>
              </Stack>
              <Stack direction="row" justifyContent="center" spacing={2}>
                <Button variant="contained" onClick={handleClickSave}>
                  저장
                </Button>
                <Button variant="contained" onClick={handleClickClose}>
                  취소
                </Button>
              </Stack>
            </DialogContent>
          </Dialog>
          <Button variant="contained" component={RouterLink} to="#">
            삭제
          </Button>
        </Stack>
      </Stack>
      <div>
        <TableContainer sx={{ height: 200, width: '100%' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>번호</TableCell>
                <TableCell>공정</TableCell>
                <TableCell>위치</TableCell>
                <TableCell>날짜</TableCell>
                <TableCell>특이사항</TableCell>
                <TableCell>작성자</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="left" onClick={onClick}>
                  {constructionList.cntrwkmNo}
                </TableCell>
                <TableCell align="left" onClick={onClick}>
                  {constructionList.cntrwkmKnd}
                </TableCell>
                <TableCell align="left" onClick={onClick}>
                  {constructionList.cntrwkmGnr}
                </TableCell>
                <TableCell align="left" onClick={onClick}>
                  {constructionList.cntrwkmDailDate}
                </TableCell>
                <TableCell align="left" onClick={onClick}>
                  {constructionList.cntrwkmDailEtc}
                </TableCell>
                <TableCell align="left" onClick={onClick}>
                  {constructionList.cntrwkmDailyUser}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Container>
  );
}
